import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {changePopupData} from '../../features/showPopup'
import HomeMonth from '../gobal/HomeMonth/HomeMonth'
import HomePersona from '../gobal/HomePersona/HomePersona'
import HomeSavedSearches from '../gobal/HomeSavedSearches/HomeSavedSearches'
import HomeSidebar from '../gobal/HomeSidebar/HomeSidebar'
import HomeSectionTeam from '../gobal/HomeTeam/HomeSectionTeam'
import HiddenAudio from '../gobal/Popup/HiddenAudio'
import Popup from '../gobal/Popup/Popup'
import TitleSubTitle from '../gobal/TitleSubTitle'
// import { NavLink } from 'react-router-dom'
import GlobalLayout from '../layout/GlobalLayout'
import TopMenu from '../layout/TopMenu'
import {UserStatusGoPage} from '../lib/userStatus'
import {searchFormDeleteData} from '../../features/searchFormData'
import {searchStep} from '../../features/searchStepChange'
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../ui/FullScreenLoader";
import {useNavigate} from "react-router-dom";

export default function Home() {
    UserStatusGoPage("/")

    const dispatch = useDispatch()
    useEffect(() => {
        // if we play come to home page empty data so we can open popups and play audio 
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: false,
            activeAudio: ''
        }))
        dispatch(searchFormDeleteData())
        dispatch(searchStep({
            step1: true,
            step2: false,
            step3: false
        }))
    }, [])

    let navigate = useNavigate();

    const {data: categoriesData, loading: categoriesLoading} = useQuery(gql`
        query categories {
            categories {
                categoryId
                name
            }
        }
    `)

    if (categoriesLoading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <GlobalLayout showTopMenu={false}>
            {/* popup for audio and video  */}
            <Popup/>
            {/* hidden audio player */}
            <HiddenAudio/>
            <TopMenu/>
            <div className='px-10'>
                <div className='pt-10 pb-12 lg:pl-16'>
                    <TitleSubTitle title="What are you looking for today?" subtitle="Hello, nice to see you again!"/>
                </div>

                <div className='flex md:flex-row flex-col pb-[3.438rem]'>
                    <div className='w-full lg:w-[72%] md:pl-14'>
                        <HomeSectionTeam/>
                        <HomeSavedSearches/>
                        <HomeMonth/>
                    </div>
                    <div className='border-l-2 border-mainColor ml-14 h-full pb-2'>
                        <HomeSidebar/>
                    </div>
                </div>
            </div>
            <div>
                <HomePersona/>
            </div>
        </GlobalLayout>
    )
}
