import React, {useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changePopupData } from '../../../features/showPopup';
import isValidHttpUrl from "../../../helpers/isValidHttpUrl";
import {youtubeParser, youtubeThumbnail} from "../../../helpers/youtubeParser";

export default function PersonaFiles({ data, answers = [] }) {
    const dispatch = useDispatch()
    const showPopup = useSelector((state) => state.showPopup.value);

    // open popup with image or video
    const openPopup = (image, video) => {
        dispatch(changePopupData({
            video: video,
            image: image,
            open: true,
            audio: '',
            playAudio: false
        }))
        document.body.classList.add("overflow-hidden");
    }
    //  play audio
    const playAudio = (audio, i) => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: audio,
            playAudio: true,
            activeAudio: i
        }))
    }
    //  stop playing audio
    const stopAudio = () => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: true,
            activeAudio: ''
        }))
    }

    const thumbnails = useMemo(() => {
        if (!answers) {
            return false;
        }
        const urls = answers.map((answer) => answer?.question?.parsedContent?.map((el) => el && isValidHttpUrl(el) && youtubeParser(el) && ({
            image: youtubeThumbnail(el),
            video: el,
        })))
        if (!urls) return false;
        return urls.flat().filter((el) => !!el);
    }, [answers]);

    return (
        <div>
            <div className='font-firstFont font-medium text-xl mb-4'>Other Content of the user</div>
            <div className='flex gap-6 flex-wrap'>
                {thumbnails?.map((item, i) => (
                    <div className='flex items-center ' key={i}>
                        <div className='relative min-h-[5.88rem!important] min-w-[6.13rem!important] max-h-[5.88rem!important] max-w-[6.13rem!important] cursor-pointer'>
                            <img src={item?.image} alt="Youtube thumbnail" className="min-h-[5.88rem!important] min-w-[6.13rem!important] max-h-[5.88rem!important] max-w-[6.13rem!important] object-cover rounded-[0.313rem]" />

                            <div
                                className='absolute w-full h-full top-0 left-0 flex justify-end items-end bg-singlePersonaItemsBg pr-[0.188rem] pb-[0.188rem]'
                                onClick={() => openPopup('', item?.video)}
                            >
                                <img src={`/images/icons/play.svg`} alt="play" className='w-[1.63rem] h-[1.63rem]' />
                            </div>
                        </div>
                    </div>
                ))}
                {
                    !thumbnails && data?.otherContent ?
                        data?.otherContent.map((item, i) => (
                            <div className='flex items-center ' key={item.id}>
                                {
                                    // if data is post open other website url
                                    item?.post ?
                                        <a href={item?.post} target='_blank' rel="noreferrer" >
                                            <div className='relative min-h-[5.88rem!important] min-w-[6.13rem!important] max-h-[5.88rem!important] max-w-[6.13rem!important] cursor-pointer'>
                                                <img src={item?.img} alt={item?.section} className="min-h-[5.88rem!important] min-w-[6.13rem!important] max-h-[5.88rem!important] max-w-[6.13rem!important] object-cover rounded-[0.313rem]" />
                                                <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end bg-singlePersonaItemsBg pr-[0.188rem] pb-[0.188rem]'>
                                                    <img src={`/images/icons/post.svg`} alt="play" className='w-[1.63rem] h-[1.63rem]' />
                                                </div>
                                            </div>
                                        </a> :
                                        // if data is other then post open popup
                                        // TODO add popup functional
                                        <div className='relative min-h-[5.88rem!important] min-w-[6.13rem!important] max-h-[5.88rem!important] max-w-[6.13rem!important] cursor-pointer'>
                                            <img src={item?.img} alt={item?.section} className="min-h-[5.88rem!important] min-w-[6.13rem!important] max-h-[5.88rem!important] max-w-[6.13rem!important] object-cover rounded-[0.313rem]" />
                                            {
                                                item?.video &&
                                                <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end bg-singlePersonaItemsBg pr-[0.188rem] pb-[0.188rem]' onClick={() => openPopup('', item?.video)} >
                                                    <img src={`/images/icons/play.svg`} alt="play" className='w-[1.63rem] h-[1.63rem]' />
                                                </div>
                                            }
                                            {
                                                item?.popupImage &&
                                                <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end bg-singlePersonaItemsBg pr-[0.188rem] pb-[0.188rem]' onClick={() => openPopup(item?.popupImage, '')} >
                                                    <img src={`/images/icons/image-icon.svg`} alt="play" className='w-[1.63rem] h-[1.63rem]' />
                                                </div>
                                            }
                                            {
                                                item?.audio &&
                                                <div>
                                                    {`singleContent${i}` !== showPopup.activeAudio ?
                                                        <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end bg-singlePersonaItemsBg pr-[0.188rem] pb-[0.188rem]' onClick={() => playAudio(item?.audio, `singleContent${i}`)} >
                                                            <img src={`/images/icons/play.svg`} alt="play" className='w-[1.63rem] h-[1.63rem]' />
                                                        </div> :
                                                        <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end bg-singlePersonaItemsBg pr-[0.188rem] pb-[0.188rem]' onClick={() => stopAudio()} >
                                                            <div className="box smallBox">
                                                                <div className="box__line  smallBox__line"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                }
                            </div>
                        ))
                        : ''
                }
            </div>
        </div>
    )
}
