import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {searchStep} from '../../features/searchStepChange'
import PersonaSearchResult from '../gobal/SearchResult/PersonaSearchResult'
import GlobalLayout from '../layout/GlobalLayout'
import TopMenu from '../layout/TopMenu'
import {UserStatusGoPage} from '../lib/userStatus'
import SuccessPopup from "../gobal/SuccessPopup";
import {gql, useMutation} from "@apollo/client";
import {SAVED_SEARCHES} from "../gobal/HomeSavedSearches/HomeSavedSearches";

export default function PersonaResult() {
    UserStatusGoPage()
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const {id} = useParams()

    const searchFormData = useSelector((state) => state.searchFormData.value);

    const [showSuccess, setShowSuccess] = useState(false)

    const [mutateSearch, {loading: mutateLoading}] = useMutation(gql`
        mutation createSearch($input: CreateSearchInput!) {
            createSearch(createSearchInput: $input) {
                searchId
                questionId
            }
        }
    `)

    useEffect(() => {
        // if open save success message hide it in 5s
        setTimeout(() => {
            showSuccess &&
            setShowSuccess(false)
        }, 5000);
    }, [showSuccess])


    useEffect(() => {
        const {saveSearch, category, question, region, kids, date, age} = searchFormData;
        if (!id && !question) {
            navigate("/")
            return
        }
        if (saveSearch && category && question) {
            mutateSearch({
                variables: {
                    input: {
                        type: "PERSONAS",
                        questionId: question,
                        categoryId: category,
                        saved: true,
                        regions: region || [],
                        kids: !!kids,
                        ...(date && {
                            month: new Date(date).getMonth() + 1,
                            year: new Date(date).getFullYear(),
                        }),
                        demoCat1: age && age.includes("18-24"),
                        demoCat2: age && age.includes("25-34") && !kids,
                        demoCat3: age && age.includes("25-34") && kids,
                        demoCat4: age && age.includes("33-44") && !kids,
                        demoCat5: age && age.includes("33-44") && kids,
                        demoCat6: age && age.includes("45-55"),
                    }
                },
                refetchQueries: [{ query: SAVED_SEARCHES }],
            }).then(({data}) => {
                setShowSuccess(true)
                if (data) {
                    // navigate to search page after creating it
                    navigate(`/persona-result/${data?.createSearch?.searchId}`)
                }
            })
        }
    }, [])

    // click on number to go search step
    function changeStep(step1, step2, step3) {
        dispatch(searchStep({
            step1: step1,
            step2: step2,
            step3: step3
        }))
        navigate("/search");
    }

    return (
        <div>
            <GlobalLayout showTopMenu={false}>
                <SuccessPopup title="Success!" text="Your search was saved successfully!" showPopup={showSuccess}
                              closePopup={() => setShowSuccess(false)}/>

                <TopMenu/>

                <div className='pl-10 pr-5 mt-2'>
                    <div className='flex font-firstFont text-sm tracking-wider'>
                        <div className='pr-5 border-r-2'>
                            <NavLink to={'/'} className='flex items-center opacity-60'>
                                <img src="/images/icons/back.svg" alt="back"/>
                                <span className='ml-[0.625rem]'>Dashboard</span>
                            </NavLink>
                        </div>
                        <div className='flex ml-7'>
                            <div className='opacity-60 cursor-pointer' onClick={() => changeStep(true, false, false)}>
                                Step 1 Month /
                            </div>
                            <div className='ml-2 opacity-60 cursor-pointer'
                                 onClick={() => changeStep(true, true, false)}>
                                Step 2 Type /
                            </div>
                            <div className='ml-2 opacity-60 cursor-pointer'
                                 onClick={() => changeStep(true, true, true)}>
                                Step 3 Question
                            </div>
                            <div className='ml-2'>
                                / Personas List
                            </div>
                        </div>
                    </div>
                    <PersonaSearchResult/>
                </div>
            </GlobalLayout>
        </div>
    )
}
