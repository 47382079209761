import React from 'react'
import PersonaMostSearchedSlider from './PersonaMostSearchedSlider'

export default function PersonaMostSearched({personas}) {
    return (
        <div>
            <div className='bg-mainColor  md:ml-[2.5rem] pt-[2.188rem] pl-[3.875rem] pr-[3.6rem] pb-[2.75rem] mt-3'>
                <div className='font-firstFont font-semibold text-bgMain text-sm pb-3 pl-4 uppercase'>Most searched personas</div>
                {/* <HomePersonaSection /> */}
                <PersonaMostSearchedSlider personas={personas} />
            </div>
        </div>
    )
}


