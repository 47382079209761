import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchStep } from '../../../features/searchStepChange';

export default function SearchStepNumbers() {
    const searchStepChange = useSelector((state) => state.searchStepChange.value);
    const dispatch = useDispatch()
    // click on number to go prev step
    function changeStep(step1, step2, step3) {
        dispatch(searchStep({
            step1: step1,
            step2: step2,
            step3: step3
        }))
    }
    return (
        <div className='searchTop pt-3'>
            {/* disable step if it is not current or previous  */}
            {
                searchStepChange.step1 ?
                    <div className='searchTop_item cursor-pointer' >
                        <div className='info' onClick={() => changeStep(true, false, false)}>
                            <div className='number'>1</div>
                            <div className='text'>Month</div>
                        </div>
                    </div> : ''
            }
            {
                searchStepChange.step2 ?
                    <div className={`searchTop_item cursor-pointer`}  >
                        <div className='info' onClick={() => changeStep(true, true, false)}>
                            <div className='number'>2</div>
                            <div className='text'>Type</div>

                        </div>
                        <div className='line'></div>
                    </div> :
                    <div className={`searchTop_item disable  `} >
                        <div className='info'>
                            <div className='number'>2</div>
                            <div className='text'>Type</div>

                        </div>
                        <div className='line'></div>
                    </div>
            }
            <div className={`searchTop_item ${!searchStepChange.step3 ? 'disable' : ''}`}>
                <div className='info'>
                    <div className='number'>3</div>
                    <div className='text'>Questions</div>
                </div>
                <div className='line'></div>
            </div>
        </div>
    )
}
