import React, {useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePopupData } from '../../../features/showPopup'
import isValidHttpUrl from "../../../helpers/isValidHttpUrl";
import {youtubeParser, youtubeThumbnail} from "../../../helpers/youtubeParser";
import {searchForm} from "../../../features/searchFormData";
import moment from "moment";
import {useNavigate} from "react-router-dom";
export default function HomeMonthItem({ data, itemIndex, question }) {
    let navigate = useNavigate();

    const dispatch = useDispatch()
    const showPopup = useSelector((state) => state.showPopup.value);
    // open popup with image or video
    const openPopup = (image, video) => {
        dispatch(changePopupData({
            video: video,
            image: image,
            open: true,
            audio: '',
            playAudio: false
        }))
        document.body.classList.add("overflow-hidden");
    }
    //  play audio
    const playAudio = (audio, i) => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: audio,
            playAudio: true,
            activeAudio: i
        }))
    }
    //  stop playing audio
    const stopAudio = () => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: true,
            activeAudio: ''
        }))
    }

    const topAnswer = useMemo(() => {
        const topAnswers = question?.topAnswers?.filter((topAnswer) => topAnswer?.answer?.parsedAnswer && topAnswer?.persona?.image)
        return topAnswers[Math.floor(Math.random() * topAnswers.length)]
    }, [question])

    const thumbnail = useMemo(() => {
        if (!question || !question.parsedContent && !question.parsedContent.length > 0) {
            return false;
        }
        const urls = question?.parsedContent?.map((el) => el && isValidHttpUrl(el) && youtubeParser(el) && youtubeThumbnail(el))
        if (!urls) return false;
        return urls[Math.floor(Math.random() * urls.length)]
    }, [question]);

    const handleClick = () => {
        if (!question) return;

        dispatch(searchForm({
            date: moment()
                .set('month', question?.month - 1)
                .set('year', question?.year).toDate().toString(),
            category: question.categoryId,
            question: question.questionId,
        }))

        navigate('/persona-result')
    }

    return (
        <div className=' ml-[0.625rem] items-center    border-transparentWhite border-r mr-4'>
            {
                topAnswer && (
                    <div
                        onClick={handleClick}
                        className='relative min-h-[7.88rem!important] min-w-[8.19rem!important] max-h-[7.88rem!important] max-w-[8.19rem!important] cursor-pointer w-fit h-fit'
                    >
                        <img
                            src={thumbnail || topAnswer?.persona?.image}
                            alt={topAnswer?.persona?.name}
                            className="min-h-[7.88rem!important] min-w-[8.19rem!important] object-cover rounded-[0.313rem] max-h-[7.88rem!important] max-w-[8.19rem!important]"
                        />

                        {thumbnail && (
                            <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' >
                                <img src={`/images/icons/play.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]' />
                            </div>
                        )}

                        {!thumbnail && topAnswer?.persona?.image && (
                            <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' >
                                <img src={`/images/icons/image-icon.svg`} alt="image" className='w-[2.13rem] h-[2.13rem]' />
                            </div>
                        )}

                        {!thumbnail && !topAnswer?.persona?.image && (
                            <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' >
                                <img src={`/images/icons/post.svg`} alt="post" className='w-[2.13rem] h-[2.13rem]' />
                            </div>
                        )}

                    </div>
                )
            }
            {
                data && (
                    // if data is post open other website url
                    data?.post ?
                        <a href={data?.post} target='_blank' rel="noreferrer" >
                            <div className='relative min-h-[7.88rem!important] min-w-[8.19rem!important] max-h-[7.88rem!important] max-w-[8.19rem!important] cursor-pointer w-fit h-fit'>
                                <img src={data?.img} alt={data.section} className="min-h-[7.88rem!important] min-w-[8.19rem!important] object-cover rounded-[0.313rem] max-h-[7.88rem!important] max-w-[8.19rem!important]" />
                                <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end'>
                                    <img src={`/images/icons/post.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]' />
                                </div>

                            </div>
                        </a> :
                        // if data is other then post open popup
                        // TODO add popup functional
                        <div className='relative min-h-[7.88rem!important] min-w-[8.19rem!important] max-h-[7.88rem!important] max-w-[8.19rem!important] cursor-pointer w-fit h-fit'>
                            <img src={data?.img} alt={data.section} className="min-h-[7.88rem!important] min-w-[8.19rem!important] object-cover rounded-[0.313rem] max-h-[7.88rem!important] max-w-[8.19rem!important]" />
                            {
                                data?.video &&
                                <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' onClick={() => openPopup('', data?.video)} >
                                    <img src={`/images/icons/play.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]' />
                                </div>

                            }
                            {
                                data?.audio &&
                                <div>
                                    {
                                        // if we click on certain item show animation
                                        itemIndex !== showPopup.activeAudio ?
                                            <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' onClick={() => playAudio(data?.audio, itemIndex)} >
                                                <img src={`/images/icons/play.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]' />
                                            </div> :
                                            <div className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' onClick={() => stopAudio()} >
                                                <div className="box ">
                                                    <div className="box__line  "></div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            }
                            {
                                data?.popupImage &&
                                <div onClick={() => openPopup(data?.popupImage, '')} className='absolute w-full h-full top-0 left-0 pr-1 pb-1 flex justify-end items-end' >
                                    <img src={`/images/icons/image-icon.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]' />
                                </div>
                            }
                        </div>
                )

            }
            <div className='font-dropdownFont font-medium tracking-wider text-xs leading-5 '>
                <div className='text-transparentWhite8 mt-3 break-words pr-1'>{question?.categoryName}</div>
                <div className='text-transparentWhite8 break-words pr-1'>
                    {topAnswer?.persona?.age} Y/O
                    {topAnswer?.persona?.status && `, ${topAnswer?.persona?.status}`}
                    {`, ${topAnswer?.persona?.kids === 'No' ? "W/O" : "W"} KIDS`}
                </div>
            </div>
        </div >
    )
}
