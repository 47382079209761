import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { searchForm } from '../../../features/searchFormData';
import HomePersonaSection from './HomePersonaSection'

export default function HomePersona() {
    const dispatch = useDispatch();
    const searchFormData = useSelector((state) => state.searchFormData.value);
    let navigate = useNavigate();


    const goToPersonaDashboard = () => {
        searchFormData.date &&
            dispatch(searchForm({ date: '', type: '', saveSearch: false, category: '', question: '', age: [], region: [], kids: false }))
        return navigate("/personas");
    }

    return (
        <div>
            <div className='bg-mainColor  md:ml-[3.688rem] pt-[2.813rem] pl-11 pr-[4.438rem] pb-[2.75rem]'>
                <div className="font-firstFont font-semibold text-bgMain text-sm pb-[1.438rem] uppercase tracking-wider">personas</div>
                <HomePersonaSection />
                <div className='mt-5'>
                    <div className="black-button cursor-pointer" onClick={() => goToPersonaDashboard()}>
                        More
                    </div>
                </div>
            </div>
        </div>
    )
}
