
export default function UserLayout({ children }) {
    return (
        <div className="flex h-full">
            <div className=" min-h-[100vh] flex">
                <div className="bg-mainColor w-[6.563rem] h-full"></div>
                <div className="bg-mainColor w-[1rem] h-full ml-[1.438rem]"></div>
            </div>
            <div className="h-full w-full flex justify-center items-center">
                {children}
            </div>
        </div>
    );
}
