export const categoriesMainData = [
    {
        id: 1,
        name: 'Current Events & Politics',
        uniqueId: 10,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 10,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 20,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 30,
            },
            {
                item: 'Do you vote in every election ?',
                id: 4,
                uniqueId: 40,
            },
            {
                item: 'Are you satisfied with the direction the country ,is heading?',
                id: 5,
                uniqueId: 50,
            }

        ]
    },
    {
        id: 2,
        name: 'Beauty & Health',
        uniqueId: 20,
        question: [
            {
                item: 'Do you vote in every election ?',
                id: 1,
                uniqueId: 60,
            },
            {
                item: 'Are you satisfied with the direction the country is heading?',
                id: 2,
                uniqueId: 70,
            },
            {
                item: 'How do you learn about current events?',
                id: 3,
                uniqueId: 80,
            }

        ]
    },
    {
        id: 3,
        name: 'Lifestyle & Culture',
        uniqueId: 30,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 90,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 100,
            },
            {
                item: 'Are you satisfied with the direction the country is heading?',
                id: 3,
                uniqueId: 110,
            }

        ]
    },
    {
        id: 4,
        name: 'Aspiration',
        uniqueId: 40,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 120,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 130,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 140,
            },
            {
                item: 'Do you vote in every election ?',
                id: 4,
                uniqueId: 150,
            }

        ]
    },
    {
        id: 5,
        name: 'Money/ Finances/ Education',
        uniqueId: 50,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 160,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 170,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 180,
            },
            {
                item: 'Do you vote in every election ?',
                id: 4,
                uniqueId: 190,
            }

        ]
    },
    {
        id: 6,
        name: 'Food & Bevarage',
        uniqueId: 60,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 200,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 210,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 220,
            }

        ]
    },
    {
        id: 7,
        name: 'Love & Relationship',
        uniqueId: 70,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 221,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 222,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 223,
            },
            {
                item: 'Do you vote in every election ?',
                id: 4,
                uniqueId: 224,
            }

        ]
    },
    {
        id: 8,
        name: 'Fashion',
        uniqueId: 80,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 225,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 226,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 227,
            },
            {
                item: 'Do you vote in every election ?',
                id: 4,
                uniqueId: 228,
            }

        ]
    },
    {
        id: 9,
        name: 'Media Entertinment/ Technology',
        uniqueId: 90,
        question: [
            {
                item: 'How do you learn about current events?',
                id: 1,
                uniqueId: 229,
            },
            {
                item: 'Where do you get your news?',
                id: 2,
                uniqueId: 230,
            },
            {
                item: 'Do you discuss politics with others?',
                id: 3,
                uniqueId: 231,
            },
            {
                item: 'Do you vote in every election ?',
                id: 4,
                uniqueId: 232,
            }
        ]
    }
]
export const userLoginInfo = {
    email: 'test@gmail.com',
    password: '123456789',
    remember: true
}
export const homeSidebar = [
    {
        id: 1,
        img: `/images/home/sidebar/img1.png`,
        section: "Media/ Entertainment/ Technology",
        category: "Women 25-34, w kids",
        video: '/images/popup/video2.mp4',
        post: '',
        audio: '',
        popupImage: ''
    },
    {
        id: 2,
        img: `/images/home/sidebar/img2.png`,
        section: "Media/ Entertainment/ Technology",
        category: "Women 25-34, w kids",
        video: '',
        post: 'https://www.google.com/',
        audio: '',
        popupImage: ''
    },
    {
        id: 3,
        img: `/images/home/sidebar/img1.png`,
        section: "Media/ Entertainment/ Technology",
        category: "Women 25-34, w kids",
        video: '',
        post: '',
        audio: '/images/popup/audio.mp3',
        popupImage: ''
    },
    {
        id: 4,
        img: `/images/home/sidebar/img2.png`,
        section: "Aspiration",
        category: "Women 25-34, w kids",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/small-img.png'
    }
]

export const homeSliderMonth = [
    {
        id: 1,
        img: `/images/home/month/img1.png`,
        section: "Aspiration",
        category: "Women 18-24",
        video: '',
        post: 'https://www.google.com/',
        audio: '',
        popupImage: ''
    },
    {
        id: 2,
        img: `/images/home/month/img2.png`,
        section: "Aspiration",
        category: "Women 34-33, w/o kids",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/small-img.png'
    },
    {
        id: 3,
        img: `/images/home/month/img3.png`,
        section: "Aspiration",
        category: "Women 18-24",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/large-img.png'
    },
    {
        id: 4,
        img: `/images/home/month/img2.png`,
        section: "Media Entertainment",
        category: "Women 34-33, w/o kids",
        video: '',
        post: '',
        audio: '/images/popup/audio2.mp3',
        popupImage: ''
    },
    {
        id: 5,
        img: `/images/home/month/img1.png`,
        section: "Aspiration",
        category: "Women 18-24",
        video: '/images/popup/video.mp4',
        post: '',
        audio: '',
        popupImage: ''
    },
    {
        id: 6,
        img: `/images/home/month/img2.png`,
        section: "Aspiration",
        category: "Women 34-33, w/o kids",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/small-img.png'
    }
]

export const topRatedDemo = [
    {
        id: 1,
        img: `/images/home/month/img1.png`,
        section: "Aspiration",
        category: "women 18-24",
        video: '',
        post: 'https://www.google.com/',
        audio: '',
        popupImage: ''
    },
    {
        id: 2,
        img: `/images/home/month/img2.png`,
        section: "Aspiration",
        category: "Women 34-33, w/o kids",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/small-img.png'
    },
    {
        id: 3,
        img: `/images/home/month/img3.png`,
        section: "Aspiration",
        category: "women 18-24",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/large-img.png'
    },
    {
        id: 4,
        img: `/images/home/month/img2.png`,
        section: "Media Entertainment",
        category: "Women 34-33, w/o kids",
        video: '',
        post: '',
        audio: '/images/popup/audio.mp3',
        popupImage: ''
    },
    {
        id: 5,
        img: `/images/home/month/img1.png`,
        section: "Aspiration",
        category: "women 18-24",
        video: '/images/popup/video.mp4',
        post: '',
        audio: '',
        popupImage: ''
    },
    {
        id: 6,
        img: `/images/home/month/img2.png`,
        section: "Aspiration",
        category: "Women 34-33, w/o kids",
        video: '',
        post: '',
        audio: '',
        popupImage: '/images/popup/small-img.png'
    }
]

export const personaHomeList = [
    {
        id: 1,
        name: 'Keyshanna',
        age: '18',
        img: `/images/home/persona/persona1.png`,
        status: 'Single',
        children: 'No kids',
        country: 'Los Angeles',
    },
    {
        id: 2,
        name: 'Gabrielle',
        age: '38',
        img: `/images/home/persona/persona2.png`,
        status: 'Married',
        children: '6-year-old twin boys',
        country: 'Oakland',
    },
    {
        id: 3,
        name: 'Tarana',
        age: '49',
        img: `/images/home/persona/persona3.png`,
        status: 'Married',
        children: '12-year-old son, 16-year-old daughter',
        country: 'NY',
    },
    {
        id: 4,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'no kids',
        country: 'Orange County',
    }
]
// home data end

// search result demo
export const searchResultDemo = {
    answersBy: [
        {
            id: 1,
            age: '18-24',
            status: '',
            answers: [
                {
                    item: 'Local TV News '
                },
                {
                    item: 'Internet'
                },
                {
                    item: 'Family'
                },
                {
                    item: 'Friends'
                },
                {
                    item: 'Twitter'
                }
            ]
        },
        {
            id: 2,
            age: '25-34',
            status: 'without kids',
            answers: [
                {
                    item: 'Local TV News '
                },
                {
                    item: 'Internet'
                },
                {
                    item: 'Family'
                },
                {
                    item: 'Friends'
                },
                {
                    item: 'Twitter'
                }
            ]
        },
        {
            id: 3,
            age: '25-34',
            status: 'with kids',
            answers: [
                {
                    item: 'Local TV News '
                },
                {
                    item: 'Internet'
                },
                {
                    item: 'Family'
                },
                {
                    item: 'Friends'
                },
                {
                    item: 'Twitter'
                }
            ]
        },
        {
            id: 4,
            age: '34-44',
            status: 'without kids',
            answers: [
                {
                    item: 'Local TV News '
                },
                {
                    item: 'Internet'
                },
                {
                    item: 'Family'
                },
                {
                    item: 'Friends'
                },
                {
                    item: 'Twitter'
                }
            ]
        },
        {
            id: 5,
            age: '34-44',
            status: 'with kids',
            answers: [
                {
                    item: 'Local TV News '
                },
                {
                    item: 'Internet'
                },
                {
                    item: 'Family'
                },
                {
                    item: 'Friends'
                },
                {
                    item: 'Twitter'
                }
            ]
        },
        {
            id: 6,
            age: '45-55',
            status: '',
            answers: [
                {
                    item: 'Local TV News '
                },
                {
                    item: 'Internet'
                },
                {
                    item: 'Family'
                },
                {
                    item: 'Friends'
                },
                {
                    item: 'Twitter'
                }
            ]
        }
    ]
}
// search result demo end

// search by persona
export const age = [
    {
        age: "18-24"
    },
    {
        age: "25-34"
    },
    {
        age: "34-44"
    },
    {
        age: "45-55"
    }
]

export const region = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]

export const personaList = [
    {
        id: 1,
        name: 'Keyshanna',
        age: '18',
        img: `/images/home/persona/persona1.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Los Angeles',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid ',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]

    },
    {
        id: 2,
        name: 'Gabrielle',
        age: '38',
        img: `/images/home/persona/persona2.png`,
        status: 'Married',
        children: 'With kids',
        country: 'Oakland',
        answer: 'A Thousand Years by Christina Perri Some Nights by Fun Me, Myself, & I by Beyonce',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]

    },
    {
        id: 3,
        name: 'Tarana',
        age: '49',
        img: `/images/home/persona/persona3.png`,
        status: 'Married',
        children: 'With kids',
        country: 'NY',
        answer: 'A Thousand Years by Christina Perri Some Nights by Fun Me, Myself, & I by Beyonce',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Open-minded`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 4,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 5,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 6,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 7,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 8,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 9,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 10,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 11,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 12,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 13,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    },
    {
        id: 14,
        name: 'Nina',
        age: '21',
        img: `/images/home/persona/persona4.png`,
        status: 'Single',
        children: 'Without kids',
        country: 'Orange County',
        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
        overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
        personality: `Shy, open-minded, agree-able and conscientious`,
        motivation: `Getting a good education, making her parents and family proud`,
        goals: `Travel the world, become a hospital administrator, get out of debt`,
        frustration: `Financial limitations, cost of college`,
        otherContent: [
            {
                id: 1,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '/images/popup/video2.mp4',
                post: '',
                audio: '',
                popupImage: ''
            },
            {
                id: 2,
                img: `/images/home/sidebar/img2.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: 'https://www.google.com/',
                audio: '',
                popupImage: ''
            },
            {
                id: 3,
                img: `/images/home/sidebar/img1.png`,
                section: "Media/ Entertainment/ Technology",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '/images/popup/audio2.mp3',
                popupImage: ''
            },
            {
                id: 4,
                img: `/images/home/sidebar/img2.png`,
                section: "Aspiration",
                category: "women 25-34, w kids",
                video: '',
                post: '',
                audio: '',
                popupImage: '/images/popup/small-img.png'
            }
        ],
        answers: [
            {
                id: 1,
                name: 'Current Events & Politics',
                uniqueId: 10,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 10,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 20,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 30,
                        answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 40,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Are you satisfied with the direction the country ,is heading?',
                        id: 5,
                        uniqueId: 50,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                    }

                ]
            },
            {
                id: 2,
                name: 'Beauty & Health',
                uniqueId: 20,
                question: [
                    {
                        item: 'Do you vote in every election ?',
                        id: 1,
                        uniqueId: 60,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 2,
                        uniqueId: 70,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'How do you learn about current events?',
                        id: 3,
                        uniqueId: 80,
                        answer: 'Bungee Jumping'
                    }

                ]
            },
            {
                id: 3,
                name: 'Lifestyle & Culture',
                uniqueId: 30,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 90,
                        answer: 'Bungee Jumping'
                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 100,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                    },
                    {
                        item: 'Are you satisfied with the direction the country is heading?',
                        id: 3,
                        uniqueId: 110,
                        answer: 'All the Stars - SZA/Kendrick Lamar'
                    }

                ]
            },
            {
                id: 4,
                name: 'Aspiration',
                uniqueId: 40,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 120,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 130,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 140,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 150,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 5,
                name: 'Money/ Finances/ Education',
                uniqueId: 50,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 160,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 170,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 180,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 190,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }

                ]
            },
            {
                id: 6,
                name: 'Food & Bevarage',
                uniqueId: 60,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 200,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 210,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 220,
                        answer: 'Bungee Jumping'

                    }

                ]
            },
            {
                id: 7,
                name: 'Love & Relationship',
                uniqueId: 70,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 221,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 222,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 223,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 224,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    }

                ]
            },
            {
                id: 8,
                name: 'Fashion',
                uniqueId: 80,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 225,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 226,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 227,
                        answer: 'Are you satisfied with the direction the country ,is heading?',

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 228,
                        answer: 'All the Stars - SZA/Kendrick Lamar'

                    }

                ]
            },
            {
                id: 9,
                name: 'Media Entertinment/ Technology',
                uniqueId: 90,
                question: [
                    {
                        item: 'How do you learn about current events?',
                        id: 1,
                        uniqueId: 229,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    },
                    {
                        item: 'Where do you get your news?',
                        id: 2,
                        uniqueId: 230,
                        answer: 'Bungee Jumping'

                    },
                    {
                        item: 'Do you discuss politics with others?',
                        id: 3,
                        uniqueId: 231,
                        answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                    },
                    {
                        item: 'Do you vote in every election ?',
                        id: 4,
                        uniqueId: 232,
                        answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                    }
                ]
            }
        ]
    }
]
// search by persona end
