import React from 'react'

export default function SubmitPopup(props) {
    return (
        <div className={`mainPopup ${props.showPopup ? 'active' : ''}`}>
            <div className='bg-mainColor max-w-[26.31rem] w-full p-5 relative font-firstFont'>
                <div className='text-mainFontSize text-black font-bold mb-1'>
                    {props.title}
                </div>
                <div className='text-sm text-black'>
                    {props.text}
                </div>
                <div className='absolute top-5 right-5 cursor-pointer' onClick={props.closePopup}>
                    <img src={`/images/icons/close.svg`} alt="close" />
                </div>
            </div>
        </div>
    )
}
