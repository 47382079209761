import React from 'react'
import {NavLink} from 'react-router-dom'

export default function PersonaCardItem({data, answer}) {

    return (
        <div className='border border-mainColor rounded-[0.313rem] min-h-full flex flex-col justify-between'>
            <div>
                <div className='flex py-[0.875rem] px-[1.188rem] border-b border-b-transparentWhite25'>
                    <div className='mr-4'>
                        <img src={answer?.persona?.image} alt={answer?.persona?.name}
                             className="w-[2.813rem] h-[2.813rem] object-cover rounded-[100%]"/>
                    </div>
                    <div className='font-firstFont text-xs tracking-[0.04em] font-medium'>
                        <div>{answer?.persona?.name}, {answer?.persona?.age}</div>
                        <div className='text-transparentWhite45'>
                            <div>{answer?.persona?.region}</div>
                            <div>{answer?.persona?.status}, {answer?.persona?.kids}</div>
                        </div>
                    </div>
                </div>
                <div className='py-[0.875rem] px-[1.188rem] font-firstFont text-xs tracking-[0.04em]'>
                    <div className='mb-1 text-transparentWhite5'>Answer</div>
                    <div className='font-medium  overflow-hidden'>
                        <div className='line-three min-h-[3rem]'>
                            {
                                (answer.parsedAnswer && answer.parsedAnswer[0].length > 0) ?
                                    answer.parsedAnswer :
                                    (answer.answer && answer.answer !== "[\"\"]") ? answer.answer : "--"
                            }
                        </div>
                    </div>
                </div>
            </div>
            <NavLink to={`/persona/${answer?.persona?.personaId}`}
                     className="bg-mainColor flex justify-center items-center text-bgMain font-firstFont font-medium text-xs tracking-[0.04em] py-[0.438rem]">
                More
            </NavLink>
        </div>
    )
}
