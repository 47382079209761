import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from "@apollo/client/link/error";

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem('token');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors[0]?.extensions?.response?.statusCode === 401) {
        const token = localStorage.getItem('token');
        if (token) {
            localStorage.removeItem('token');
            window.location.reload();
        }
    }
    return null;
});

const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
});

export default apolloClient;

