import React, {useState} from "react";
import "./style.scss";
import {useDispatch} from "react-redux";
import {changePopupData} from "../../../features/showPopup";
import {youtubeThumbnail} from "../../../helpers/youtubeParser";

function PagePreview({link = 'http://www.impathlens.com/'}) {

    const [loaded, setLoaded] = useState(false)

    const onLoad = () => setLoaded(true)

    const dispatch = useDispatch()
    const openPopup = (image, video) => {
        dispatch(changePopupData({
            video: video,
            image: image,
            open: true,
            audio: '',
            playAudio: false
        }))
        document.body.classList.add("overflow-hidden");
    }

    const isVideo = link.includes('//youtu')

    return (
        <div className="thumbnail-container" title={link} onClick={() => {
            if (isVideo) {
                openPopup('', link);
                return;
            }

            window.open(link, "_blank")
        }}>
            {/*{process.env.REACT_APP_NODE_ENV !== 'development' && (*/}
            {isVideo ? (
                <img src={youtubeThumbnail(link)} alt={"Thumbnail youtube"} className='w-[100%] h-[100%]' />
            ) : (
                <div className="thumbnail">
                    <iframe src={link} frameBorder="0" onLoad={onLoad} style={{opacity: loaded ? 1 : 0}}/>
                </div>
            )}
            {/*)}*/}

            {!isVideo && (
                <div className='rounded-md bg-transparentBlack5 absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                    <img src={`/images/icons/post.svg`} alt="play" className='w-[3.313rem] h-[3.313rem]' />
                </div>
            )}
            {isVideo && (
                <div className='rounded-md bg-transparentBlack5 absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer' onClick={() => openPopup('', link)}>
                    <img src={`/images/icons/play.svg`} alt="play" className='w-[3.125rem] h-[3.125rem]' />
                </div>
            )}
        </div>
    )
}

export default PagePreview;