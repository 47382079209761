import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
// import { useForm } from 'react-hook-form';
import ChangeSteps from './ChangeSteps';
import { searchForm } from '../../../features/searchFormData';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { searchStep } from '../../../features/searchStepChange';
import useCloseDropdown from '../../lib/useCloseDropdown';



export default function StepOne() {
    const [dateData, setDateData] = useState(new Date());
    const [yearData, setYearData] = useState(new Date());
    const dispatch = useDispatch()
    // const [selectOnChangeData, setSelectSelectOnChangeData] = useState([])

    // store data
    const searchStepChange = useSelector((state) => state.searchStepChange.value);
    const searchFormData = useSelector((state) => state.searchFormData.value);

    const currentDate = new Date()
    const [selectedDate, setSelectedDate] = useState()
    const prevDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
    const currentYear = moment().year()
    const prevYear = moment().year() - 1
    const [isOpen, setIsOpen] = useState(false);
    const dropdownMonthRef = useRef(null);

    // dropdown outside click function
    useCloseDropdown(dropdownMonthRef, () => setIsOpen(false), isOpen)
    // open select field
    const toggle = () => {
        setIsOpen(!isOpen);
    };

    // change date
    const changeDate = (date) => {
        setDateData(date)
    };
    useEffect(() => {
        // add data to store
        dispatch(searchForm({
            date: dateData.toString(),
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: searchFormData.category,
            question: searchFormData.question,
            age: searchFormData.age,
            region: searchFormData.region,
            kids: searchFormData.kids,
        }))
    }, [dateData])

    useEffect(() => {
        // add data to store
        dispatch(searchForm({
            date: searchFormData.date ? searchFormData.date : dateData.toString(),
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: searchFormData.category,
            question: searchFormData.question,
            age: searchFormData.age,
            region: searchFormData.region,
            kids: searchFormData.kids,
        }))
        setTimeout(() => {
            // if date is already selected save data to show
            searchFormData.date ?
                setSelectedDate(new Date(searchFormData.date))
                : setSelectedDate(new Date())
        }, 100)

    }, [])


    function nextStep() {
        dispatch(searchStep({
            step1: searchStepChange.step1,
            step2: true,
            step3: searchStepChange.step3
        }))
    }

    // clock react-datepicker prev arrow  
    const handlePreviousClick = () => {
        document.querySelector('.react-datepicker__navigation-icon--previous') &&
            document.querySelector('.react-datepicker__navigation-icon--previous').click()
    }
    // clock react-datepicker next arrow  
    const handleNextClick = () => {
        document.querySelector('.react-datepicker__navigation-icon--next') &&
            document.querySelector('.react-datepicker__navigation-icon--next').click()
    }
    // year dropdown on change
    const changeYear = (e) => {
        setYearData(e.target.value)
        if (Number(e.target.value) === currentYear && currentYear !== prevYear) {
            handleNextClick()
        }
        if (Number(e.target.value) === prevYear && currentYear !== prevYear) {
            handlePreviousClick()
        }
    }

    return (
        <div className='pt-20'>
            <div className='stepCalendar relative'>
                <div className='tracking-wider opacity-50 ml-5 font-dropdownFont text-sm mb-4 '>Search by</div>
                <div className='flex w-full items-center'>
                    <div className='uppercase text-searchTextFontSize mr-4 font-firstFont font-semibold ml-5'>MONTH in</div>

                    <div onClick={() => toggle()} className="border border-borderColor rounded text-formSmallText py-[2px] relative cursor-pointer min-w-[5.938rem] font-dropdownFont h-fit">
                        {
                            yearData &&
                            <div className='line-one pl-4 pr-6 font-medium'>
                                {
                                    searchFormData.date ?
                                        moment(searchFormData.date).format('YYYY') : moment(yearData).format('YYYY')}
                            </div>
                        }
                        <span className="absolute right-4 top-[0.7rem]">
                            <img src={`/images/search/arrow-down.svg`} alt="arrow" />
                        </span>
                        {
                            currentYear !== prevYear &&
                            <ul className={`absolute w-full items z-10 font-dropdownFont min-w-[16rem]  ${isOpen ? 'block' : 'hidden'}`} ref={dropdownMonthRef} >
                                <li>
                                    <div className="flex relative">
                                        <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                            <input type="radio" name="radio" className="Radio" value={currentYear} defaultChecked
                                                onChange={(e) => changeYear(e)} />
                                            <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">{currentYear}</div>
                                            <span className="checkMarkDropdown"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex relative">
                                        <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                            <input type="radio" name="radio" className="Radio" value={prevYear}
                                                onChange={(e) => changeYear(e)} />
                                            <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">{prevYear}</div>
                                            <span className="checkMarkDropdown"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        }
                    </div>

                </div>
                {
                    selectedDate &&
                    <DatePicker
                        dateFormat="MM/yyyy"
                        selected={selectedDate}
                        minDate={prevDate}
                        maxDate={currentDate}
                        onChange={(date) => changeDate(date)}
                        showMonthYearPicker={true}
                        scrollableYearDropdown={false}
                        inline
                    />
                }
            </div>
            <ChangeSteps disablePrev={true} disableNext={!dateData} goPrev={''} goNext={() => nextStep()} step={1} />
        </div >
    )
}
