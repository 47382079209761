import React from 'react'
import FormTitle from '../gobal/FormTitle'
import SignUpForm from '../gobal/SignUpForm'
import GlobalLayout from '../layout/GlobalLayout'
import UserLayout from '../layout/UserLayout'

export default function SignUp() {
    return (
        <GlobalLayout showTopMenu={false} >
            <UserLayout>
                <div className='max-w-[45.188rem] px-4 font-formFont w-full pt-[5rem] md:pt-[15.313rem] pb-[5rem]'>
                    <div className='mb-7'>
                        <FormTitle info="Hello, welcome to" title="Impath" />
                    </div>

                    <SignUpForm />
                </div>
            </UserLayout>
        </GlobalLayout>
    )
}
