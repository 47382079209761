import React, { useEffect, useState } from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {gql, useMutation, useQuery} from "@apollo/client";
import {getMonth} from "../../helpers/getMonth";
import FullScreenLoader from "../../ui/FullScreenLoader";
// import { useForm } from 'react-hook-form';

export default function MenuSearch() {
    const [searchInput, setSearchInput] = useState('')
    const [searchText, setSearchText] = useState('')
    const [showResult, setShowResult] = useState(false)

    let navigate = useNavigate();


    const setSearchTextChange = (e) => {
        setSearchInput(e.target.value)
        setSearchText(e.target.value)
    }

    useEffect(() => {
        searchText.length > 2 ?
            setShowResult(true) : setShowResult(false)
    }, [searchText])

    const {data: searchData, loading: searchLoading, error: searchError} = useQuery(gql`
        query questions($filter: FilterQuestionInput) {
            questions(filter: $filter) {
                questionId
                question
                month
                year
                categoryId
                categoryName
            }
        }
    `, {
        variables: {
            filter: {
                question: searchText,
            }
        },
        skip: !searchText || searchText.length < 2
    })

    const [mutateSearch, {loading: mutateLoading}] = useMutation(gql`
        mutation createSearch($input: CreateSearchInput!) {
            createSearch(createSearchInput: $input) {
                searchId
                questionId
            }
        }
    `)

    const handleClick = (questionId, categoryId) => {
        mutateSearch({
            variables: {
                input: {
                    questionId,
                    categoryId,
                }
            }
        }).then(({data}) => {
            setSearchInput("");
            setSearchText("");
            setShowResult(false);
            navigate(`/demo-result/${data?.createSearch?.searchId}`);
        })
    }

    if (mutateLoading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <div className='relative'>

            <div className='relative'>
                <input type="text" className='search-input-field' name='search' placeholder="Search Question...." value={searchInput} onChange={(e) => setSearchTextChange(e)} />
                <img src={`/images/icons/search.svg`} alt="search" className='absolute top-2 left-3' />
            </div>

            <div className={`absolute top-8 left-0 w-full bg-dropdownBgColor max-h-[24.19rem] z-20 rounded-b-[0.188rem] font-dropdownFont font-medium cursor-pointer tracking-wider topScrollResult over overflow-auto ${showResult ? 'block' : 'hidden'}`} >
                <div className='text-xs pl-5 py-3'>{searchText}</div>
                {!searchLoading && !searchData?.questions.length && (
                    <div className='text-[rgba(255,255,255,0.45)] line-two pl-2 pb-3'>
                        Nothing found
                    </div>
                )}

                {
                    searchData?.questions?.map((question) => (
                        <div
                            key={question.questionId}
                            className='flex justify-between pl-5 pr-7 border-b border-b-transparentWhite15 py-3 items-center searchResult relative'
                            onClick={() => handleClick(question.questionId, question?.categoryId)}
                        >
                            <div className='text-personaTextFontSize '>{question.question}</div>
                            <div className='text-xs text-transparentWhite5'>{getMonth(question.month)} {question.year || "2022"}/ {question.categoryName}/ </div>
                        </div>
                    ))
                }
                {/*<div*/}
                {/*    className='flex justify-between pl-5 pr-7 border-b border-b-transparentWhite15 py-3 items-center searchResult relative'*/}
                {/*    onClick={() => setShowResult(false)}*/}
                {/*>*/}
                {/*    <div className='text-personaTextFontSize '>Do you listen to music when you work out/exercise?</div>*/}
                {/*    <div className='text-xs text-transparentWhite5'>Mar 2022/ Demo/ </div>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className='flex justify-between pl-5 pr-7 border-b border-b-transparentWhite15 py-3 items-center searchResult relative'*/}
                {/*    onClick={() => setShowResult(false)}*/}
                {/*>*/}
                {/*    <div className='text-personaTextFontSize '>Do you go in nature often?</div>*/}
                {/*    <div className='text-xs text-transparentWhite5'>Dec 2021/ Personas/ / 18-24  Y.O/ North America</div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
