import React, {useEffect, useMemo, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { age, region } from '../../../data/data'
import { searchForm } from '../../../features/searchFormData';
import useCloseDropdown from '../../lib/useCloseDropdown';
import {gql, useQuery} from "@apollo/client";

export default function StepTwoPersonaFields() {
    const [selectOnChangeAge, setSelectSelectOnChangeAge] = useState([])
    const [selectOnChangeRegion, setSelectSelectOnChangeRegion] = useState([])
    const [openAge, setOpenAge] = useState(false);
    const [openRegion, setOpenRegion] = useState(false);
    const [dataRegion, setDataRegion] = useState(region);
    const [searchRegion, setSearchRegion] = useState('');
    const [kids, setKids] = useState(false)
    const dispatch = useDispatch()
    const searchFormData = useSelector((state) => state.searchFormData.value);
    const dropdownAgeRef = useRef(null);
    const dropdownRegionRef = useRef(null);

    // dropdown outside click function
    useCloseDropdown(dropdownAgeRef, () => setOpenAge(false), openAge)
    useCloseDropdown(dropdownRegionRef, () => setOpenRegion(false), openRegion)
    // Age functions
    const toggle = () => {
        setOpenAge(!openAge);
        openRegion &&
            setOpenRegion(false);
    };

    function handleAgeChange(e) {
        // create array from checked items else if item unchecked remove it
        if (e.target.checked) {
            setSelectSelectOnChangeAge([...selectOnChangeAge, e.target.value])
        } else {
            setSelectSelectOnChangeAge(selectOnChangeAge.filter(item => item !== e.target.value))
        }
    }

    const allAgesSelected = useMemo(() => {
        return selectOnChangeAge.length === 4
    }, [selectOnChangeAge])

    function handleAgeSelectAll() {
        if (allAgesSelected) {
            setSelectSelectOnChangeAge([])
        } else {
            setSelectSelectOnChangeAge(age.map((el) => el.age))
        }
    }

    useEffect(() => {
        // if user select type save it in store
        selectOnChangeAge &&
            dispatch(searchForm({
                date: searchFormData.date,
                type: searchFormData.type,
                saveSearch: searchFormData.saveSearch,
                category: searchFormData.category,
                question: searchFormData.question,
                age: selectOnChangeAge,
                region: searchFormData.region,
                kids: searchFormData.kids,
            }))
    }, [selectOnChangeAge])

    // Region functions
    const toggleRegion = () => {
        setOpenRegion(!openRegion);
        openAge &&
            setOpenAge(false);
    };
    function handleRegionChange(e) {
        // create array from checked items else if item unchecked remove it
        setSearchRegion("")
        if (e.target.checked) {
            setSelectSelectOnChangeRegion([...selectOnChangeRegion, e.target.value.trim().toUpperCase()])
        } else {
            setSelectSelectOnChangeRegion(selectOnChangeRegion.filter(item => item !== e.target.value.trim().toUpperCase()))
        }
    }
    function changeSearchRegion(e) {
        setSearchRegion(e.target.value)
        e.target.value ?
            setDataRegion(
                region.filter((val) => val?.name.toUpperCase().includes(e.target.value.toUpperCase()))
            ) : setDataRegion(
                region
            )
    }

    useEffect(() => {
        // if user select region save it in store
        selectOnChangeRegion &&
            dispatch(searchForm({
                date: searchFormData.date,
                type: searchFormData.type,
                saveSearch: searchFormData.saveSearch,
                category: searchFormData.category,
                question: searchFormData.question,
                age: searchFormData.age,
                region: selectOnChangeRegion,
                kids: searchFormData.kids,
            }))
    }, [selectOnChangeRegion])

    // kids checkbox

    function changeKids() {
        setKids(!kids)
    }
    useEffect(() => {
        dispatch(searchForm({
            date: searchFormData.date,
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: searchFormData.category,
            question: searchFormData.question,
            age: searchFormData.age,
            region: searchFormData.region,
            kids: kids,
        }))
        console.log('kids', kids);
    }, [kids])

    useEffect(() => {
        searchFormData.region &&
            setSelectSelectOnChangeRegion(searchFormData.region)
        searchFormData.age &&
            setSelectSelectOnChangeAge(searchFormData.age)
        searchFormData.kids &&
            setKids(searchFormData.kids)
    }, [])

    const {data: personasData} = useQuery(gql`
        query personas {
            personas {
                personaId
                name
                region
            }
        }
    `)

    const regions = useMemo(() => {
        const aux = personasData?.personas?.map((persona) => persona.region);
        const filtered = [...new Set(aux || [])]
        return filtered.map((reg) => ({
            name: region.find((el) => el.abbreviation === reg)?.name || reg,
            abbreviation: reg,
        }))
    }, [personasData]);

    const filteredRegions = useMemo(() => {
        if (!searchRegion || !searchRegion.length) {
            return regions
        }
        return regions.filter((region) => region.name.toLowerCase().includes(searchRegion.toLowerCase()))
    }, [regions, searchRegion])

    const allCountriesSelected = useMemo(() => {
        return regions.length > 0 && regions.length === selectOnChangeRegion.length
    }, [regions, selectOnChangeRegion])

    function handleSelectAllRegions() {
        setSearchRegion("")
        if (!allCountriesSelected) {
            setSelectSelectOnChangeRegion(
                regions.map((region) => region.abbreviation.trim().toUpperCase())
            )
        } else {
            setSelectSelectOnChangeRegion([])
        }
    }

    return (
        <div className=' xl:w-[33.938rem] ml-[3.188rem] pl-[4.438rem] border-l-2 border-white h-full pt-[6.3rem]'>
            <div className='text-transparentWhite65 tracking-wider text-sm mb-5'>You can filter your information before going further.</div>
            <div className="dropdown-check-list relative font-dropdownFont font-medium mb-4" tabIndex="100">
                <div className='text-formSubSize pb-3 tracking-wider'>
                    Age*
                </div>
                <div ref={dropdownAgeRef}>
                    <div onClick={() => toggle()} className="border border-borderColor rounded text-xs py-[6px] relative cursor-pointer  tracking-wider">
                        {
                            // show selected data if exist
                            selectOnChangeAge && selectOnChangeAge.length !== 0 ? <div className='line-one pl-2 pr-6 opacity-75'>
                                {
                                    selectOnChangeAge.map((item, i) => (
                                        item + `${i !== selectOnChangeAge.length - 1 ? ', ' : ''}`
                                    ))
                                }
                            </div> :
                                <div className='text-white opacity-60 px-4 pl-2'>
                                    Select age
                                </div>
                        }
                        <span className="absolute right-4 top-[0.775rem]">
                            <img src={`/images/search/arrow-down-grey.svg`} alt="arrow" />
                        </span>
                    </div>
                    {/* age items  */}
                    <ul className={`absolute w-full items z-20 font-dropdownFont  ${openAge ? 'block' : 'hidden'}`}>
                        <li>
                            <div className="flex relative">
                                <label className="containerCheckboxDropdown border-b border-b-whiteBorderColor">

                                    <input type="checkbox" className="checkbox" value={"All"}
                                           onChange={(e) => handleAgeSelectAll()}
                                           checked={allAgesSelected}
                                    />

                                    <div className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">
                                        All
                                    </div>
                                    <span className="checkMarkDropdown"></span>
                                </label>
                            </div>
                        </li>
                        {
                            age &&
                            age.map((item, i) => (
                                <li key={i}>
                                    <div className="flex relative">
                                        <label className="containerCheckboxDropdown border-b border-b-whiteBorderColor">
                                            {
                                                selectOnChangeAge &&
                                                    selectOnChangeAge.includes(item.age.trim().toUpperCase()) ?
                                                    <input type="checkbox" className="checkbox" value={item.age}
                                                        onChange={(e) => handleAgeChange(e)}
                                                        checked={true}
                                                    />
                                                    : <input type="checkbox" className="checkbox" value={item.age}
                                                        onChange={(e) => handleAgeChange(e)}
                                                        checked={false}
                                                    />
                                            }

                                            <div className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">{item.age}</div>
                                            <span className="checkMarkDropdown"></span>
                                        </label>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="dropdown-check-list relative font-dropdownFont font-medium" tabIndex="100">

                <div className='text-formSubSize pb-3 tracking-wider'>
                    Region*
                </div>
                <div ref={dropdownRegionRef}>
                    <div onClick={() => toggleRegion()} className="border border-borderColor rounded text-xs py-[6px] relative cursor-pointer tracking-wider">
                        {
                            // show selected data if exist
                            selectOnChangeRegion && selectOnChangeRegion.length !== 0 ?
                                <div className='line-one pl-2 pr-6 opacity-75 capitalize'>
                                    {
                                        selectOnChangeRegion.map((item, i) => (
                                            (region.find(
                                                (el) => el.abbreviation === item)?.name || item
                                            ) +
                                            `${i !== selectOnChangeRegion.length - 1 ? ', ' : ''}`
                                        ))
                                    }
                                </div> :
                                <div className='text-white opacity-60 px-4 pl-2'>
                                    Choose Regions
                                </div>
                        }
                        <span className="absolute right-4 top-[0.775rem]">
                            <img src={`/images/search/arrow-down-grey.svg`} alt="arrow" />
                        </span>
                    </div>
                    <div className={`absolute w-full items z-10 font-dropdownFont  ${openRegion ? 'block' : 'hidden'} `}>
                        <div className='bg-dropdownBgColor relative'>
                            <input
                                type="text"
                                placeholder='Search Region...'
                                value={searchRegion}
                                onChange={(e) => changeSearchRegion(e)}
                                className="bg-transparent tracking-wider text-xs w-full outline-none px-8 py-4"
                            />
                            <img src="/images/icons/search.svg" alt="search" className='absolute top-[18px] left-3' />
                        </div>
                        <ul className={`absolute w-full items z-10 font-dropdownFont  ${openRegion ? 'block' : 'hidden'} max-h-[16rem] overflow-auto customScroll`}  >

                            <li>
                                <div className="flex relative">
                                    <label className="containerCheckboxDropdown border-b border-b-whiteBorderColor">

                                        <input type="checkbox" className="checkbox" name="region" value="All"
                                               onChange={(e) => handleSelectAllRegions()}
                                               checked={allCountriesSelected}
                                        />

                                        <div className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">
                                            All
                                        </div>
                                        <span className="checkMarkDropdown"></span>
                                    </label>
                                </div>
                            </li>

                            {
                                filteredRegions?.map((item, i) => (
                                    <li key={i}>
                                        <div className="flex relative">
                                            <label className="containerCheckboxDropdown border-b border-b-whiteBorderColor">
                                                {
                                                    selectOnChangeRegion && selectOnChangeRegion.length !== 0 && selectOnChangeRegion.includes(item.abbreviation.trim().toUpperCase()) ?

                                                        <input type="checkbox" className="checkbox" name="region" value={item.abbreviation}
                                                            onChange={(e) => handleRegionChange(e)}
                                                            checked={true}
                                                        />
                                                        :
                                                        <input type="checkbox" className="checkbox" name="region" value={item.abbreviation}
                                                            onChange={(e) => handleRegionChange(e)}
                                                            checked={false}
                                                        />
                                                }
                                                <div className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">{item.name}</div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>


                <div className="flex  relative mt-6">
                    <label className="containerCheckbox">
                        <input type="checkbox" className="checkbox" onChange={() => changeKids()} value={kids}
                            checked={kids} />
                        <span className="text-personaTextFontSize text-white tracking-wider">With Kids {kids}</span>
                        <span className="checkMark small"></span>
                    </label>
                </div>
            </div>
        </div>
    )
}
