import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {searchStep} from '../../features/searchStepChange'
import {changePopupData} from '../../features/showPopup'
import HiddenAudio from '../gobal/Popup/HiddenAudio'
import Popup from '../gobal/Popup/Popup'
import DemoSearchResult from '../gobal/SearchResult/DemoSearchResult'
import GlobalLayout from '../layout/GlobalLayout'
import TopMenu from '../layout/TopMenu'
import {UserStatusGoPage} from '../lib/userStatus'
import {gql, useMutation} from "@apollo/client";
import SuccessPopup from "../gobal/SuccessPopup";
import {SAVED_SEARCHES} from "../gobal/HomeSavedSearches/HomeSavedSearches";

export default function DemoResult() {
    UserStatusGoPage()
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const {id} = useParams()

    const [showSuccess, setShowSuccess] = useState(false)

    const [mutateSearch, {loading: mutateLoading}] = useMutation(gql`
        mutation createSearch($input: CreateSearchInput!) {
            createSearch(createSearchInput: $input) {
                searchId
                questionId
            }
        }
    `)

    const searchFormData = useSelector((state) => state.searchFormData.value);

    useEffect(() => {
        // if open save success message hide it in 5s
        setTimeout(() => {
            showSuccess &&
            setShowSuccess(false)
        }, 5000);
    }, [showSuccess])

    useEffect(() => {
        const {saveSearch, category, question} = searchFormData;
        if (!id && !question) {
            navigate("/")
            return;
        }
        if (saveSearch && category && question) {
            mutateSearch({
                variables: {
                    input: {
                        questionId: question,
                        categoryId: category,
                        saved: true,
                    }
                },
                refetchQueries: [{ query: SAVED_SEARCHES }],
            }).then(({data}) => {
                setShowSuccess(true)
                if (data) {
                    // navigate to search page after creating it
                    navigate(`/demo-result/${data?.createSearch?.searchId}`)
                }
            })
        }

        // if we play come to result page empty data so we can open popups and play audio 
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: false,
            activeAudio: ''
        }))
    }, [])

    // click on number to go search step
    function changeStep(step1, step2, step3) {
        dispatch(searchStep({
            step1: step1,
            step2: step2,
            step3: step3
        }))
        navigate("/search");
    }

    return (
        <GlobalLayout showTopMenu={false}>
            <SuccessPopup title="Success!" text="Your search was saved successfully!" showPopup={showSuccess}
                          closePopup={() => setShowSuccess(false)}/>
            {/* popup for audio and video  */}
            <Popup/>
            {/* hidden audio player */}
            <HiddenAudio/>
            <TopMenu/>
            <div className='pl-10 pr-5 mt-2'>
                <div className='flex font-firstFont text-sm tracking-wider'>
                    <div className='pr-5 border-r-2'>
                        <NavLink to={'/'} className='flex items-center opacity-60'>
                            <img src="/images/icons/back.svg" alt="back"/>
                            <span className='ml-[0.625rem]'>Dashboard</span>
                        </NavLink>
                    </div>
                    <div className='flex ml-7'>
                        <div onClick={() => changeStep(true, false, false)} className="cursor-pointer">
                            Step 1 Month /
                        </div>
                        <div className='ml-2 cursor-pointer' onClick={() => changeStep(true, true, false)}>
                            Step 2 Type /
                        </div>
                        <div className='ml-2 cursor-pointer' onClick={() => changeStep(true, true, true)}>
                            Step 3 Question
                        </div>
                    </div>
                </div>
                <DemoSearchResult/>
            </div>
        </GlobalLayout>
    )
}
