import React from 'react'
import { NavLink } from 'react-router-dom'
import FormTitle from '../gobal/FormTitle'
import GlobalLayout from '../layout/GlobalLayout'
import UserLayout from '../layout/UserLayout'

export default function ForgotPasswordSuccess() {
    return (
        <GlobalLayout showTopMenu={false} >
            <UserLayout>
                <div className='max-w-[32.375rem]  px-4 font-formFont w-full pt-[5rem] md:pt-[15.625rem] pb-[5rem]'>
                    <div className='mb-7'>
                        <FormTitle info="Hello, welcome to" title="Impath" />
                    </div>
                    <div className='flex items-end justify-center flex-col md:flex-row mt-[4.375rem]'>
                        <img src={`/images/success/icon.png`} alt="icon" className='max-w-[11.88rem]' />
                        <div className='ml-[1.875rem]'>
                            <div className='font-semibold text-formSmallText mb-3'>Verify your email</div>
                            <p className=' text-formSmallText -mb-1'>
                                We’ve sent you the details for resetting the password. Please follow the instructions presented in the email.
                            </p>
                        </div>
                    </div>
                    <NavLink to="/login" className="main-button w-fit mt-[4.813rem]" >
                        Back To Login
                    </NavLink>
                </div>
            </UserLayout>
        </GlobalLayout>
    )
}
