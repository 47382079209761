import React from 'react'
import { NavLink } from 'react-router-dom'

export default function ChangeSteps({ disablePrev, disableNext, goPrev, goNext, step, lastStep }) {
    return (
        <div className={`flex items-center justify-between text-base font-firstFont  mx-auto pb-10 ${lastStep ? 'w-full' : 'w-[25.938rem]'}`} >
            {
                disablePrev ?
                    <div className='opacity-50 flex items-center'>
                        <img src={`/images/search/prev.svg`} alt="close" className='w-4 h-4 object-contain mr-3' />
                        <span>Previous</span>
                    </div> :
                    <div className='cursor-pointer flex items-center' onClick={goPrev}>
                        <img src={`/images/search/prev.svg`} alt="close" className='w-4 h-4 object-contain mr-3' />
                        <span>Previous</span>
                    </div>
            }
            <div className='opacity-50'>
                {step} of 3
            </div>
            {
                disableNext ?
                    <div className=' opacity-50 flex items-center min-w-[84px] justify-end'>
                        <span>Next</span>
                        <img src={`/images/search/next.svg`} alt="close" className='w-4 h-4 object-contain ml-3' />
                    </div> : lastStep ?
                        // if it is last step go to result page
                        <NavLink to={lastStep}>
                            <div className='cursor-pointer flex items-center min-w-[84px] justify-end' onClick={goNext}>

                                <span>Next</span>
                                <img src={`/images/search/next.svg`} alt="close" className='w-4 h-4 object-contain ml-3' />
                            </div>
                        </NavLink> :
                        <div className='cursor-pointer flex items-center min-w-[84px] justify-end' onClick={goNext}>
                            <span>Next</span>
                            <img src={`/images/search/next.svg`} alt="close" className='w-4 h-4 object-contain ml-3' />
                        </div>
            }
        </div>
    )
}
