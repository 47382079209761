import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {logInFunctional} from '../../features/login';
import {useForm} from "react-hook-form";
import {NavLink} from 'react-router-dom';
import {userIsLoginFunctional} from '../../features/userIsLogIn';
import {gql, useMutation} from "@apollo/client";

export default function LoginForm() {
    const [sending, setSending] = useState(false)
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [emailErrorCompare, setEmailErrorCompare] = useState('')
    const [passErrorCompare, setPassErrorCompare] = useState('')
    const {
        register, handleSubmit, formState: {errors}, getValues, reset
    } = useForm();

    const [mutateLogin] = useMutation(gql`
        mutation login($input: LoginInput!) {
            login(input: $input) {
                accessToken
            }
        }
    `);

    // submit form
    const onSubmit = async data => {
        setSending(true)
        setEmailErrorCompare('')
        setPassErrorCompare('')

        mutateLogin({
            variables: {
                input: {
                    email: getValues("email"),
                    password: getValues("password"),
                }
            },
            onError: errors => {
                console.log('onError', errors.message)
                setSending(false)
                if (errors.message.includes('user')) {
                    setEmailErrorCompare(errors.message)
                    setPassErrorCompare(' ')
                } else if (errors.message.includes('Unauthorized')) {
                    setPassErrorCompare('Incorrect password!')
                } else {
                    setEmailErrorCompare('Email and password do not match!')
                    setPassErrorCompare('Email and password do not match!')
                }
            }
        }).then(({data}) => {
            setSending(false)
            if (data?.login?.accessToken) {
                console.log('accessToken', data.login.accessToken)
                localStorage.setItem('token', data.login.accessToken)

                // if login pass is right
                setSending(false)
                dispatch(logInFunctional({
                    email: getValues("email"), password: getValues("password"), remember: getValues("remember")
                }))
                reset({
                    email: "",
                    password: "",
                    remember: false
                })
                emailErrorCompare && setEmailErrorCompare('')
                passErrorCompare && setPassErrorCompare('')
                dispatch(userIsLoginFunctional({isLogIn: true}))
            }
        }).catch((er) => {
            console.log('er', er)
            setSending(false)
            setEmailErrorCompare('Email and password do not match!')
            setPassErrorCompare('Email and password do not match!')
        })
    };

    // submit firm end

    function handleEmailChange(e) {
        emailErrorCompare && setEmailErrorCompare('')
    }

    function handlePassChange(e) {
        passErrorCompare && setPassErrorCompare('')
    }


    return (

        <div className='text-black'>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="w-full md:mr-[0.625rem] pb-6 relative">
                    <label className="input-label" htmlFor="email">Email *</label>
                    <input className={`input-field ${errors.email || emailErrorCompare ? "input-field-error" : ''}`}
                           type="text" {...register("email", {
                        required: "This field is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Email format is incorrect"
                        },
                        onChange: (e) => handleEmailChange(e),
                    })} placeholder="example@company.io" id="email"/>
                    {
                        // if form email is wrong or dodnlt match password
                        !errors.email && emailErrorCompare &&
                        <span className="itemErrorMessage">
                            {emailErrorCompare}
                        </span>
                    }
                    {
                        errors.email &&
                        <span className="itemErrorMessage">
                            {errors.email.message}
                        </span>
                    }
                </div>

                <div className="w-full md:mr-[1rem] mb-10 relative">
                    <label className="input-label" htmlFor="password">Password *</label>
                    <input className={`input-field ${errors.password || passErrorCompare ? "input-field-error" : ''}`}
                           type={showPassword ? "text" : "password"}  {...register("password", {
                        required: "This field is required",
                        minLength: {
                            value: 4,
                            message: "Password must have at least 4 characters"
                        },
                        onChange: (e) => handlePassChange(e),

                    })} placeholder="Min. 6 characters" id="password"/>
                    <span className={`passwordIcon ${showPassword ? "show" : ""}`}
                          onClick={() => setShowPassword(!showPassword)}> </span>
                    {
                        // if form email is wrong or dodnlt match password
                        !errors.password && passErrorCompare &&
                        <span className="itemErrorMessage">
                            {passErrorCompare}
                        </span>
                    }
                    {
                        errors.password && <span className="itemErrorMessage">{errors.password.message}</span>
                    }
                </div>
                <div className='flex justify-between pb-8'>
                    <div className="flex  relative">
                        <label className="containerCheckbox">
                            <input type="checkbox" className="checkbox" {...register('remember')} />
                            <span className="text-formSubSize text-white ">Remember Me</span>
                            <span className="checkMark"></span>
                        </label>
                    </div>
                    <div className="text-formSubSize text-white ">
                        <NavLink to="/forgot-password" className="text-mainColor">Forgot Password?</NavLink>
                    </div>
                </div>
                {
                    // if we are sending data
                    sending ?
                        <div className="main-button w-fit">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div> :
                        // after or before send
                        <input type="submit" value={"Login"} className="main-button"/>
                }
                <div className="text-formSubSize text-white pt-[3.125rem]">Not a member? Sign Up <NavLink to="/sign-up"
                                                                                                          className="text-mainColor">Here.</NavLink>
                </div>
            </form>
        </div>
    )
}
