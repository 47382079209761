import React from 'react'
import FormTitle from '../gobal/FormTitle'
import GlobalLayout from '../layout/GlobalLayout'
import UserLayout from '../layout/UserLayout'
import ForgotPasswordForm from "../gobal/ForgotPasswordForm";

export default function ForgotPassword() {
    return (
        <GlobalLayout showTopMenu={false} >
            <UserLayout>
                <div className='max-w-[32.375rem]  px-4 font-formFont w-full pt-[5rem] md:pt-[15.625rem] pb-[5rem]'>
                    <div className='mb-7'>
                        <FormTitle info="Hello, welcome to" title="Impath" />
                    </div>
                    <ForgotPasswordForm />
                </div>
            </UserLayout>
        </GlobalLayout>
    )
}
