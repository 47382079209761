import React from 'react'
import { searchResultDemo } from '../../../data/data'

export default function DemoSidebar({question}) {
    return (
        <div className='font-firstFont'>
            <div className='font-medium text-lg pb-1'>Answers by Demo</div>
            {/*{*/}
            {/*    searchResultDemo.answersBy.map((item, i) => (*/}
            {/*        <div key={item.id} className={` pt-2 pb-[0.375rem] ${searchResultDemo.answersBy.length === i + 1 ? ' ' : 'border-b border-transparentWhite7'}`} >*/}
            {/*            <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>*/}
            {/*                Women {item.age}*/}
            {/*                {*/}
            {/*                    item.status &&*/}
            {/*                    <span> / <span className='opacity-50'> {item.status}</span></span>*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*            <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>*/}
            {/*                {*/}
            {/*                    item.answers &&*/}
            {/*                    item.answers.map((info, j) => (*/}
            {/*                        <li key={j}>*/}
            {/*                            {j + 1}. {info.item}*/}
            {/*                        </li>*/}
            {/*                    ))*/}
            {/*                }*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    ))*/}
            {/*}*/}
            <div className={`pt-5 pb-5 border-b border-transparentWhite7`} >
                <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>
                    Women 18-24
                </div>
                <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>
                    {
                        question?.parsedDemoCat1?.map((info, j) => (
                            <li key={j}>
                                {j + 1}. {info}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={` pt-5 pb-5 border-b border-transparentWhite7`} >
                <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>
                    Women 25-34
                    <span> / <span className='opacity-50'> without kids </span></span>
                </div>
                <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>
                    {
                        question?.parsedDemoCat2?.map((info, j) => (
                            <li key={j}>
                                {j + 1}. {info}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={` pt-5 pb-5 border-b border-transparentWhite7`} >
                <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>
                    Women 25-34
                    <span> / <span className='opacity-50'> with kids </span></span>
                </div>
                <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>
                    {
                        question?.parsedDemoCat3?.map((info, j) => (
                            <li key={j}>
                                {j + 1}. {info}
                            </li>
                        ))
                    }
                </ul>
            </div>

            <div className={` pt-5 pb-5 border-b border-transparentWhite7`} >
                <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>
                    Women 34-44
                    <span> / <span className='opacity-50'> without kids </span></span>
                </div>
                <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>
                    {
                        question?.parsedDemoCat4?.map((info, j) => (
                            <li key={j}>
                                {j + 1}. {info}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={` pt-5 pb-5 border-b border-transparentWhite7`} >
                <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>
                    Women 34-44
                    <span> / <span className='opacity-50'> with kids </span></span>
                </div>
                <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>
                    {
                        question?.parsedDemoCat5?.map((info, j) => (
                            <li key={j}>
                                {j + 1}. {info}
                            </li>
                        ))
                    }
                </ul>
            </div>

            <div className={` pt-5 pb-5 `} >
                <div className='tracking-[0.04em] text-sm font-bold text-mainColor mb-1'>
                    Women 45-55
                </div>
                <ul className='tracking-wider text-transparentWhite8 text-personaTextFontSize leading-5 mb-1'>
                    {
                        question?.parsedDemoCat6?.map((info, j) => (
                            <li key={j}>
                                {j + 1}. {info}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div >
    )
}
