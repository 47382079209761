import React from 'react'
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeMonthItem from './HomeMonthItem';
import {homeSliderMonth} from '../../../data/data';

export default function HomeMonthSlider({questions}) {

    // slider arrows is set custom style
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        draggable: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    return (
        questions && questions.length > 0 ? (
            <div className='w-full relative font-dropdownFont monthSlider'>
                <Slider {...settings}>
                    {
                        questions.map((question, i) => (
                            <HomeMonthItem question={question} key={question.questionId} itemIndex={`monthItem${i}`}/>
                        ))
                    }
                </Slider>
            </div>
        ) : (
            // if nothing found
            <div className='relative flex mb-8'>
                <div
                    className="border  border-white rounded-[0.313rem] mx-[0.625rem]    text-white text-xs py-[5px] px-[10px] min-h-[5rem] flex -tracking-tighter   items-center">
                    <div>
                        <div className='line-three max-h-12 my-auto font-semibold mb-1'>
                            Nothing found for selected month
                        </div>
                        <div className='text-[rgba(255,255,255,0.45)] line-two'>
                            Data for selected month hasn't been added yet in the system
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
