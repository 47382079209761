import React from 'react'
import {region} from "../../../data/data";

export default function SinglePersonaSidebar({ data, persona }) {
    return (
        <div className='tracking-[0.04em] font-firstFont pb-5'>
            <div className='flex justify-center relative'>
                <div className='w-[10.13rem] h-[6rem] absolute bg-mainColor left-0 top-12 z-10'></div>
                {
                    persona?.image &&
                    <img src={persona.image} alt={persona.name} className="w-[11.44rem] h-[11.44rem] object-cover border-[3px] border-mainColor rounded-[100%] relative z-20 personaImageShadow" />
                }
            </div>
            <div className='text-center font-medium text-base mt-5 text-mainColor'>{persona?.name}</div>
            <div className='pl-[4.375rem] pr-[3.125rem]'>
                <div className='mt-[1.875rem]'>
                    <div className='flex items-center mb-2'>
                        <img src="/images/persona/person.svg" alt="info" className='w-[1.75rem] h-[1.69rem] object-contain' />
                        <div className='ml-3 text-xs'>
                            {persona?.age} <span className='text-transparentWhite5'>years old</span>,  {
                            persona?.region &&
                            region.find((el) => el.abbreviation === persona.region)?.name
                            || persona?.region
                        }
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <img src="/images/persona/heart.svg" alt="info" className='w-[1.75rem] h-[1.69rem] object-contain' />
                        <div className='ml-3 text-xs text-transparentWhite5'>
                            {data?.status}, Kids - {persona?.kids}
                        </div>
                    </div>
                </div>

                <div className='mt-[3.063rem]'>
                    <div className='font-medium text-sm mb-3'>Overview</div>
                    <div className=' text-xs leading-[1.125rem] text-transparentWhite65'>{persona?.overview}</div>
                </div>

                <div className='border-2 border-transparentWhite4 rounded p-3 text-xs mt-5'>
                    <div className='mb-1 font-semibold'>Personality</div>
                    <div className='text-transparentWhite65'>{data?.personality}</div>
                </div>
                <div className='border-2 border-transparentWhite4 rounded p-3 text-xs mt-5'>
                    <div className='mb-1 font-semibold'>Motivation</div>
                    <div className='text-transparentWhite65'>{data?.motivation}</div>
                </div>
                <div className='border-2 border-transparentWhite4 rounded p-3 text-xs mt-5'>
                    <div className='mb-1 font-semibold'>Goals</div>
                    <div className='text-transparentWhite65'>{data?.goals}</div>
                </div>
                <div className='border-2 border-transparentWhite4 rounded p-3 text-xs mt-5'>
                    <div className='mb-1 font-semibold'>Frustration</div>
                    <div className='text-transparentWhite65'>{data?.frustration}</div>
                </div>
            </div>
        </div>
    )
}
