import React from 'react'

export default function HomeSectionTitle({ title }) {
    return (
        <div className='uppercase font-firstFont text-sm -tracking-tighter font-medium'>
            <span className='text-transparentWhite'>search by </span>
            <span>{title}</span>
        </div>
    )
}
