import React, {useMemo} from 'react'
import {homeSidebar} from '../../../data/data'
import HomeSidebarItem from './HomeSidebarItem'
import {gql, useQuery} from "@apollo/client";

export default function HomeSidebar() {

    const {data, loading} = useQuery(gql`
        query questionsMostViewed {
            questionsMostViewed {
                questionId
                question
                categoryId
                categoryName
                viewsCount
                month
                year
                parsedContent
                topAnswers {
                    topAnswerId
                    answer {
                        parsedAnswer
                    }
                    persona {
                        name
                        age
                        kids
                        status
                        region
                        image
                    }
                }
            }
        }
    `)

    return (
        <div className='pl-11'>
            <div className='uppercase font-firstFont tracking-wider  text-sm border-b border-transparentWhite pb-5'>
                Popular among others
            </div>
            {
                data?.questionsMostViewed?.map((question, i) => (
                    <HomeSidebarItem
                        question={question}
                        key={question.questionId}
                        itemIndex={`sidebarItem${i}`}
                    />
                ))
            }
        </div>
    )
}
