import React from 'react'
import {useSelector} from 'react-redux';
import {region} from "../../../data/data";


export default function PersonaAdditionalInfo({search}) {
    const searchFormData = useSelector((state) => state.searchFormData.value);

    return (
        search ? (
            <div className='flex font-firstFont text-sm tracking-wider mt-5 mb-7'>
                <div>Age/ <span className='text-transparentWhite45'>
                    {search.demoCat1 && "18-24"} {(search.demoCat2 || search.demoCat3) && "25-34"} {(search.demoCat4 || search.demoCat5) && "35-44"} {search.demoCat6 && "45-54"}
                </span>
                </div>
                <div className='ml-[3.125rem]'>Region/ <span className='text-transparentWhite45 capitalize'>{
                    search.parsedRegions?.map((item, i) => (
                        (region.find(
                                (el) => el.abbreviation === item)?.name || item
                        ) +
                        `${i !== search.parsedRegions.length - 1 ? ', ' : ''}`
                    ))
                }</span>
                </div>
                <div className='ml-[3.125rem]'>Kids/ <span className='text-transparentWhite45'>
                {
                    search.kids ?
                        "With kids" : "Without kids"
                }
            </span>
                </div>
            </div>
        ) : (
            <div className='flex font-firstFont text-sm tracking-wider mt-5 mb-7'>
                <div>Age/ <span className='text-transparentWhite45'>{
                    searchFormData.age &&
                    searchFormData.age.map((item, i) => (
                        item + `${i !== searchFormData.age.length - 1 ? ', ' : ''}`
                    ))
                }</span>
                </div>
                <div className='ml-[3.125rem]'>Region/ <span className='text-transparentWhite45 capitalize'>{
                    searchFormData.region &&
                    searchFormData.region.map((item, i) => (
                        item + `${i !== searchFormData.region.length - 1 ? ', ' : ''}`
                    ))
                }</span>
                </div>
                <div className='ml-[3.125rem]'>Kids/ <span className='text-transparentWhite45'>
                {
                    searchFormData.kids ?
                        "With kids" : "Without kids"
                }
            </span>
                </div>
            </div>
        )
    )
}
