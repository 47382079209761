import React from 'react'

export default function FormTitle(props) {
    return (
        <div >
            <div className='uppercase opacity-50 font-firstFont font-medium tracking-wider text-formSubSize mb-2'>{props.info}</div>
            <div className='uppercase font-secondFont text-titleSize'>{props.title}</div>
        </div>
    )
}
