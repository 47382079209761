import DemoResult from "./components/pages/DemoResult";
import FirstLoginCategory from "./components/pages/FirstLoginCategory";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Personas from "./components/pages/Personas";
import PersonaResult from "./components/pages/PersonaResult";
import Search from "./components/pages/Search";
import SignUp from "./components/pages/SignUp";
import SignUpSuccess from "./components/pages/SignUpSuccess";
import SinglePersona from "./components/pages/SinglePersona";
import VerifyEmail from "./components/pages/VerifyEmail";
import ForgotPassword from "./components/pages/ForgotPassword";
import ForgotPasswordSuccess from "./components/pages/ForgotPasswordSuccess";
import AccountRecovery from "./components/pages/AccountRecovery";


const routes = [
    {
        path: '/sign-up',
        exact: true,
        element: <SignUp />
    },
    {
        path: '/sign-up-success',
        exact: true,
        element: <SignUpSuccess />
    },
    {
        path: '/confirm-email/:token',
        exact: true,
        element: <VerifyEmail />
    },
    {
        path: '/forgot-password',
        exact: true,
        element: <ForgotPassword />
    },
    {
        path: '/forgot-password-success',
        exact: true,
        element: <ForgotPasswordSuccess />
    },
    {
        path: '/recover-account/:token',
        exact: true,
        element: <AccountRecovery />
    },
    {
        path: '/login',
        exact: true,
        element: <Login />
    },
    {
        path: '/choose-category',
        exact: true,
        element: <FirstLoginCategory />
    },
    {
        path: '/search',
        exact: true,
        element: <Search />
    },
    {
        path: '/demo-result',
        exact: true,
        element: <DemoResult />
    },
    {
        path: '/demo-result/:id',
        exact: true,
        element: <DemoResult />
    },
    {
        path: '/persona-result',
        exact: true,
        element: <PersonaResult />
    },
    {
        path: '/persona-result/:id',
        exact: true,
        element: <PersonaResult />
    },
    {
        path: '/persona/:id',
        exact: true,
        element: <SinglePersona />
    },
    {
        path: '/personas',
        exact: true,
        element: <Personas />
    },
    {
        path: '/',
        exact: true,
        element: <Home />
    },
    {
        path: '*',
        exact: false,
        element: <div className='text-white'>404 page not found</div>
    },
]
export default routes;