import React from 'react'
import HomeSectionTitle from '../HomeSectionTitle'
import HomeSavedSearchesSlider from './HomeSavedSearchesSlider'
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../../ui/FullScreenLoader";

export const SAVED_SEARCHES = gql`
        query savedSearches {
            savedSearches {
                searchId
                saved
                categoryId
                questionId
                type
                category {
                    categoryId
                    name
                }
                question {
                    questionId
                    question
                    month
                    year
                }
            }
        }
    `

export default function HomeSavedSearches() {

    const {data: savedSearchesData, loading} = useQuery(SAVED_SEARCHES)

    if (loading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <div className='w-full mt-11'>
            <div className='pl-[0.625rem] mb-4'>
                <HomeSectionTitle
                    title={`SAVED SEARCHES (${savedSearchesData?.savedSearches?.length || 0})`}
                />
            </div>
            <HomeSavedSearchesSlider savedSearches={savedSearchesData?.savedSearches} />
        </div>
    )
}
