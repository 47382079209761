import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changePopupData } from '../../../features/showPopup';

export default function PersonaAnswerItem({ data, itemIndex }) {
    const dispatch = useDispatch()
    const showPopup = useSelector((state) => state.showPopup.value);
    // open popup with image or video
    const openPopup = (image, video) => {
        dispatch(changePopupData({
            video: video,
            image: image,
            open: true,
            audio: '',
            playAudio: false
        }))
        document.body.classList.add("overflow-hidden");
    }
    //  play audio
    const playAudio = (audio, i) => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: audio,
            playAudio: true,
            activeAudio: i
        }))
    }
    //  stop playing audio
    const stopAudio = () => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: true,
            activeAudio: ''
        }))
    }
    return (
        <div className='flex items-center py-4 justify-center '>
            {
                // if data is post open other website url
                data?.post ?
                    <a href={data?.post} target='_blank' rel="noreferrer" >
                        <div className='relative min-h-[10.31rem!important] min-w-[10.69rem!important] max-h-[10.31rem!important] max-w-[10.69rem!important] cursor-pointer'>
                            <img src={data?.img} alt={data.section} className="min-h-[10.31rem!important] min-w-[10.69rem!important] max-h-[10.31rem!important] max-w-[10.69rem!important] object-cover rounded-[0.313rem]" />
                            <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end pr-[0.438rem] pb-[0.313rem]'>
                                <img src={`/images/icons/post.svg`} alt="play" className='w-[2.81rem] h-[2.81rem]' />
                            </div>
                        </div>
                    </a> :
                    // if data is other then post open popup 
                    <div className='relative min-h-[10.31rem!important] min-w-[10.69rem!important] max-h-[10.31rem!important] max-w-[10.69rem!important] cursor-pointer'>
                        <img src={data?.img} alt={data.section} className="min-h-[10.31rem!important] min-w-[10.69rem!important] max-h-[10.31rem!important] max-w-[10.69rem!important] object-cover rounded-[0.313rem]" />
                        {
                            data?.video &&
                            <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end pr-[0.438rem] pb-[0.313rem]' onClick={() => openPopup('', data?.video)} >
                                <img src={`/images/icons/play.svg`} alt="play" className='w-[2.81rem] h-[2.81rem]' />
                            </div>
                        }
                        {
                            data?.audio &&
                            <div>
                                {
                                    // if we click on certain item show animation
                                    itemIndex !== showPopup.activeAudio ?
                                        <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end pr-[0.438rem] pb-[0.313rem]' onClick={() => playAudio(data?.audio, itemIndex)} >
                                            <img src={`/images/icons/play.svg`} alt="play" className='w-[2.81rem] h-[2.81rem]' />
                                        </div> :
                                        <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end pr-[0.438rem] pb-[0.313rem]' onClick={() => stopAudio()} >
                                            <div className="box mediumBox">
                                                <div className="box__line  mediumBox__line"></div>
                                            </div>
                                        </div>

                                }

                            </div>

                        }
                        {
                            data?.popupImage &&
                            <div className='absolute w-full h-full top-0 left-0 flex justify-end items-end pr-[0.438rem] pb-[0.313rem]' onClick={() => openPopup(data?.popupImage, '')} >
                                <img src={`/images/icons/image-icon.svg`} alt="play" className='w-[2.81rem] h-[2.81rem]' />
                            </div>
                        }
                    </div>
            }
        </div>
    )
}
