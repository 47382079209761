import {createSlice} from "@reduxjs/toolkit";

const initialStateValue = {isLogIn: !!localStorage.getItem('token')}

export const userSlice = createSlice({
    name: "user",
    initialState: {value: initialStateValue},
    reducers: {
        userIsLoginFunctional: (state, action) => {
            state.value = action.payload;
        },
        userIsLogoutFunctional: (state, action) => {
            state.value = {
                ...initialStateValue,
                isLogIn: false
            }
        },
    },
});

export const {userIsLoginFunctional, userIsLogoutFunctional} = userSlice.actions;

export default userSlice.reducer;