import React, { useEffect, useRef, useState } from 'react'
import { personaList } from '../../../data/data'
import ReactPaginate from 'react-paginate';
import PersonaDashboardCard from './PersonaDashboardCard';
import PersonaDashboardTable from './PersonaDashboardTable';
import useCloseDropdown from '../../lib/useCloseDropdown';

export default function PersonaData({personas = []}) {

    const [offset, setOffset] = useState(0);
    const [startSlice, setStartSlice] = useState(0);
    const [data, setData] = useState([]);
    const [perPage, setPerPage] = useState(15);
    const [pageCount, setPageCount] = useState(0)
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenType, setIsOpenType] = useState(false);
    const [count, setCount] = useState(15);
    const [type, setType] = useState('Cards');
    const typeRef = useRef(null);
    const countRef = useRef(null);

    useCloseDropdown(typeRef, () => setIsOpenType(false), isOpenType)
    useCloseDropdown(countRef, () => setIsOpen(false), isOpen)


    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage)
        setStartSlice(selectedPage * perPage)
    };

    const getData = async () => {
        const data = personas;
        const slice = personas.slice(startSlice,
            startSlice + perPage)
        const postData = slice.map((item) => (
            type === 'Cards' ?
                <div key={item.personaId} className="w-1/1 md:w-1/4 px-4 pb-6">
                    <PersonaDashboardCard data={item} />
                </div>
                : <div key={item.personaId} >
                    <PersonaDashboardTable data={item} />
                </div>
        ))
        setData(postData)
        setPageCount(Math.ceil(data.length / perPage))
    }

    useEffect(() => {
        getData()
    }, [offset, type, perPage, personas])


    // open select field
    const toggle = () => {
        setIsOpen(!isOpen);
    };



    // change date
    const changeCount = (date) => {
        setCount(Number(date.target.value))
        // Number(date.target.value) !== Number(perPage) &&
        setPerPage(count)
    };
    useEffect(() => {
        setPerPage(count)
        // if we change items count per page and we are not on first page go to first page
        setTimeout(() => {
            document.querySelector(`.pagination li:nth-child(2) a`).click()
        }, 10);

    }, [count])

    // open type select field
    const toggleType = () => {
        setIsOpenType(!isOpenType);
    };

    function changeType(e) {
        setType(e.target.value)
    }

    return (
        <div className=' max-w-[80rem] border-t border-t-transparentWhite25'>
            <div className='flex justify-between mb-12 mt-6'>
                <div>
                    <div className='flex items-center' ref={typeRef}>
                        <span className='font-firstFont text-sm font-semibold tracking-wider mr-3' onClick={() => setIsOpenType(false)}>RESULT</span>

                        <div onClick={() => toggleType()} className={`border  rounded text-formSmallText py-[2px] relative cursor-pointer w-[8.25rem] font-dropdownFont h-fit ${isOpenType ? 'border-mainColor' : 'border-borderColor'}`} >
                            {

                                <div className={`line-one pl-3 pr-6 font-medium ${isOpenType ? 'text-mainColor' : 'text-white'}`}>
                                    See {type}
                                </div>
                            }
                            <span className="absolute right-3 top-[0.7rem]">
                                <img src={` ${isOpenType ? '/images/search/yellow-arrow-up.svg' : '/images/search/arrow-down.svg'}`} alt="arrow" />
                            </span>
                            {

                                <ul className={`absolute w-full items z-10 font-dropdownFont min-w-[16rem]  ${isOpenType ? 'block' : 'hidden'} top-8`}  >
                                    <li>
                                        <div className="flex relative">
                                            <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                                <input type="radio" name="type" className="Radio" value={'Cards'}
                                                    onChange={(e) => changeType(e)} defaultChecked />
                                                <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">Cards</div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex relative">
                                            <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                                <input type="radio" name="type" className="Radio" value={'Table'}
                                                    onChange={(e) => changeType((e))} />
                                                <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">Table</div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>

                                </ul>
                            }
                        </div>
                    </div>
                </div>

                <div className='flex items-center' ref={countRef}>
                    <span className='mr-1 font-dropdownFont text-sm tracking-wider' onClick={() => setIsOpen(false)}>See</span>

                    <div onClick={() => toggle()} className="border border-borderColor rounded text-formSmallText py-[2px] relative cursor-pointer w-[3.688rem] font-dropdownFont h-fit">
                        {

                            <div className='line-one pl-3 pr-6 font-medium'>
                                {count}
                            </div>
                        }
                        <span className="absolute right-1 top-[0.7rem]">
                            <img src={`/images/search/arrow-down.svg`} alt="arrow" />
                        </span>
                        {

                            <ul className={`absolute w-full items z-10 font-dropdownFont min-w-[16rem]  ${isOpen ? 'block' : 'hidden'} top-8`}  >
                                <li>
                                    <div className="flex relative">
                                        <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                            <input type="radio" name="radio" className="Radio" value={15} defaultChecked
                                                onChange={(e) => changeCount(e)} />
                                            <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">{15}</div>
                                            <span className="checkMarkDropdown"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex relative">
                                        <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                            <input type="radio" name="radio" className="Radio" value={30}
                                                onChange={(e) => changeCount(e)} />
                                            <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">{30}</div>
                                            <span className="checkMarkDropdown"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex relative">
                                        <label className="containerRadioDropdown border-b border-b-whiteBorderColor">
                                            <input type="radio" name="radio" className="Radio" value={50}
                                                onChange={(e) => changeCount(e)} />
                                            <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">{50}</div>
                                            <span className="checkMarkDropdown"></span>
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        }
                    </div>
                    <span className='ml-1 font-dropdownFont text-sm tracking-wider' onClick={() => setIsOpen(false)}>on page</span>
                </div>
            </div>

            <div>


                <div className='  mt-3'>

                    {
                        // data style table
                        data &&
                        type === 'Table' &&
                        <div>
                            <div className='flex w-full font-firstFont font-medium text-sm tracking-[0.04em] text-transparentWhite6 pb-4 border-b'>
                                <div className='w-3/12'>
                                    User
                                </div>
                                <div className='w-1/12'>
                                    Age
                                </div>
                                <div className='w-2/12'>
                                    Region
                                </div>
                                <div className='w-2/12'>
                                    Social State
                                </div>
                                <div className='w-3/12'>
                                    Personality
                                </div>
                                <div className='w-1/12'>
                                    Answers
                                </div>
                            </div>
                            <div  >
                                {data}
                            </div>
                        </div>
                    }
                    {
                        // data style card
                        data && type === 'Cards' &&
                        <div className='flex flex-wrap -mx-4'>
                            {data}
                        </div>
                    }
                </div>
                <div className='personaPagination relative text-[17px] font-firstFont  mx-auto pb-10 pt-1.625'>
                    <div className='flex justify-center text-transparentWhite5 mt-5'>
                        {
                            offset + 1 + ' of ' + pageCount
                        }
                    </div>
                    <ReactPaginate
                        previousLabel={<div className='cursor-pointer flex items-center pageChange'  >
                            <img src={`/images/search/prev.svg`} alt="close" className='w-4 h-4 object-contain mr-3' />
                            <span>Previous</span>
                        </div>}
                        nextLabel={<div className='cursor-pointer flex items-center min-w-[84px] justify-end pageChange' >
                            <span>Next</span>
                            <img src={`/images/search/next.svg`} alt="close" className='w-4 h-4 object-contain ml-3' />
                        </div>}
                        breakLabel={"/"}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        extraAriaContext={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        </div>
    )
}
