import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { step1: true, step2: false, step3: false }

export const userSlice = createSlice({
    name: "searchStepChange",
    initialState: { value: initialStateValue },
    reducers: {
        searchStep: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { searchStep } = userSlice.actions;

export default userSlice.reducer;