import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserStatusGoPage } from '../../lib/userStatus'
import SuccessPopup from '../SuccessPopup'
import Filters from './Filters'
import PersonaAdditionalInfo from './PersonaAdditionalInfo'
import PersonaList from './PersonaList'
import PersonaSidebar from './PersonaSidebar'
import {useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../../ui/FullScreenLoader";


export default function PersonaSearchResult() {
    const {id} = useParams()

    const [showSuccess, setShowSuccess] = useState(false)
    UserStatusGoPage()
    const searchFormData = useSelector((state) => state.searchFormData.value);

    const {data, loading, error} = useQuery(gql`
        query search($id: Int!) {
            search(id: $id) {
                searchId
                categoryId
                questionId
                type
                regions
                parsedRegions
                kids
                demoCat1
                demoCat2
                demoCat3
                demoCat4
                demoCat5
                demoCat6
                question {
                    questionId
                    categoryId
                    question
                    insight
                    finding
                    content
                    parsedContent
                    topAnswers {
                        answerId
                        answer {
                            answerId
                            parsedAnswer
                            personaId
                        }
                        persona {
                            personaId
                            name
                            age
                        }
                    }
                    answers {
                        answerId
                        answer
                        parsedAnswer
                        persona {
                            personaId
                            name
                            age
                            region
                            kids
                            status
                            image
                        }
                    }
                    month
                    year
                }
            }
        }
    `, {
        variables: {
            id: Number(id)
        },
        skip: !id || !Number(id),
    });

    const [questionId, setQuestionId] = useState();

    const {data: questionData, loading: questionLoading} = useQuery(gql`
        query question($id: Int!) {
            question(id: $id) {
                
                questionId
                categoryId
                question
                insight
                finding
                content
                parsedContent
                topAnswers {
                topAnswerId
                    answerId
                    answer {
                        answerId
                        parsedAnswer
                        personaId
                    }
                    persona {
                        personaId
                        name
                        age
                    }
                }
                answers {
                    answerId
                    answer
                    parsedAnswer
                    personaId
                    persona {
                        personaId
                        name
                        age
                        region
                        kids
                        status
                        image
                    }
                }
                month
                year
                
            }
        }
    `, {
        variables: {
            id: Number(questionId),
        },
        skip: !questionId || !Number(questionId),
    })

    if (loading) {
        return (
            <FullScreenLoader/>
        )
    }

    const filterByAge = (answers) => {
        if (!answers) {
            return null;
        }
        if (!data?.search) {
            return answers;
        }
        return answers.filter((answer) => {
            const age = answer?.persona?.age || 0
            const kids = answer?.persona?.kids && answer?.persona?.kids !== "No"
            const region = answer?.persona?.region
            // check region
            if (region && data.search?.parsedRegions && !data.search?.parsedRegions?.includes(region)) {
                return false;
            }
            if (age < 25 && data?.search?.demoCat1) {
                return true
            }
            if (age < 35 && data?.search?.demoCat2 && !kids) {
                return true
            }
            if (age < 35 && data?.search?.demoCat3 && kids) {
                return true
            }
            if (age < 45 && data?.search?.demoCat4 && !kids) {
                return true
            }
            if (age < 45 && data?.search?.demoCat5 && kids) {
                return true
            }
            if (age > 44 && data?.search?.demoCat6) {
                return true
            }
            return false;
        })
    }

    return (
        <div className='pt-16 flex xl:pr-[4.75rem] lg:flex-row flex-col pb-20'>
            {/*<SuccessPopup title="Success!" text="Your search was saved successfully!" showPopup={showSuccess} closePopup={() => setShowSuccess(false)} />*/}
            <div className='w-full lg:w-[22%] pr-5 border-r-2'>
                {/* <DemoSidebar /> */}
                <PersonaSidebar
                    topAnswers={questionData?.question?.topAnswers || data?.search?.question?.topAnswers}
                />
            </div>
            <div className='w-full lg:w-[78%] md:pl-14'>
                <Filters
                    title="Personas"
                    question={questionData?.question || data?.search?.question || data?.search?.question}
                    setQuestionId={setQuestionId}
                />
                <PersonaAdditionalInfo search={data?.search} />
                <PersonaList
                    answers={filterByAge(questionData?.question?.answers) || filterByAge(data?.search?.question?.answers)}
                />
            </div>
        </div>
    )
}
