import React, {useMemo, useRef, useState} from 'react'
import { age, region } from '../../../data/data';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PersonaData from './PersonaData';
import useCloseDropdown from '../../lib/useCloseDropdown';


export default function PersonaDashboardContent({personas}) {
    const [selectOnChangeAge, setSelectSelectOnChangeAge] = useState([])
    const [selectOnChangeRegion, setSelectSelectOnChangeRegion] = useState([])
    const [openAge, setOpenAge] = useState(false);
    const [openRegion, setOpenRegion] = useState(false);
    const [dataRegion, setDataRegion] = useState(region);
    const [searchRegion, setSearchRegion] = useState('');
    const [kids, setKids] = useState(false)
    const [dateIsOpen, setDateIsOpen] = useState(false);
    const [dateData, setDateData] = useState(null);
    const currentDate = new Date()
    const prevDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
    const ageRef = useRef(null);
    const regionRef = useRef(null);
    const monthRef = useRef(null);

    useCloseDropdown(ageRef, () => setOpenAge(false), openAge)
    useCloseDropdown(regionRef, () => setOpenRegion(false), openRegion)
    useCloseDropdown(monthRef, () => setDateIsOpen(false), dateIsOpen)

    // Age functions
    const toggle = () => {
        setOpenAge(!openAge);
    };

    function handleAgeChange(e) {
        // create array from checked items else if item unchecked remove it
        if (e.target.checked) {
            setSelectSelectOnChangeAge([...selectOnChangeAge, e.target.value])
        } else {
            setSelectSelectOnChangeAge(selectOnChangeAge.filter(item => item !== e.target.value))
        }
    }

    // Region functions
    const toggleRegion = () => {
        setOpenRegion(!openRegion);
    };
    function handleRegionChange(e) {
        // create array from checked items else if item unchecked remove it
        if (e.target.checked) {
            setSelectSelectOnChangeRegion([...selectOnChangeRegion, e.target.value.trim().toLowerCase()])
        } else {
            setSelectSelectOnChangeRegion(selectOnChangeRegion.filter(item => item !== e.target.value.trim().toLowerCase()))
        }
    }
    function changeSearchRegion(e) {
        setSearchRegion(e.target.value)
        e.target.value ?
            setDataRegion(
                region.filter((val) => val?.name.toLowerCase().includes(e.target.value.toLowerCase()))
            ) : setDataRegion(
                region
            )
    }

    const filteredPersonas = useMemo(() => {
        let _personas = personas;

        if (kids) {
            _personas = _personas.filter((persona) => persona.kids && persona.kids !== "No")
        }
        if (selectOnChangeAge && selectOnChangeAge.length > 0) {
            _personas = _personas.filter((persona) => {
                const age = parseInt(persona.age);
                if (age < 25) {
                    return selectOnChangeAge.includes('18-24');
                } else if (age < 35) {
                    return selectOnChangeAge.includes('25-34');
                } else if (age < 45) {
                    return selectOnChangeAge.includes('34-44');
                } else if (age > 44) {
                    return selectOnChangeAge.includes('45-55');
                }
                return false;
            })
        }

        if (selectOnChangeRegion && selectOnChangeRegion.length > 0) {
            const regionsCodes = selectOnChangeRegion.map((el) => region.find((r) => r.name.toLowerCase() === el)?.abbreviation)
            _personas = _personas.filter((persona) => {
                return selectOnChangeRegion.includes(persona.region.toLowerCase()) ||
                    regionsCodes.includes(persona.region.toUpperCase());
            })
        }

        return _personas;
    }, [personas, kids, selectOnChangeAge, selectOnChangeRegion]);


    // kids checkbox

    function changeKids() {
        setKids(!kids)
    }

    // date 

    const toggleCalendar = () => {
        setDateIsOpen(!dateIsOpen);
    };
    const changeDate = (date) => {
        toggleCalendar()
        setDateData(date)
    };

    return (
        <div className='ml-[6.5rem] mr-[2.2rem]'>
            <div className=' pb-3'>


                <div className=' w-full h-full pt-8 flex  -mx-3'>
                    <div className="dropdown-check-list relative font-dropdownFont font-medium mb-3 w-1/3 mx-3" ref={ageRef}>
                        <div className='text-formSubSize pb-3 tracking-wider font-normal' onClick={() => openAge && setOpenAge(false)}>
                            Age
                        </div>
                        <div onClick={() => toggle()} className="border border-borderColor rounded text-xs py-[6px] relative cursor-pointer  tracking-wider">
                            {
                                // show selected data if exist
                                selectOnChangeAge && selectOnChangeAge.length !== 0 ? <div className='line-one pl-2 pr-6 opacity-75 font-dropdownFont'>
                                    {
                                        selectOnChangeAge.map((item, i) => (
                                            item + `${i !== selectOnChangeAge.length - 1 ? ', ' : ''}`
                                        ))
                                    }
                                </div> :
                                    <div className='text-white opacity-75 px-4 pl-2 font-dropdownFont'>
                                        All
                                    </div>
                            }
                            <span className="absolute right-4 top-[0.775rem]">
                                <img src={`/images/search/arrow-down-grey.svg`} alt="arrow" />
                            </span>
                        </div>
                        {/* age items  */}
                        <ul className={`absolute w-full items z-20 font-dropdownFont  ${openAge ? 'block' : 'hidden'}`} >
                            {
                                age &&
                                age.map((item, i) => (
                                    <li key={i}>
                                        <div className="flex relative">
                                            <label className="containerCheckboxDropdown border-b border-b-whiteBorderColor">
                                                <input type="checkbox" className="checkbox" value={item.age}
                                                    onChange={(e) => handleAgeChange(e)}
                                                />
                                                <div className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">{item.age}</div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="dropdown-check-list relative font-dropdownFont font-medium mb-3 w-1/3 mx-3" ref={regionRef}>
                        <div className='text-formSubSize pb-3 tracking-wider font-normal' onClick={() => setOpenRegion(false)}>
                            Region
                        </div>
                        <div onClick={() => toggleRegion()} className="border border-borderColor rounded text-xs py-[6px] relative cursor-pointer tracking-wider">
                            {
                                // show selected data if exist
                                selectOnChangeRegion && selectOnChangeRegion.length !== 0 ?
                                    <div className='line-one pl-2 pr-6 opacity-75 capitalize font-dropdownFont'>
                                        {
                                            selectOnChangeRegion.map((item, i) => (
                                                item + `${i !== selectOnChangeRegion.length - 1 ? ', ' : ''}`
                                            ))
                                        }
                                    </div> :
                                    <div className='text-transparentWhite px-4 pl-2 font-dropdownFont'>
                                        Choose Regions
                                    </div>
                            }
                            <span className="absolute right-4 top-[0.775rem]">
                                <img src={`/images/search/arrow-down-grey.svg`} alt="arrow" />
                            </span>
                        </div>
                        <div className={`absolute w-full items z-10 font-dropdownFont  ${openRegion ? 'block' : 'hidden'} `}>
                            <div className='bg-dropdownBgColor relative'>
                                <input type="text" placeholder='Search Region...' value={searchRegion} onChange={(e) => changeSearchRegion(e)} className="bg-transparent tracking-wider text-xs w-full outline-none px-8 py-4" />
                                <img src="/images/icons/search.svg" alt="search" className='absolute top-[18px] left-3' />
                            </div>
                            <ul className={`absolute w-full items z-10 font-dropdownFont  ${openRegion ? 'block' : 'hidden'} max-h-[9.375rem] overflow-auto customScroll`}  >
                                {
                                    dataRegion && dataRegion.length > 0 &&
                                    dataRegion.map((item, i) => (
                                        <li key={i}>
                                            <div className="flex relative">
                                                <label className="containerCheckboxDropdown border-b border-b-whiteBorderColor">
                                                    {
                                                        selectOnChangeRegion && selectOnChangeRegion.length !== 0 && selectOnChangeRegion.includes(item.name.trim().toLowerCase()) ?

                                                            <input type="checkbox" className="checkbox" name="region" value={item.name}
                                                                onChange={(e) => handleRegionChange(e)}
                                                                checked={true}
                                                            />
                                                            :
                                                            <input type="checkbox" className="checkbox" name="region" value={item.name}
                                                                onChange={(e) => handleRegionChange(e)}
                                                                checked={false}
                                                            />
                                                    }
                                                    <div className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">{item.name}</div>
                                                    <span className="checkMarkDropdown"></span>
                                                </label>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>


                    </div>
                    <div className='relative mb-3 w-1/3 px-3' ref={monthRef}>
                        <div className='text-formSubSize pb-3 tracking-wider font-dropdownFont font-normal' onClick={() => setDateIsOpen(false)}>
                            Month
                        </div>
                        <div className="border border-borderColor rounded text-xs py-[6px] relative cursor-pointer tracking-wider" onClick={() => toggleCalendar()}>
                            {
                                dateData ?
                                    <div className='line-one pl-2 pr-6 opacity-75 capitalize font-dropdownFont'>
                                        {moment(dateData).format('MMM YYYY')}
                                    </div>
                                    : <div className='text-transparentWhite px-4 pl-2 font-dropdownFont font-medium'>
                                        Choose Month
                                    </div>
                            }
                            <span className="absolute right-4 top-[0.775rem]">
                                <img src={`/images/search/arrow-down-grey.svg`} alt="arrow" />
                            </span>
                        </div>
                        {dateIsOpen && (
                            <div className='absolute z-20 homeCalendar'>
                                <DatePicker
                                    dateFormat="MM/yyyy"
                                    selected={dateData}
                                    minDate={prevDate}
                                    maxDate={currentDate}
                                    onChange={(date) => changeDate(date)}
                                    showMonthYearPicker
                                    inline
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex  relative ">
                    <label className="containerCheckbox">
                        <input type="checkbox" className="checkbox" onChange={() => changeKids()} value={kids} />
                        <span className="text-sm text-white tracking-wider">With Kids</span>
                        <span className="checkMark small"></span>
                    </label>
                </div>
            </div>
            <PersonaData personas={filteredPersonas} />
        </div>
    )
}
