import React, {useMemo, useState} from 'react'
import {useSelector} from 'react-redux';
import {categoriesMainData} from '../../../data/data'
import Slider from "react-slick";
import PersonaAnswerItem from './PersonaAnswerItem';
import {useParams} from "react-router-dom";
import isValidHttpUrl from "../../../helpers/isValidHttpUrl";
import {youtubeParser, youtubeThumbnail} from "../../../helpers/youtubeParser";

export default function PersonaQuestion({data}) {
    const {id} = useParams()

    const searchFormData = useSelector((state) => state.searchFormData.value);
    const [currentSlide, setCurrentSlide] = useState()
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        afterChange: current => setCurrentSlide(current)
    };

    const thumbnails = useMemo(() => {
        if (!searchFormData.question) {
            return false;
        }
        const urls = searchFormData.question?.parsedContent?.map((el) => el && isValidHttpUrl(el) && youtubeParser(el) && ({
            img: youtubeThumbnail(el),
            video: el,
        }))
        if (!urls) return false;
        return urls.filter((el) => !!el);
    }, [searchFormData.question]);

    const answersMemo = useMemo(() => {
        const answersMap = searchFormData.question?.answers
            ?.filter((answer) => answer.personaId == id && answer.parsedAnswer && answer.parsedAnswer[0].length)
            ?.map((answer) => answer.parsedAnswer)
        return answersMap && answersMap.length ? answersMap : "--"
    }, [searchFormData])

    return (

        <div>
            {
                searchFormData.question &&
                <div className='flex border-2 rounded border-mainColor mb-11 justify-between p-[1.875rem]'>

                    <div className='font-firstFont text-mainColor pr-9 w-[70%]'>
                        <div className='mb-4'>
                            <div className='text-xl font-medium leading-[1.125rem] mb-2'>Question</div>
                            <div className='opacity-70 text-sm'>
                                {
                                    searchFormData.question?.question || ""
                                }
                            </div>
                        </div>
                        <div>
                            <div className='text-xl font-medium leading-[1.125rem] mb-2'>Answer</div>
                            <div className='opacity-70 text-sm'>
                                {
                                    answersMemo
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-[250px] personaAnswerSlider'>
                        <Slider {...settings}>
                            {thumbnails?.map((item, i) => (
                                <div key={i}>
                                    <PersonaAnswerItem data={item} itemIndex={`questionItem${i}`}/>
                                </div>
                            ))}
                            {
                                !thumbnails && data?.otherContent &&
                                data?.otherContent.map((item, i) => (
                                    <div key={item.id}>
                                        <PersonaAnswerItem data={item} itemIndex={`questionItem${i}`}/>
                                    </div>
                                ))
                            }
                        </Slider>
                        <div className='flex justify-center text-sm text-mainColor opacity-70'>
                            {currentSlide ? currentSlide + 1 : 1}/{thumbnails.length || data?.otherContent.length}
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
