import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { category: [] }

export const userSlice = createSlice({
    name: "firstLoginCategoryForm",
    initialState: { value: initialStateValue },
    reducers: {
        formCategory: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { formCategory } = userSlice.actions;

export default userSlice.reducer;