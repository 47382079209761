import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import MenuSearch from '../gobal/MenuSearch'
import { useDispatch } from "react-redux";
import { userIsLogoutFunctional } from '../../features/userIsLogIn'
import useCloseDropdown from '../lib/useCloseDropdown';
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../ui/FullScreenLoader";



export default function TopMenu() {
    const [showLogout, seShowLogout] = useState(false);
    const dispatch = useDispatch()
    const dropdownRef = useRef(null);

    const {data: profileDate, loading} = useQuery(gql`
        query getProfile {
            getProfile {
                userId
                firstName
                lastName
                email
            }
        }
    `)

    const handleLogout = () => {
        console.log('logout perform')
        localStorage.removeItem('token');

        dispatch(userIsLogoutFunctional())
    }

    useCloseDropdown(dropdownRef, () => seShowLogout(false), showLogout)

    if (loading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <div className='flex w-full   justify-between pl-10 pr-5 items-center py-[1.375rem]'>
            <NavLink to={'/'}>
                <img src={`/images/logo.svg`} alt='logo' />
            </NavLink>
            <div className='w-[85%] pl-8 pr-4'>
                <MenuSearch />
            </div>
            <div>
                <div className='relative' ref={dropdownRef}>
                    <div className='flex items-center cursor-pointer' onClick={() => seShowLogout(!showLogout)}>
                        <span className='text-ellipsis w-fit min-w-max pr-3 font-firstFont text-sm'>
                            {/*Michelle P.*/}
                            {`${profileDate?.getProfile?.firstName} ${profileDate?.getProfile?.lastName}`}
                        </span>
                        <img src={`/images/icons/user.svg`} alt='logo' className='w-[1.875rem] h-[1.875rem]' />
                    </div>
                    <div
                        className={`absolute top-12 right-0 ${showLogout ? 'block' : 'hidden'} cursor-pointer`}
                        onClick={handleLogout}
                    >
                        <div className='flex border border-mainColor rounded py-[0.688rem] px-[1.063rem] items-center relative min-w-[106px]'>
                            <img src={`/images/icons/logoutArrow.svg`} alt='arrow' className='h-[10px]  w-[15px] absolute -top-[8.5px] bg-bgMain right-2' />
                            <img src={`/images/icons/logout.svg`} alt='logo' className='w-3  h-3' />
                            <span className='uppercase font-dropdownFont font-semibold text-xs ml-[0.625rem] text-mainColor w-fit min-w-max'>
                                log out
                            </span>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
