import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../ui/FullScreenLoader";

// if user is log in go to home page and not go to registration or login page
export function UserStatusGoPage(page) {
    console.log('UserStatusGoPage')
    const login = useSelector((state) => state.userIsLogIn.value);
    // const answerQuestion = useSelector((state) => state.userIsLogIn.value);
    let navigate = useNavigate();
    // const choose = useSelector((state) => state.chooseCategory.value);

    const {data: categoriesData, loading: categoriesLoading} = useQuery(gql`
        query userCategories {
            userCategories {
                userCategoryId
                userId
            }
        }
    `, {
        fetchPolicy: 'cache-first',
        onError: errors => {
            if (errors.message === 'Unauthorized') {
                navigate('/login')
            }
        }
    })


    useEffect(() => {
        if (categoriesLoading) {
            return;
        }

        const choose = !!categoriesData?.userCategories.length

        // if user is loged in and choose category go to home page else go to question page 
        if (login.isLogIn && !choose) {
            return navigate("/choose-category");
        } else if (login.isLogIn && choose && page) {
            return navigate(page);
        } else if (!login.isLogIn) {
            return navigate("/login");
        }
    }, [login, categoriesLoading])

} 