import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { UserStatusGoPage } from '../../lib/userStatus'
import PersonaInfo from './PersonaInfo'
import SinglePersonaSidebar from './SinglePersonaSidebar'

export default function PersonaContent({ persona, data, id }) {
    UserStatusGoPage(`/persona/${persona?.personaId}`)
    const personaSearchResult = useSelector((state) => state.personaSearchResult.value);
    return (
        <div>
            {
                personaSearchResult.nextItemId || personaSearchResult.prevItemId ?
                    <div className='pt-11 flex xl:pr-[4.75rem] lg:flex-row flex-col '>
                        <div className='w-full lg:w-[29%]'>

                        </div>
                        <div className='w-full lg:w-[71%] md:pl-11'>
                            <div className='opacity-60 flex justify-between font-firstFont'>
                                <div>
                                    {
                                        personaSearchResult.prevItemId &&
                                        <NavLink to={`/persona/${personaSearchResult.prevItemId}`}>
                                            <div className='cursor-pointer flex items-center opacity-90'>
                                                <img src="/images/search/prev-question.svg" alt="prev question" className='w-[1rem] h-[1rem] object-contain' />
                                                <span className='text-xs ml-3'>Previous Persona</span>
                                            </div>
                                        </NavLink>
                                    }
                                </div>
                                <div>
                                    {
                                        personaSearchResult.nextItemId &&
                                        <NavLink to={`/persona/${personaSearchResult.nextItemId}`}>
                                            <div className='cursor-pointer flex items-center opacity-90' >
                                                <span className='text-xs mr-3'>Next Persona</span>
                                                <img src="/images/search/next-question.svg" alt="next question" className='w-[1rem] h-[1rem] object-contain' />
                                            </div>
                                        </NavLink>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }

            <div className={`${personaSearchResult.nextItemId || personaSearchResult.prevItemId ? 'pt-4' : 'pt-16'}  flex xl:pr-[4.75rem] lg:flex-row flex-col pb-20`}>
                <div className='w-full lg:w-[29%] border-r-2 border-r-mainColor'>
                    <SinglePersonaSidebar data={data[0]} persona={persona} />
                </div>
                <div className='w-full lg:w-[71%] md:pl-11'>
                    <PersonaInfo data={data[0]} persona={persona} />
                </div>
            </div>
        </div>
    )
}
