import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { choose: false }

export const userSlice = createSlice({
    name: "chooseCategory",
    initialState: { value: initialStateValue },
    reducers: {
        chooseCat: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { chooseCat } = userSlice.actions;

export default userSlice.reducer;