import React, { useEffect, useState } from 'react'
import ChangeSteps from './ChangeSteps'
import { searchStep } from '../../../features/searchStepChange';
import { useDispatch, useSelector } from 'react-redux';
import { searchForm } from '../../../features/searchFormData';
import StepTwoPersonaFields from './StepTwoPersonaFields';

export default function StepTwo() {
    const dispatch = useDispatch()
    const [type, setTypes] = useState()
    // store data
    const searchStepChange = useSelector((state) => state.searchStepChange.value);
    const searchFormData = useSelector((state) => state.searchFormData.value);

    function nextStep() {
        dispatch(searchStep({
            step1: searchStepChange.step1,
            step2: searchStepChange.step2,
            step3: true
        }))
    }
    function prevStep() {
        dispatch(searchStep({
            step1: searchStepChange.step1,
            step2: false,
            step3: false
        }))
    }

    function changeType(e) {
        setTypes(e.target.value)
    }
    useEffect(() => {
        // if user select type save it in store
        type &&
            dispatch(searchForm({
                date: searchFormData.date,
                type: type,
                saveSearch: searchFormData.saveSearch,
                category: searchFormData.category,
                question: searchFormData.question,
                age: [],
                region: [],
                kids: false,
            }))
    }, [type])

    useEffect(() => {
        searchFormData.type &&
            setTypes(searchFormData.type)
    }, [])

    return (
        <div className='pt-20'>


            <div className='flex mb-[5.438rem]'>
                <div>
                    <div className='mb-9'>
                        <div className='tracking-wider opacity-50  font-dropdownFont text-sm mb-4 '>Search by</div>
                        <div className='flex w-full items-center'>
                            <div className='uppercase text-searchTextFontSize mr-4 font-firstFont font-semibold  '>TYPE</div>
                        </div>
                    </div>
                    <ul className={` w-full items z-10 font-dropdownFont min-w-[16rem] flex justify-center`}  >
                        <li className='mr-[1.438rem]'>
                            <div className="flex relative">
                                <label className="stepRadioButton">
                                    <input type="radio" name="type" className="Radio" value={'demo'} onChange={(e) => changeType(e)}
                                        checked={searchFormData.type === 'demo' ? true : false}
                                    />
                                    <div className='image'>
                                        <img src={`/images/search/step2/demo.png`} alt="demo" />
                                    </div>
                                    <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">demo</div>
                                </label>
                            </div>
                        </li>
                        <li className='ml-[1.438rem]'>
                            <div className="flex relative">
                                <label className="stepRadioButton">
                                    <input type="radio" name="type" className="Radio" value={'persona'} onChange={(e) => changeType(e)}
                                        checked={searchFormData.type === 'persona' ? true : false}
                                    />
                                    <div className='image'>
                                        <img src={`/images/search/step2/persona.png`} alt="persona" />
                                    </div>

                                    <div className="text-white RadioText font-medium tracking-wider text-dropdownFontSize">persona</div>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                {
                    type === 'persona' &&
                    <div>
                        <StepTwoPersonaFields />
                    </div>
                }
            </div>
            {
                // if didn't check type disable next button
                !type &&
                <ChangeSteps disablePrev={false} disableNext={!type} goPrev={() => prevStep()} goNext={() => nextStep()} step={2} />
            }
            {
                // if  check type demo show next button
                type === 'demo' &&
                <ChangeSteps disablePrev={false} disableNext={false} goPrev={() => prevStep()} goNext={() => nextStep()} step={2} />
            }
            {
                // if check type persona and choose age and region                
                type === 'persona' && searchFormData.age.length > 0 && searchFormData.region.length > 0 &&
                <ChangeSteps disablePrev={false} disableNext={false} goPrev={() => prevStep()} goNext={() => nextStep()} step={2} />
            }
            {
                // if check type persona but didn't choose age and region
                type === 'persona' && (searchFormData.age.length === 0 || searchFormData.region.length === 0) &&
                <ChangeSteps disablePrev={false} disableNext={true} goPrev={() => prevStep()} goNext={() => nextStep()} step={2} />
            }
        </div >
    )
}
