import React from 'react'
import {NavLink} from 'react-router-dom'
import {personaList, searchPersonaSidebarDate} from '../../../data/data'

export default function PersonaSidebar({topAnswers}) {
    return (
        <div>
            <div className='font-firstFont text-lg font-medium pb-[0.875rem]'>
                Top {topAnswers?.length} Answears by Personas
            </div>
            {
                topAnswers?.map((topAnswer, i) => (
                    i < 7 &&
                    <div key={topAnswer?.topAnswerId}
                         className="border-2 border-mainColor mb-5 rounded min-h-[6.688rem]">
                        <NavLink to={`/persona/${topAnswer?.answer?.personaId}`}>
                            <div
                                className='flex justify-between font-firstFont font-medium text-xs py-2 px-3 items-center border-b border-b-transparentWhite25 tracking-[0.04em]'>
                                <div>{topAnswer?.persona?.name}, {topAnswer?.persona?.age}</div>
                                <div><img src="/images/search/persona/arrow-right.svg" alt="arrow right"/></div>
                            </div>
                            <div
                                className='font-firstFont tracking-[0.04em] text-transparentWhite65 text-xs pl-3 pr-6 pt-1 pb-3 leading-[1.125rem] overflow-hidden'>
                                <div className='line-three '>
                                    {topAnswer?.answer?.parsedAnswer && topAnswer?.answer?.parsedAnswer[0].length ? topAnswer?.answer?.parsedAnswer : "--"}
                                </div>
                            </div>
                        </NavLink>
                    </div>
                ))
            }
        </div>
    )
}
