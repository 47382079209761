import React from 'react'
import { personaList } from '../../../data/data'
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PersonaMostSearchedItem from './PersonaMostSearchedItem';

export default function PersonaMostSearchedSlider({personas = []}) {
    // slider arrows is set custom style
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    return (
        <div className='personaMostSearchedSliderArrow'>
            {/* <div className='flex justify-around lg:flex-row flex-col w-full'> */}
            <Slider {...settings}>
                {
                    personas.map((item, i) => (
                        <PersonaMostSearchedItem
                            key={item.personaId}
                            data={item}
                            showRightLine={personas.length === i + 1}
                            index={i}
                        />
                    ))
                }
            </Slider>
            {/* </div> */}
        </div>
    )
}
