import React from 'react'

export default function NextPrevQuestion({ next, prev }) {
    return (
        <div className='flex justify-between font-firstFont'>
            <div className='opacity-60'>
                {
                    // if we check first item hide prev button 
                    document.querySelector('input[name="question"]:checked') !== null && Number(document.querySelector('input[name="question"]:checked').getAttribute('data-count')) !== 0 ?
                        <div onClick={prev} className='cursor-pointer flex items-center opacity-90'>
                            <img src="/images/search/prev-question.svg" alt="prev question" className='w-[1rem] h-[1rem] object-contain' />
                            <span className='text-xs ml-3'>Previous Question</span>
                        </div> : ''
                }
            </div>
            <div className='opacity-60'>
                {
                    document.querySelector('input[name="question"]:checked') && Number(document.querySelectorAll('input[name="question"]').length - 1) !== Number(document.querySelector('input[name="question"]:checked').getAttribute('data-count')) ?
                        <div onClick={next} className='cursor-pointer flex items-center opacity-90' >
                            <span className='text-xs mr-3'>Next Question</span>
                            <img src="/images/search/next-question.svg" alt="next question" className='w-[1rem] h-[1rem] object-contain' />
                        </div> : ''
                }
            </div>
        </div>
    )
}
