import { Route, Routes, BrowserRouter } from 'react-router-dom';
import RoutesItems from "./routes";
import {
    ApolloProvider,
} from "@apollo/client";
import apolloClient from "./graphql";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
        <div className="App">
            <BrowserRouter >
                <Routes>
                    {
                        RoutesItems.map((routeConfig, i) => {
                            return (
                                <Route path={routeConfig.path} exact={routeConfig.exact} element={routeConfig.element} key={i} />
                            )
                        })
                    }

                </Routes>
            </BrowserRouter >
        </div>
    </ApolloProvider>
  );
}

export default App;
