import React from 'react'
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

export default function PopupVideoSection({ closePopup }) {
    const showPopup = useSelector((state) => state.showPopup.value);

    return (
        <div className='relative z-20 h-fit'>
            <div className='closePopup font-firstFont text-xs text-transparentWhite65 text-right pr-4 py-2 absolute left-0 top-0 w-full cursor-pointer z-20' onClick={closePopup}>Close</div>
            <div className='max-w-[61.50rem] pb-10 w-full'>
                <ReactPlayer
                    url={showPopup.video}
                    controls={true}
                />
            </div>
        </div>
    )
}
