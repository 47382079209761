import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { email: "", password: '', remember: false }

export const userSlice = createSlice({
    name: "login",
    initialState: { value: initialStateValue },
    reducers: {
        logInFunctional: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { logInFunctional } = userSlice.actions;

export default userSlice.reducer;