import React from 'react'
import {NavLink} from 'react-router-dom';
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useDispatch, useSelector} from 'react-redux';
import {searchForm} from '../../../features/searchFormData';
import moment from 'moment';
import {categoriesMainData} from '../../../data/data';
import {gql, useMutation} from "@apollo/client";
import FullScreenLoader from "../../../ui/FullScreenLoader";

export default function HomeSavedSearchesSlider({savedSearches}) {
    const searchFormData = useSelector((state) => state.searchFormData.value);
    const dispatch = useDispatch()

    // slider arrows is set custom style
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    const [mutateRemoveSearch, {loading: loadingRemoveSearch}] = useMutation(gql`
        mutation removeSearch($id: Int!) {
            removeSearch(id: $id)
        }
    `)

    function RemoveSavedQuestion(searchId) {
        if (searchId) {
            mutateRemoveSearch({
                variables: {
                    id: searchId,
                },
                refetchQueries: ['savedSearches'],
            })
                .then(({data}) => {
                    console.log('remove search data', data)
                })
        }
        // dispatch(searchForm({
        //     date: '',
        //     type: '',
        //     saveSearch: false,
        //     category: '',
        //     question: '',
        //     age: [],
        //     region: [],
        //     kids: false,
        // }))
    }

    if (loadingRemoveSearch) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <div className='w-full  relative font-dropdownFont savedSearchesSlider'>
            <Slider {...settings}>
                {
                    (savedSearches && savedSearches.length > 0) ?
                        savedSearches.map((savedSearch) => (
                            // if we have saved search
                            <div className='relative' key={savedSearch.searchId}>
                                <NavLink
                                    to={
                                        savedSearch?.type === "DEMOS" ?
                                            `/demo-result/${savedSearch.searchId}` :
                                            `/persona-result/${savedSearch.searchId}`
                                    }
                                >
                                    <div
                                        className="border  border-white rounded-[0.313rem] mx-[0.625rem]    text-white text-xs py-[5px] px-[10px] min-h-[5rem] flex -tracking-tighter overflow-hidden  items-center">
                                        <div>
                                            <div className='line-three max-h-12 my-auto font-semibold mb-1'>
                                                {
                                                    savedSearch?.category?.name
                                                }
                                            </div>
                                            <div
                                                className='text-[rgba(255,255,255,0.45)] line-two line-two whitespace-pre-line'>
                                                {
                                                    moment()
                                                        .set('month', savedSearch?.question?.month - 1)
                                                        .set('year', savedSearch?.question?.year)
                                                        .format('MMMM YYYY')} / {savedSearch.type}
                                                {` 
                                            `}
                                                {
                                                    savedSearch?.question?.question
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </NavLink>
                                <div className='absolute right-5 top-3 w-2 h-2 cursor-pointer'
                                     onClick={() => RemoveSavedQuestion(savedSearch?.searchId)}>
                                    <img src={`/images/home/cancel.svg`} alt="search"/>
                                </div>
                            </div>
                        ))
                        :
                        // if we don't have saved search
                        <div className='relative'>
                            <div
                                className="border  border-white rounded-[0.313rem] mx-[0.625rem]    text-white text-xs py-[5px] px-[10px] min-h-[5rem] flex -tracking-tighter   items-center">
                                <div>
                                    <div className='line-three max-h-12 my-auto font-semibold mb-1'>
                                        No saved searches
                                    </div>
                                    <div className='text-[rgba(255,255,255,0.45)] line-two'>
                                        Your favourite answears will appear here after saving them during searching
                                        process.
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </Slider>
        </div>
    )
}
