import React from 'react'
import HomeSectionTeamSlider from './HomeSectionTeamSlider'
import HomeSectionTitle from '../HomeSectionTitle'

export default function HomeSectionTeam() {
    return (
        <div className='w-full'>
            <div className='pl-[0.625rem] mb-4'>
                <HomeSectionTitle title="section/theme" />
            </div>
            <HomeSectionTeamSlider />
        </div>
    )
}
