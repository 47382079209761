import React from 'react'

export default function TitleSubTitle({ title, subtitle }) {
    return (
        <div className='font-firstFont'>
            <div className='font-medium uppercase text-sm text-transparentWhite mb-3'>{subtitle}</div>
            <h1 className='font-semibold text-3xl uppercase'>{title}</h1>
        </div>
    )
}
