import React, {useEffect, useMemo, useState} from 'react'
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Navigation } from 'swiper';

export default function PersonaCategoryQuestion({ data, answers = [] }) {
    const [category, setCategory] = useState('')
    const [questions, setQuestions] = useState([])
    const searchFormData = useSelector((state) => state.searchFormData.value);

    const categories = useMemo(() => {
        return answers.map((answer) => {
            return answer.question?.categoryName;
        })
            .reduce(function(a,b){
                if (a.indexOf(b) < 0 ) a.push(b);
                return a;
            },[]);

    }, [answers]);

    useEffect(() => {
        if (categories) {
            setCategory(categories[0])
        }
    }, [categories])

    const changeCurrentCategory = (categoryUniqueId) => {
        setCategory(categoryUniqueId)
    }

    const filteredAnswers = useMemo(() => {
        return answers?.filter((answer) => answer.question.categoryName === category) || []
    }, [answers, category])

    // useEffect(() => {
    //     // if we select category and and question show that cat     questions and make category and question active
    //     searchFormData.category !== '' && searchFormData.question !== '' &&
    //         changeCurrentCategory(searchFormData.category)
    //
    //     // if user open single persona from dashboard show just first category active don't make question active
    //     data && searchFormData.category === '' && searchFormData.question === '' &&
    //         data.map((item, i) => (
    //             i === 0 &&
    //             changeCurrentCategory(item.uniqueId)
    //         ))
    // }, [data])

    SwiperCore.use([Navigation]);
    return (
        <div className='mt-11'>
            <div className="font-firstFont font-medium text-xl mb-4">Other Answers</div>
            {/* categories  */}
            <div className='mb-6 border-b border-b-transparentWhite font-firstFont font-medium text-sm tracking-wider relative '>
                <Swiper
                    className="flex personaSwiper  scrollXHide gap-4 md:gap-12 pb-10 pl-5 md:pl-0 overflow-x-scroll"
                    watchSlidesProgress={true}
                    slidesPerView="auto"
                    navigation
                >
                    {/*{*/}
                    {/*    data &&*/}
                    {/*    data.map((item, index) => (*/}
                    {/*        <SwiperSlide*/}
                    {/*            className={`cursor-grabbing !w-fit pl-[0.938rem] pr-[0.938rem]     text-center border-b-4 border-b-transparent pb-2 ${category === item.uniqueId ? 'border-b-white' : ''}`}*/}
                    {/*            key={item.id}>*/}
                    {/*            <div onClick={() => changeCurrentCategory(item.uniqueId)} className={`${category === item.uniqueId ? '' : 'text-transparentWhite4'} text-center cursor-pointer`}>{item.name}</div>*/}
                    {/*        </SwiperSlide>*/}
                    {/*    ))*/}
                    {/*}*/}
                    {
                        categories &&
                        categories.map((item, index) => (
                            <SwiperSlide
                                className={`cursor-grabbing !w-fit pl-[0.938rem] pr-[0.938rem]     text-center border-b-4 border-b-transparent pb-2 ${category === item ? 'border-b-white' : ''}`}
                                key={item}>
                                <div onClick={() => changeCurrentCategory(item)} className={`${category === item ? '' : 'text-transparentWhite4'} text-center cursor-pointer`}>{item}</div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                {/* <div className='absolute right-0 top-1 personaArrow w-fit'>
                    <img src="/images/persona/arrow-right.svg" alt={'arrow right'} />
                </div> */}
            </div>

            {/* questions  */}
            {
                // question 
                filteredAnswers.map((answer, j) => (
                    <div key={answer.answerId} className={`  font-dropdownFont text-personaTextFontSize mb-[1.563rem]`}>
                        <div className={`${
                            searchFormData.question && searchFormData.question !== answer.question?.question ? 'border-white text-white' : 'border-mainColor text-mainColor'
                        } ${!searchFormData.question ? 'border-mainColor text-mainColor' : ''} font-dropdownFont text-personaTextFontSize px-5 py-3 border font-medium rounded-[0.313rem]`}>
                            {j + 1}. {answer?.question?.question}
                        </div>
                        <div className='text-transparentWhite8 pt-3 px-5 pb-3 relative'>
                            {answer.parsedAnswer && answer.parsedAnswer[0].length ? answer.parsedAnswer : "--"}
                            <div className='border border-personaQuestionBorder border-t-0 box-border w-full h-full absolute -top-[2px] left-0 rounded-b-[0.313rem]'></div>
                        </div>
                    </div>
                ))
            }
        </div >
    )
}
