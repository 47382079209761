import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {changePopupData} from '../../../features/showPopup';
import {searchForm} from "../../../features/searchFormData";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import isValidHttpUrl from "../../../helpers/isValidHttpUrl";
import {youtubeParser} from "../../../helpers/youtubeParser";
import {youtubeThumbnail} from "../../../helpers/youtubeParser";

export default function HomeSidebarItem({data, itemIndex, question}) {
    const dispatch = useDispatch()
    const showPopup = useSelector((state) => state.showPopup.value);
    // open popup with image or video
    const openPopup = (image, video) => {
        dispatch(changePopupData({
            video: video,
            image: image,
            open: true,
            audio: '',
            playAudio: false
        }))
        document.body.classList.add("overflow-hidden");
    }
    //  play audio
    const playAudio = (audio, i) => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: audio,
            playAudio: true,
            activeAudio: i
        }))
    }
    //  stop playing audio
    const stopAudio = () => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: true,
            activeAudio: ''
        }))
    }

    const topAnswer = useMemo(() => {
        const topAnswers = question?.topAnswers?.filter((topAnswer) => topAnswer?.answer?.parsedAnswer && topAnswer?.persona?.image)
        return topAnswers[Math.floor(Math.random() * topAnswers.length)]
    }, [question])

    let navigate = useNavigate();

    const thumbnail = useMemo(() => {
        if (!question || !question.parsedContent && !question.parsedContent.length > 0) {
            return false;
        }
        const urls = question?.parsedContent?.map((el) => el && isValidHttpUrl(el) && youtubeParser(el) && youtubeThumbnail(el))
        if (!urls) return false;
        return urls[Math.floor(Math.random() * urls.length)]
    }, [question]);

    const handleClick = () => {
        if (!question) return;

        dispatch(searchForm({
            date: moment()
                .set('month', question?.month - 1)
                .set('year', question?.year).toDate().toString(),
            category: question.categoryId,
            question: question.questionId,
        }))

        navigate('/persona-result')
    }

    return (
        <div className='flex items-center py-6 border-b border-transparentWhite'>
            {/*// new functional*/}
            {
                topAnswer && (
                    <div
                        className='relative min-h-[5.5rem!important] min-w-[5.69rem!important] max-h-[5.5rem!important] max-w-[5.69rem!important] cursor-pointer'
                        onClick={handleClick}
                    >
                        <img
                            // onClick={handleClick}
                            src={thumbnail || topAnswer?.persona?.image} alt={topAnswer?.persona?.name}
                            className="min-h-[5.5rem!important] min-w-[5.69rem!important] max-h-[5.5rem!important] max-w-[5.69rem!important] object-cover rounded-[0.313rem]"
                        />

                        {thumbnail && (
                            <div
                                className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '
                            >
                                <img src={`/images/icons/play.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]'/>
                            </div>
                        )}

                        {!thumbnail && !topAnswer?.persona?.image && (
                            <div
                                className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '>
                                <img src={`/images/icons/post.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]'/>
                            </div>
                        )}

                    </div>
                )
            }

            {/*// old functional*/}
            {
                // if data is post open other website url
                data && (
                    data?.post ?
                        <a href={data?.post} target='_blank' rel="noreferrer">
                            <div
                                className='relative min-h-[5.5rem!important] min-w-[5.69rem!important] max-h-[5.5rem!important] max-w-[5.69rem!important] cursor-pointer'>
                                <img src={data?.img} alt={data?.section}
                                     className="min-h-[5.5rem!important] min-w-[5.69rem!important] max-h-[5.5rem!important] max-w-[5.69rem!important] object-cover rounded-[0.313rem]"/>
                                <div
                                    className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '>
                                    <img src={`/images/icons/post.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]'/>
                                </div>
                            </div>
                        </a> :
                        // if data is other then post open popup
                        // TODO add popup functional
                        <div
                            className='relative min-h-[5.5rem!important] min-w-[5.69rem!important] max-h-[5.5rem!important] max-w-[5.69rem!important] cursor-pointer'>
                            <img src={data?.img} alt={data?.section}
                                 className="min-h-[5.5rem!important] min-w-[5.69rem!important] max-h-[5.5rem!important] max-w-[5.69rem!important] object-cover rounded-[0.313rem]"/>
                            {
                                data?.video &&
                                <div
                                    className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '
                                    onClick={() => openPopup('', data?.video)}>
                                    <img src={`/images/icons/play.svg`} alt="play" className='w-[2.13rem] h-[2.13rem]'/>
                                </div>
                            }
                            {
                                data?.audio &&
                                <div>
                                    {
                                        // if we click on certain item show animation
                                        itemIndex !== showPopup.activeAudio ?
                                            <div
                                                className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '
                                                onClick={() => playAudio(data?.audio, itemIndex)}>
                                                <img src={`/images/icons/play.svg`} alt="play"
                                                     className='w-[2.13rem] h-[2.13rem]'/>
                                            </div> :
                                            <div
                                                className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '
                                                onClick={() => stopAudio()}>
                                                <div className="box ">
                                                    <div className="box__line  "></div>
                                                </div>
                                            </div>

                                    }

                                </div>

                            }
                            {
                                data?.popupImage &&
                                <div
                                    className='absolute w-full h-full top-0 left-0 flex justify-center items-center bg-sidebarItemBg '
                                    onClick={() => openPopup(data?.popupImage, '')}>
                                    <img src={`/images/icons/image-icon.svg`} alt="play"
                                         className='w-[2.13rem] h-[2.13rem]'/>
                                </div>
                            }
                        </div>
                )
            }

            <div className='font-dropdownFont font-medium tracking-wider text-xs leading-5 pl-4'>
                <div>Section</div>
                <div className='text-transparentWhite45'>{question?.categoryName}</div>
                <div>Category</div>
                <div className='text-transparentWhite45'>
                    {topAnswer?.persona?.age} Y/O
                    {topAnswer?.persona?.status && `, ${topAnswer?.persona?.status}`}
                    {`, ${topAnswer?.persona?.kids === 'No' ? "W/O" : "W"} KIDS`}
                </div>
            </div>
        </div>
    )
}
