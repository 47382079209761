import React, {useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom';
import {gql, useMutation} from "@apollo/client";

export default function ForgotPasswordForm() {
    let navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [sending, setSending] = useState(false)
    const [emailErrorCompare, setEmailErrorCompare] = useState('')

    const [mutateRecoverAccount] = useMutation(gql`
        mutation recoverAccount($email: String!) {
            recoverAccount(email: $email) {
                userId
                email
            }
        }
    `);

    // submit form
    const handleSubmit = event => {
        event.preventDefault();

        setSending(true)
        setEmailErrorCompare('')

        mutateRecoverAccount({
            variables: {
                email,
            },
            onError: errors => {
                console.log('onError', errors.message)
                setSending(false)
                if (errors.message.includes('user')) {
                    setEmailErrorCompare(errors.message)
                } else {
                    setEmailErrorCompare('Email not found!')
                }
            }
        }).then(({data}) => {
            setSending(false)
            if (data?.recoverAccount) {
                // if success
                navigate('/forgot-password-success')
            }
        })
    };

    return (

        <div className='text-black'>
            <form onSubmit={handleSubmit}>
                <div className="w-full md:mr-[0.625rem] pb-10 relative">
                    <label className="input-label" htmlFor="email">Email *</label>
                    <input
                        className={`input-field ${emailErrorCompare ? "input-field-error" : ''}`}
                        type="email"
                        required={true}
                        placeholder="example@company.io"
                        id="email"
                        onChange={event => setEmail(event.target.value)}
                    />
                    {
                        // if form email is wrong or dodnlt match password
                        emailErrorCompare &&
                        <span className="itemErrorMessage">
                            {emailErrorCompare}
                        </span>
                    }
                </div>
                {
                    // if we are sending data
                    sending ?
                        <div className="main-button w-fit">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div> :
                        // after or before send
                        <input type="submit" value={"Send"} className="main-button"/>
                }
                <div className="text-formSubSize text-white pt-[3.125rem]">
                    Remember password? Sign in
                    &nbsp;
                    <NavLink
                        to="/login"
                        className="text-mainColor"
                    >
                        Here.
                    </NavLink>
                </div>
            </form>
        </div>
    )
}
