import React, {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form';
import {categoriesMainData} from '../../data/data';
import {formCategory} from '../../features/firstLoginCategoryForm'
import {chooseCat} from '../../features/chooseCategory';
import {useDispatch} from 'react-redux'
import {useNavigate} from "react-router-dom";
import useCloseDropdown from '../lib/useCloseDropdown';
import {gql, useMutation, useQuery} from "@apollo/client";
import FullScreenLoader from "../../ui/FullScreenLoader";

export default function FirstLoginCategoryForm() {
    const dropdownRef = useRef(null);

    const {register, handleSubmit, formState: {errors}, getValues, reset} = useForm();
    const [sending, setSending] = useState(false)
    const [submitData, setSubmitData] = useState("");
    // const [selectData, setSelectData] = useState('')
    const [selectOnChangeData, setSelectSelectOnChangeData] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    // open select field
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const dispatch = useDispatch()
    let navigate = useNavigate();

    // dropdown outside click function
    useCloseDropdown(dropdownRef, () => setIsOpen(false), isOpen)

    const onSubmit = async data => {
        setSending(true)
        setSubmitData(JSON.stringify(data));
    };

    const [mutateUserCategories] = useMutation(gql`
        mutation createUserCategory($input: CreateUserCategoryInput!) {
            createUserCategory(createUserCategoryInput: $input) {
                userCategoryId
                categoryId
                userId
            }
        }
    `);

    useEffect(() => {
        // if data save data
        if (submitData && submitData.length > 0) {
            console.log('getValues("categories")', getValues("categories"))
            console.log('selectOnChangeData', selectOnChangeData)

            mutateUserCategories({
                variables: {
                    input: {
                        categoryIds: selectOnChangeData
                    }
                },
                awaitRefetchQueries: true,
                refetchQueries: ['userCategories']
            }).then(({data}) => {
                setSending(false)
                // add data to store
                dispatch(formCategory({category: getValues("categories")}))
                // add data to store end

                // reset value if success
                reset({
                    categories: [],
                })
                setSelectSelectOnChangeData([])
                // when user choose category for first time go to home page
                dispatch(chooseCat({choose: true}))
                return navigate("/");
            }).catch(() => {
                setSending(false)
            })
        }
    }, [submitData])

    function handleCheckboxChange(e) {
        // create array from checked items else if item unchecked remove it
        const value = Number(e.target.value)
        if (e.target.checked) {
            setSelectSelectOnChangeData([...selectOnChangeData, value])
        } else {
            setSelectSelectOnChangeData(selectOnChangeData.filter(item => item !== value))
        }
    }

    const {data, loading} = useQuery(gql`
        query {
            categories {
                categoryId
                name
            }
        }
    `)

    if (loading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <div>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="dropdown-check-list relative" tabIndex="100">
                    <div className='text-formSubSize pb-2'>
                        Fields you’re most interested in
                    </div>
                    <div ref={dropdownRef}>
                        <div onClick={() => toggle()}
                             className="border border-borderColor rounded text-formSmallText py-[5px] relative cursor-pointer">
                            {
                                // show selected data if exist
                                selectOnChangeData && selectOnChangeData.length !== 0 ?
                                    <div className='line-one pl-4 pr-6'>
                                        {
                                            selectOnChangeData.map((item, i) => (
                                                `${data?.categories.find((el) => el.categoryId == item)?.name}` + `${i !== selectOnChangeData.length - 1 ? ', ' : ''}`
                                            ))
                                        }
                                    </div> :
                                    <div className='text-white opacity-60 px-4'>
                                        Select Fields
                                    </div>
                            }
                            <span className="absolute right-4 top-[0.875rem]">
                                <img src={`/images/icons/arrow-down.svg`} alt="arrow"/>
                            </span>
                        </div>
                        <ul className={`absolute w-full items z-10 font-dropdownFont  ${isOpen ? 'block' : 'hidden'}`}>
                            {
                                data?.categories &&
                                data?.categories.map((item, i) => (
                                    <li key={item.categoryId}>
                                        <div className="flex relative">
                                            <label
                                                className="containerCheckboxDropdown border-b border-b-whiteBorderColor">
                                                <input type="checkbox" className="checkbox" value={item.categoryId}
                                                       {...register('categories', {
                                                           required: true,
                                                           onChange: (e) => handleCheckboxChange(e),
                                                       })}
                                                />
                                                <div
                                                    className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">
                                                    {item.name}
                                                </div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    {errors.categories &&
                    <span className="text-formSubSize absolute -bottom-[1.2rem] left-0 text-[#CA0000] pt-1 ">This field is required</span>}
                </div>
                <div className='pt-[5.25rem]'>

                    {
                        selectOnChangeData.length !== 0 ?

                            // if we are sending data show loader
                            sending ?
                                <div className="main-button w-fit">
                                    < svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div> :
                                // after or before send
                                <input type="submit" value={"Go to Dashboard"} className="main-button"/>
                            :
                            <div className="main-button opacity-40 w-fit">
                                Go to Dashboard
                            </div>
                    }
                </div>
            </form>
        </div>
    )
}
