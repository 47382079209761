import React from 'react'
import {NavLink} from 'react-router-dom'
import {region} from "../../../data/data";

export default function HomePersonaSectionItem({data, showRightLine}) {
    // showRightLine if element is last show last right line
    return (
        <div
            className={`lg:w-1/4 mx-[12px] relative pb-2 font-firstFont before:content-[""] before:absolute before:h-[80%] before:w-[0.125rem] before:bg-black before:-left-[12px] before:top-1  after:content-[""] after:absolute after:h-[80%] after:w-[0.125rem] ${showRightLine ? 'after:bg-black' : ''} after:-right-[12px] after:top-1`}>
            <NavLink to={`/persona/${data?.personaId}`}>
                {/* bottom line  */}
                <div className='w-[100%] h-7 absolute  flex left-0   bottom-0 justify-center z-10'>
                    <div className='border border-personaBorderColor rounded-[0.313rem] w-[95%] h-7'></div>
                </div>

                {/* bottom line end */}
                <div
                    className='border border-personaBorderColor rounded-[0.313rem]  relative z-20 bg-mainColor h-full text-darkTextColor flex items-center p-4'>

                    <div className='mr-5'>
                        <img src={data?.image} alt={data.name}
                             className="min-w-[4.375rem] min-h-[4.375rem]  max-w-[4.375rem] max-h-[4.375rem] rounded-[100%] object-cover"/>
                    </div>
                    <div>
                        <h3 className='font-bold text-personaFontSize tracking-[0.04rem]'>{data?.name}, {data?.age}</h3>
                        <div className='text-personaTextFontSize tracking-wider line-two'>
                            {data?.status}, Kids - {data?.kids}, from {
                            data?.region &&
                            region.find((el) => el.abbreviation === data.region)?.name
                            || data?.region
                        }
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}
