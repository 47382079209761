import React from 'react'
import SearchForm from '../gobal/Search/SearchForm'
import GlobalLayout from '../layout/GlobalLayout'
import TopMenu from '../layout/TopMenu'
import { UserStatusGoPage } from '../lib/userStatus'

export default function Search() {
    UserStatusGoPage("/search")
    return (
        <GlobalLayout showTopMenu={false} >
            <TopMenu />
            <SearchForm />
        </GlobalLayout>
    )
}
