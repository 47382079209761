import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {searchForm} from '../../../features/searchFormData';
import {categoriesMainData} from '../../../data/data';
import NextPrevQuestion from './NextPrevQuestion';
import useCloseDropdown from '../../lib/useCloseDropdown';
import {gql, useQuery} from "@apollo/client";

export default function Filters(props) {
    const {
        question, setQuestionId = () => {
        }
    } = props;

    const searchFormData = useSelector((state) => state.searchFormData.value);
    const [dateData, setDateData] = useState(new Date());
    const currentDate = new Date()
    const prevDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const [category, setCategory] = useState('');
    const dispatch = useDispatch()
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [allQuestions, setAllQuestions] = useState();
    const [openQuestion, setOpenQuestion] = useState(false);
    const [showPrevNext, setShowPrevNext] = useState(false);
    const dropdownMonthRef = useRef(null);
    const dropdownCategoryRef = useRef(null);
    const dropdownQuestionRef = useRef(null);

    useEffect(() => {
        if (question) {
            setSelectedQuestion(question.questionId)
            setCategory(question.categoryId)
            const auxDate = dateData;
            auxDate.setFullYear(question.year)
            auxDate.setMonth(question.month - 1)
        }
    }, [question])

    useEffect(() => {
        if (selectedQuestion && selectedQuestion !== question?.questionId) {
            setQuestionId(selectedQuestion)
        }
    }, [selectedQuestion])

    // dropdown outside click function
    useCloseDropdown(dropdownMonthRef, () => setCalendarIsOpen(false), calendarIsOpen)
    useCloseDropdown(dropdownCategoryRef, () => setOpenCategory(false), openCategory)
    useCloseDropdown(dropdownQuestionRef, () => setOpenQuestion(false), openQuestion)

    useEffect(() => {
        searchFormData.date &&
        setDateData(new Date(searchFormData.date))
    }, [])
    // month
    const toggleCalendar = () => {
        setCalendarIsOpen(!calendarIsOpen);
        // if category is open close it
        // openCategory &&
        //     setOpenCategory(false);
        // // if question is open close it
        // openQuestion &&
        //     setOpenQuestion(false);
    };
    const changeDate = (date) => {
        toggleCalendar()
        setDateData(date)

        // add data to store
        dispatch(searchForm({
            date: date.toString(),
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: '',
            question: '',
            age: searchFormData.age,
            region: searchFormData.region,
            kids: searchFormData.kids,
        }))
        setCategory('')
        setSelectedQuestion('')
    };
    // category 
    const toggleCategory = () => {
        setOpenCategory(!openCategory);
        // if calendar is open close it
        // calendarIsOpen &&
        //     setCalendarIsOpen(false);
        // // if question is open close it
        // openQuestion &&
        //     setOpenQuestion(false);
    };

    function changeCategory(e) {
        setCategory(Number(e.target.value))
        // add category to store
        dispatch(searchForm({
            date: searchFormData.date,
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: Number(e.target.value),
            question: searchFormData.question,
            age: searchFormData.age,
            region: searchFormData.region,
            kids: searchFormData.kids,
        }))
        // if change category empty question
        setSelectedQuestion('')
        clearChecks()
        setOpenCategory(false)
    }

    // question
    function changeQuestion(e) {
        if (e.value) {
            // change to prev or next question
            setSelectedQuestion(Number(e.value));
        } else if (e?.target?.value) {
            // change on click
            setSelectedQuestion(Number(e?.target?.value))
        }

        setOpenQuestion(false);
    }

    useEffect(() => {
        // if change question add to store
        dispatch(searchForm({
            date: searchFormData.date,
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: searchFormData.category,
            question: question,
            age: searchFormData.age,
            region: searchFormData.region,
            kids: searchFormData.kids,
        }))
    }, [question])

    // clear question while change category
    function clearChecks() {
        if (document.querySelector('input[name="question"]:checked')) {
            document.querySelector('input[name="question"]:checked').checked = false;
        }
    }

    const toggleQuestion = () => {
        setOpenQuestion(!openQuestion);
        // if calendar is open close it
        // calendarIsOpen &&
        //     setCalendarIsOpen(false);
        // // if question is open close it
        // openCategory &&
        //     setOpenCategory(false);
    };
    useEffect(() => {
        // show certain category questions
        categoriesMainData &&
        setAllQuestions(categoriesMainData.filter(data => data.uniqueId === category))
    }, [category, question])


    useEffect(() => {
        // if we choose category and it saves in store show it checked
        searchFormData.category &&
        setCategory(searchFormData.category)
        searchFormData.question &&
        setSelectedQuestion(searchFormData.question)
        // if page is load show prev and next
        setTimeout(() => {
            setShowPrevNext(true)
        }, 100);
    }, [])

    function prev() {
        // if we check item other then first change to prev question
        if (Number(document.querySelector('input[name="question"]:checked').getAttribute('data-count')) !== 0) {
            try {
                document.querySelectorAll('input[name="question"]').forEach(element => {
                    if (Number(element.getAttribute('data-count')) === Number(document.querySelector('input[name="question"]:checked').getAttribute('data-count')) - 1) {
                        document.querySelector('input[name="question"]:checked').checked = false;
                        element.checked = true
                        changeQuestion(element)
                        // if find iem break 
                        throw 'Break';
                    }
                });
            } catch (e) {
                if (e !== 'Break') throw e
            }
        }
    }

    function next() {
        // if we check item other then last change to next question
        if (Number(document.querySelectorAll('input[name="question"]').length - 1) !== Number(document.querySelector('input[name="question"]:checked').getAttribute('data-count'))) {
            try {
                document.querySelectorAll('input[name="question"]').forEach(element => {
                    if (Number(element.getAttribute('data-count')) === Number(document.querySelector('input[name="question"]:checked').getAttribute('data-count')) + 1) {
                        document.querySelector('input[name="question"]:checked').checked = false;
                        element.checked = true
                        changeQuestion(element)
                        // if find iem break 
                        throw 'Break';
                    }
                });
            } catch (e) {
                if (e !== 'Break') throw e
            }
        }
    }

    useEffect(() => {
        console.log('dateData', dateData.getFullYear(), dateData.getMonth())
    }, [dateData])

    const {data: categoriesData, loading: categoriesLoading} = useQuery(gql`
        query {
            categories {
                categoryId
                name
            }
        }
    `)

    const {data: questionsData, loading: questionsLoading} = useQuery(gql`
        query questions($filter: FilterQuestionInput) {
            questions(filter: $filter) {
                questionId
                question
            }
        }
    `, {
        variables: {
            filter: {
                categoryId: Number(category),
                month: dateData.getMonth() + 1,
                year: dateData.getFullYear(),
            },
        }
    })

    return (
        <div>
            <div className='font-firstFont text-searchTitleFontSize  font-semibold tracking-wider'>
                {props.title} / {category ?
                categoriesMainData.filter(data => data.uniqueId === category).map((item) => (
                    item.name
                )) : ''
            }
            </div>
            <div className='mt-4'>
                {
                    // if page is load show prev and next
                    showPrevNext &&
                    <NextPrevQuestion next={() => next()} prev={() => prev()}/>
                }
            </div>
            <div className='flex mt-5'>
                <div className='w-2/12 mr-5 max-w-[8rem]' ref={dropdownMonthRef}>
                    <div className='tracking-wider text-sm font-firstFont mb-2'
                         onClick={() => calendarIsOpen && setCalendarIsOpen(false)}>Month
                    </div>
                    <div className='relative w-full'>
                        <div
                            className={`cursor-pointer ${dateData && !calendarIsOpen ? 'border-mainColor35' : ''} ${!dateData && !calendarIsOpen ? 'empty' : ''} openDropdown  w-full `}
                            onClick={() => toggleCalendar()}>
                            <span className=' line-one'>
                                {moment(dateData).format('MMM YYYY')}
                            </span>
                        </div>
                        {calendarIsOpen && (
                            <div className='absolute z-20 mainCalendar'>
                                <DatePicker
                                    dateFormat="MM/yyyy"
                                    selected={dateData}
                                    minDate={prevDate}
                                    maxDate={currentDate}
                                    onChange={(date) => changeDate(date)}
                                    showMonthYearPicker
                                    inline
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-2/12'>
                    <div className='tracking-wider text-sm font-firstFont mb-2'>Category</div>
                    <div className='relative w-full'>
                        <div
                            className={`cursor-pointer openDropdown  w-full ${category && !openCategory ? 'border-mainColor35' : ''} ${!category && !openCategory ? 'empty' : ''}`}
                            onClick={toggleCategory}>
                            {
                                category ?
                                    <span className=' line-one'>
                                        {
                                            categoriesData?.categories?.filter(data => data.categoryId === category).map((item) => (
                                                item.name
                                            ))
                                        }
                                    </span>
                                    :
                                    <span className=' line-one opacity-50'>
                                        Select category
                                    </span>
                            }
                        </div>
                        <ul className={`absolute w-full items z-10 font-dropdownFont min-w-[18.38rem] ${openCategory ? 'block' : 'hidden'}`}
                            ref={dropdownCategoryRef}>
                            {
                                categoriesData?.categories?.map((item, i) => (
                                    <li key={item.categoryId}>
                                        <div className="flex relative">
                                            <label
                                                className="containerCheckboxDropdown border-b border-b-whiteBorderColor noCheckbox">
                                                {
                                                    category && category === item.categoryId ?
                                                        <input type="radio"
                                                               name="category"
                                                               className="checkbox"
                                                               value={item.categoryId}
                                                               onChange={(e) => changeCategory(e)}
                                                               checked={true}
                                                        /> :
                                                        <input type="radio"
                                                               name="category"
                                                               className="checkbox"
                                                               value={item.categoryId}
                                                               onChange={(e) => changeCategory(e)}
                                                               checked={false}
                                                        />
                                                }
                                                <div
                                                    className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">{item.name}</div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div>

                <div className='w-8/12 ml-5'>
                    <div className='tracking-wider text-sm font-firstFont mb-2'>Question</div>
                    <div className='relative w-full'>
                        <div
                            className={`cursor-pointer openDropdown   w-full ${selectedQuestion && !openQuestion ? 'border-mainColor35' : ''} ${!selectedQuestion && !openQuestion ? 'empty' : ''} `}
                            onClick={toggleQuestion}>
                            {
                                question ?
                                    <span className=' line-one'>
                                        {
                                            questionsData?.questions?.find(questionData => questionData.questionId === selectedQuestion)?.question
                                        }
                                    </span>
                                    :
                                    <span className=' line-one opacity-50'>
                                        Select a Question
                                    </span>
                            }

                        </div>
                        <ul className={`absolute w-full items z-10 font-dropdownFont min-w-[18.38rem] ${openQuestion ? 'block' : 'hidden'}`}
                            ref={dropdownQuestionRef}>
                            {
                                questionsData?.questions?.map((item, i) => (
                                    <li key={item.questionId}>
                                        <div className="flex relative">
                                            <label
                                                className="containerCheckboxDropdown border-b border-b-whiteBorderColor noCheckbox">
                                                {
                                                    selectedQuestion === item.questionId ?
                                                        <input type="radio"
                                                               name="question"
                                                               className="checkbox"
                                                               value={item.questionId}
                                                               onChange={(e) => changeQuestion(e)}
                                                               defaultChecked={item.questionId}
                                                               data-count={i}
                                                        /> :
                                                        <input type="radio"
                                                               name="question"
                                                               className="checkbox"
                                                               value={item.questionId}
                                                               onChange={(e) => changeQuestion(e)}
                                                               data-count={i}
                                                        />
                                                }

                                                <div
                                                    className="text-white checkboxText font-medium tracking-wider text-dropdownFontSize">{item.question}</div>
                                                <span className="checkMarkDropdown"></span>
                                            </label>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
