import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import loginReducer from './features/login';
import firstLoginCategoryFormReducer from './features/firstLoginCategoryForm'
import userIsLogInReducer from './features/userIsLogIn'
import chooseCategoryReducer from './features/chooseCategory';
import searchFormDataReducer from './features/searchFormData'
import searchStepChangeReducer from './features/searchStepChange'
import personaSearchResultReducer from './features/personaSearchResult'
import showPopupReducer from './features/showPopup'

const store = configureStore({
  reducer: {
    login: loginReducer,
    firstLoginCategoryForm: firstLoginCategoryFormReducer,
    userIsLogIn: userIsLogInReducer,
    chooseCategory: chooseCategoryReducer,
    searchFormData: searchFormDataReducer,
    searchStepChange: searchStepChangeReducer,
    personaSearchResult: personaSearchResultReducer,
    showPopup: showPopupReducer
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
