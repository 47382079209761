import { useEffect } from "react";


/**
 * Hook that change state if clicks outside of the passed ref
 */
const useCloseDropdown = (ref, closeDropdown, stateData) => {
    useEffect(() => {
        /**
         * Change state to false if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                !stateData &&
                    closeDropdown()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default useCloseDropdown;