import React, {useEffect, useState} from 'react'
import Filters from './Filters'
import DemoSidebar from './DemoSidebar'
import DemoTextInfo from './DemoTextInfo'
import {useNavigate, useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../../ui/FullScreenLoader";
import {changePopupData} from "../../../features/showPopup";
import {useDispatch} from "react-redux";

export default function DemoSearchResult() {
    const {id} = useParams()
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const {data, loading, error} = useQuery(gql`
        query search($id: Int!) {
            search(id: $id) {
                searchId
                categoryId
                questionId
                type
                question {
                    questionId
                    categoryId
                    question
                    insight
                    finding
                    content
                    parsedContent
                    topAnswers {
                        answerId
                        answer {
                            answerId
                            parsedAnswer
                            personaId
                        }
                    }
                    demoCat1
                    parsedDemoCat1
                    parsedDemoCat2
                    parsedDemoCat3
                    parsedDemoCat4
                    parsedDemoCat5
                    parsedDemoCat6
                    month
                    year
                }
            }
        }
    `, {
        variables: {
            id: Number(id)
        },
        skip: !id || !Number(id),
    });

    const openPopup = (image, video) => {
        dispatch(changePopupData({
            video: video,
            image: image,
            open: true,
            audio: '',
            playAudio: false
        }))
        document.body.classList.add("overflow-hidden");
    }

    useEffect(() => {
        if (!loading && error) {
            console.log('error', error.message)
            navigate('/')
        }
    }, [error])

    const [questionId, setQuestionId] = useState();

    const {data: questionData, loading: questionLoading} = useQuery(gql`
        query question($id: Int!) {
            question(id: $id) {
                
                questionId
                categoryId
                question
                insight
                finding
                content
                parsedContent
                topAnswers {
                    answerId
                    answer {
                        answerId
                        parsedAnswer
                        personaId
                    }
                }
                demoCat1
                parsedDemoCat1
                parsedDemoCat2
                parsedDemoCat3
                parsedDemoCat4
                parsedDemoCat5
                parsedDemoCat6
                month
                year
                
            }
        }
    `, {
        variables: {
            id: Number(questionId),
        },
        skip: !questionId || !Number(questionId),
    })

    if (loading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <div className='pt-16 flex xl:pr-[4.75rem] lg:flex-row flex-col pb-20'>
            <div className='w-full h-full lg:w-[25%] pr-16 pb-8 pt-6 pl-6 border-2 border-transparentWhite5 rounded-[5px]'>
                <DemoSidebar question={questionData?.question || data?.search?.question}/>
            </div>
            <div className='w-full lg:w-[75%] pl-14'>
                <Filters
                    title="Demographic"
                    question={questionData?.question || data?.search?.question}
                    setQuestionId={setQuestionId}
                />
                <DemoTextInfo question={questionData?.question ||data?.search?.question}/>
            </div>
        </div>
    )
}
