import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
    // seat search result default data 
    // TODO after backend be ready push here search result
    personaData: [
        {
            id: 1,
            name: 'Keyshanna',
            age: '18',
            img: `/images/home/persona/persona1.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Los Angeles',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid ',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]

        },
        {
            id: 2,
            name: 'Gabrielle',
            age: '38',
            img: `/images/home/persona/persona2.png`,
            status: 'Married',
            children: 'With kids',
            country: 'Oakland',
            answer: 'A Thousand Years by Christina Perri Some Nights by Fun Me, Myself, & I by Beyonce',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]

        },
        {
            id: 3,
            name: 'Tarana',
            age: '49',
            img: `/images/home/persona/persona3.png`,
            status: 'Married',
            children: 'With kids',
            country: 'NY',
            answer: 'A Thousand Years by Christina Perri Some Nights by Fun Me, Myself, & I by Beyonce',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 4,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 5,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 6,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 7,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 8,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 9,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 10,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 11,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 12,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 13,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        },
        {
            id: 14,
            name: 'Nina',
            age: '21',
            img: `/images/home/persona/persona4.png`,
            status: 'Single',
            children: 'Without kids',
            country: 'Orange County',
            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar  Essence - wizkid',
            overview: `Keyshanna grew up in Los Angeles.  She lives at home and is currently attending LMU studying Health and Human Sciences.  Keyshanna is in a new relationship with her boyfriend Jamaal.  She is trying to balance school and her social life.  Also, she is very close to her family the youngest of 4 kids.  Keyshanna is spiritual but doesn’t attend church regularly.  She likes music, hanging out with her girlfriends and traveling.`,
            personality: `Shy, open-minded, agree-able and conscientious`,
            motivation: `Getting a good education, making her parents and family proud`,
            goals: `Travel the world, become a hospital administrator, get out of debt`,
            frustration: `Financial limitations, cost of college`,
            otherContent: [
                {
                    id: 1,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '/images/popup/video2.mp4',
                    post: '',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 2,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: 'https://www.google.com/',
                    audio: '',
                    popupImage: ''
                },
                {
                    id: 3,
                    img: `/images/home/sidebar/img1.png`,
                    section: "Media/ Entertainment/ Technology",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '/images/popup/audio2.mp3',
                    popupImage: ''
                },
                {
                    id: 4,
                    img: `/images/home/sidebar/img2.png`,
                    section: "Aspiration",
                    category: "women 25-34, w kids",
                    video: '',
                    post: '',
                    audio: '',
                    popupImage: '/images/popup/small-img.png'
                }
            ],
            answers: [
                {
                    id: 1,
                    name: 'Current Events & Politics',
                    uniqueId: 10,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 10,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 20,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 30,
                            answer: 'Both spiritual and emotional on a higher level than the others, and in second place would be physical and personal.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu ligula accumsan, luctus quam vitae, lobortis ipsum.'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 40,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Are you satisfied with the direction the country ,is heading?',
                            id: 5,
                            uniqueId: 50,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'
                        }

                    ]
                },
                {
                    id: 2,
                    name: 'Beauty & Health',
                    uniqueId: 20,
                    question: [
                        {
                            item: 'Do you vote in every election ?',
                            id: 1,
                            uniqueId: 60,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 2,
                            uniqueId: 70,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'How do you learn about current events?',
                            id: 3,
                            uniqueId: 80,
                            answer: 'Bungee Jumping'
                        }

                    ]
                },
                {
                    id: 3,
                    name: 'Lifestyle & Culture',
                    uniqueId: 30,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 90,
                            answer: 'Bungee Jumping'
                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 100,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '
                        },
                        {
                            item: 'Are you satisfied with the direction the country is heading?',
                            id: 3,
                            uniqueId: 110,
                            answer: 'All the Stars - SZA/Kendrick Lamar'
                        }

                    ]
                },
                {
                    id: 4,
                    name: 'Aspiration',
                    uniqueId: 40,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 120,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 130,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 140,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 150,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 5,
                    name: 'Money/ Finances/ Education',
                    uniqueId: 50,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 160,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 170,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 180,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 190,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }

                    ]
                },
                {
                    id: 6,
                    name: 'Food & Bevarage',
                    uniqueId: 60,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 200,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 210,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 220,
                            answer: 'Bungee Jumping'

                        }

                    ]
                },
                {
                    id: 7,
                    name: 'Love & Relationship',
                    uniqueId: 70,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 221,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 222,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 223,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 224,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        }

                    ]
                },
                {
                    id: 8,
                    name: 'Fashion',
                    uniqueId: 80,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 225,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 226,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 227,
                            answer: 'Are you satisfied with the direction the country ,is heading?',

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 228,
                            answer: 'All the Stars - SZA/Kendrick Lamar'

                        }

                    ]
                },
                {
                    id: 9,
                    name: 'Media Entertinment/ Technology',
                    uniqueId: 90,
                    question: [
                        {
                            item: 'How do you learn about current events?',
                            id: 1,
                            uniqueId: 229,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        },
                        {
                            item: 'Where do you get your news?',
                            id: 2,
                            uniqueId: 230,
                            answer: 'Bungee Jumping'

                        },
                        {
                            item: 'Do you discuss politics with others?',
                            id: 3,
                            uniqueId: 231,
                            answer: 'Steve Wonder, Steve Jobs & Michael Jackson'

                        },
                        {
                            item: 'Do you vote in every election ?',
                            id: 4,
                            uniqueId: 232,
                            answer: 'WUSYANAME - tyler the creator All the Stars - sza and kendrick lamar Essence - wizkid '

                        }
                    ]
                }
            ]
        }
    ],
    prevItemId: null,
    nextItemId: null,
}

export const userSlice = createSlice({
    name: "personaSearchResult",
    initialState: { value: initialStateValue },
    reducers: {
        addPersonaSearchResult: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { addPersonaSearchResult } = userSlice.actions;

export default userSlice.reducer;