import React from 'react'
import { categoriesMainData } from '../../../data/data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { searchForm } from '../../../features/searchFormData';
import { searchStep } from '../../../features/searchStepChange';
import {gql, useQuery} from "@apollo/client";


export default function HomeSectionTeamSlider() {
    let navigate = useNavigate();
    // slider arrows is set custom style
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        draggable: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };
    const dispatch = useDispatch()
    const searchFormData = useSelector((state) => state.searchFormData.value);


    function goToSearchPage(categories) {
        // if click on item change store category and go to search first step
        dispatch(searchForm({
            date: searchFormData.date,
            type: searchFormData.type,
            saveSearch: searchFormData.saveSearch,
            category: categories,
            question: searchFormData.question,
            age: searchFormData.age,
            region: searchFormData.region,
            kids: searchFormData.kids,
        }))
        dispatch(searchStep({
            step1: true,
            step2: false,
            step3: false
        }))
        return navigate("/search");

    }

    const {data: categoriesData, loading: categoriesLoading} = useQuery(gql`
        query categories {
            categories {
                categoryId
                name
            }
        }
    `)

    return (
        <div className='w-full  relative font-dropdownFont homeTeamSlider'>
            <Slider {...settings}>
                {
                    categoriesData?.categories &&
                    categoriesData?.categories.map((item, i) => (
                        <div key={i}>
                            <div
                                className="border-2 border-mainColor rounded-[0.313rem] mx-[0.625rem] font-semibold uppercase text-mainColor text-xs py-[5px] px-[15px] min-h-[4.688rem] flex cursor-pointer"
                                onClick={() => goToSearchPage(item.categoryId)}
                            >
                                <div className='line-three max-h-12 my-auto -tracking-tighter'>
                                    {item.name}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        </div >
    )
}
