import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { video: '', image: '', audio: '', playAudio: false, open: false, activeAudio: '' }

export const userSlice = createSlice({
    name: "showPopup",
    initialState: { value: initialStateValue },
    reducers: {
        changePopupData: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { changePopupData } = userSlice.actions;

export default userSlice.reducer;