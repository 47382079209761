import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { categoriesMainData } from '../../../data/data'
import { searchForm } from '../../../features/searchFormData';
import ChangeSteps from './ChangeSteps';
import { searchStep } from '../../../features/searchStepChange';
import {gql, useQuery} from "@apollo/client";


export default function StepThree() {
    const [category, setCategory] = useState('');
    const [question, setQuestion] = useState('');
    const [saveSearch, setSaveSearch] = useState(false);

    const searchStepChange = useSelector((state) => state.searchStepChange.value);

    const [allQuestions, setAllQuestions] = useState();
    const searchFormData = useSelector((state) => state.searchFormData.value);
    const dispatch = useDispatch()


    // clear question while change category
    function clearChecks() {
        if (document.querySelector('input[name="question"]:checked')) {
            document.querySelector('input[name="question"]:checked').checked = false;
        }
    }
    function changeCategory(e) {
        setCategory(Number(e.target.value))
        // if change category empty question
        setQuestion('')
        clearChecks()
    }
    function changeQuestion(e) {
        setQuestion(Number(e.target.value))
    }
    useEffect(() => {
        // set category to store first is always checked
        category ?
            dispatch(searchForm({
                date: searchFormData.date,
                type: searchFormData.type,
                saveSearch: saveSearch,
                category: category,
                question: question ? question : '',
                age: searchFormData.age,
                region: searchFormData.region,
                kids: searchFormData.kids,
            })) :
            setCategory(searchFormData.category)
        // show certain category questions
    }, [category, question, saveSearch])


    // save question 
    function changeSaveSearch() {
        setSaveSearch(!saveSearch)
    }

    useEffect(() => {
        searchFormData.question &&
            setQuestion(searchFormData.question)
        searchFormData.saveSearch &&
            setSaveSearch(searchFormData.saveSearch)
    }, [])
    function prevStep() {
        dispatch(searchStep({
            step1: searchStepChange.step1,
            step2: searchStepChange.step2,
            step3: false
        }))
    }

    const {data: categoriesData, loading: categoriesLoading} = useQuery(gql`
        query categories {
            categories {
                categoryId
                name
            }
        }
    `)

    const dateData = new Date(searchFormData.date);

    const {data: questionsData, loading: questionsLoading} = useQuery(gql`
        query questions($filter: FilterQuestionInput) {
            questions(filter: $filter) {
                questionId
                question
            }
        }
    `, {
        variables: {
            filter: {
                categoryId: Number(category),
                month: dateData.getMonth() + 1,
                year: dateData.getFullYear(),
            },
        },
        skip: !category || !dateData,
    })

    return (
        <div className='w-full xl:min-w-[65.938rem] m-auto'>
            <div className='w-full'>
                <div className='font-dropdownFont font-medium text-base mb-2'>Search by</div>
                <div className='font-firstFont font-semibold uppercase text-searchTextFontSize mb-10'>QUESTIONS</div>
            </div>
            <div className='flex flex-col lg:flex-row w-full justify-center overflow-hidden'>
                <div className='flex flex-col lg:w-[35%] mr-10'>
                    <div
                        className='tracking-wider text-transparentWhite5 text-base pb-6 font-firstFont font-medium'
                    >
                        Categories
                    </div>

                    <div className="rounded-[5px] border-2 border-mainColor h-full">
                        <ul className={` w-full items z-10 font-dropdownFont pb-5`}>
                            {/* <ul className={` w-full items z-10 font-dropdownFont min-w-[16rem] lg:min-w-[30%] pb-5`}>  min-w-[19.5rem]*/}
                            {
                                categoriesData?.categories?.map((item, i) => (
                                    <li className='mr-[3.438rem] mt-3' key={i}>
                                        <div className="flex relative">
                                            <label className="stepCategory">

                                                <input
                                                    type="radio"
                                                    name="category"
                                                    className="Radio"
                                                    value={item.categoryId}
                                                    onChange={(e) => changeCategory(e)}
                                                    checked={category === item.categoryId}
                                                />

                                                <div className="text-white RadioText font-medium tracking-wider  ">
                                                    {item.name}
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className='lg:w-[65%]  max-w-[42.813rem]'>
                    <div className='tracking-wider text-transparentWhite5 text-base pb-6 font-firstFont font-medium'>
                        Please select the question you’re interested in
                    </div>
                    <div className='rounded-[5px] border-2 border-transparentWhite h-[21.875rem] px-[1.125rem] py-5 pr-2'>
                        {/* min-w-[16rem]  */}
                        {questionsData?.questions?.length > 0 && (
                            <ul className={` w-full items z-10 font-dropdownFont  h-full overflow-y-scroll  customScroll pr-1`}>
                                {
                                    questionsData?.questions?.map((item, key) => (
                                        <li key={item.questionId} >
                                            <div className="flex relative">
                                                <label className="stepQuestion">
                                                    <input
                                                        type="radio"
                                                        name="question"
                                                        className="Radio"
                                                        value={item.questionId}
                                                        onChange={(e) => changeQuestion(e)}
                                                        checked={question === item.questionId}
                                                        // defaultChecked={isChecked}
                                                    />
                                                    <div className="text-white RadioText font-medium tracking-wider  ">{item.question}</div>
                                                </label>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        )}
                        {questionsData?.questions?.length === 0 && (
                            <div className="w-full h-full flex items-center justify-center text-xs text-transparentWhite8">
                                No questions available for this month yet
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex relative mt-8 mb-10">
                <label className="formCheckbox ">
                    <input type="checkbox" className="checkbox" value={saveSearch} onChange={() => changeSaveSearch()} checked={saveSearch} />
                    <div className="checkboxText">Save Search</div>
                    <span className="checkMarkDropdown"></span>
                </label>
            </div>
            <ChangeSteps
                disablePrev={false}
                disableNext={!category || !question}
                goPrev={() => prevStep()}
                step={3}
                lastStep={searchFormData.type === "demo" ? "/demo-result" : "/persona-result"}
            />
        </div>
    )
}
