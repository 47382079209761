import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { date: '', type: '', saveSearch: false, category: '', question: '', age: [], region: [], kids: false }

export const userSlice = createSlice({
    name: "searchFormData",
    initialState: { value: initialStateValue },
    reducers: {
        searchForm: (state, action) => {
            state.value = action.payload;
        },
        searchFormDeleteData: (state, action) => {
            state.value = initialStateValue
        },
    },
});

export const { searchForm, searchFormDeleteData } = userSlice.actions;

export default userSlice.reducer;