import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import HomeSectionTitle from '../HomeSectionTitle'
import HomeMonthSlider from './HomeMonthSlider'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useCloseDropdown from '../../lib/useCloseDropdown';
import {gql, useQuery} from "@apollo/client";
export default function HomeMonth() {
    const [dateData, setDateData] = useState(new Date());
    const currentDate = new Date()
    const prevDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
    const dropdownRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const toggleCalendar = () => {
        setIsOpen(!isOpen);
    };
    const changeDate = (date) => {
        toggleCalendar()
        setDateData(date)
    };
    // dropdown outside click function
    useCloseDropdown(dropdownRef, () => setIsOpen(false), isOpen)

    const {data, loading} = useQuery(gql`
        query questionsMostViewed(
            $filter: FilterQuestionInput
            $order: OrderQuestionInput
            $take: Int
        ) {
            questionsMostViewed(
                filter: $filter
                order: $order
                take: $take
            ) {
                questionId
                question
                categoryId
                categoryName
                viewsCount
                month
                year
                parsedContent
                topAnswers {
                    topAnswerId
                    answer {
                        parsedAnswer
                    }
                    persona {
                        name
                        age
                        kids
                        status
                        region
                        image
                    }
                }
            }
        }
    `, {
        variables: {
            filter: {
                month: dateData.getMonth() + 1,
                year: dateData.getFullYear(),
            },
            take: 10,
        }
    })

    return (
        <div>
            <div className='w-full mt-12'>
                <div className='pl-[0.625rem] mb-3'>
                    <HomeSectionTitle title="month" />
                </div>
                <div className='pl-[0.625rem] flex items-center mb-6'>
                    <div className='font-firstFont text-sm text-transparentWhite8'>
                        Most viewed data of
                    </div>
                    <div className='relative' >
                        <div className="cursor-pointer openHomeCalendar" onClick={toggleCalendar}>
                            {moment(dateData).format('MMM YYYY')}
                        </div>
                        {isOpen && (
                            <div className='absolute z-20 homeCalendar' ref={dropdownRef}>
                                <DatePicker
                                    dateFormat="MM/yyyy"
                                    selected={dateData}
                                    minDate={prevDate}
                                    maxDate={currentDate}
                                    onChange={(date) => changeDate(date)}
                                    showMonthYearPicker
                                    inline
                                />
                            </div>
                        )}
                    </div>
                </div>

                <HomeMonthSlider questions={data?.questionsMostViewed} />

                <div className='mt-5 ml-3'>
                    <NavLink to='/search' className="white-button">
                        Search
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
