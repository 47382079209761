
export default function GlobalLayout({ children, showTopMenu }) {
  return (
    <div className='bg-bgMain font-normal min-h-[100vh] text-textColor'>
      {
        showTopMenu &&
        <div>search</div>
      }
      <div className="main-container h-full overflow-hidden">
        {children}
      </div>

    </div>
  );
}
