import React, { useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import SubmitErrorPopup from './SubmitErrorPopup';
import {gql, useMutation} from "@apollo/client";

export default function SignUnForm() {
    let navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [sending, setSending] = useState(false)
    const [open, setOpen] = useState(false);
    const [emailError, setEmailError] = useState("")
    // form data
    const { register, handleSubmit, formState: { errors }, watch, getValues, reset
    } = useForm();
    // const [data, setData] = useState("");

    const [mutateRegister] = useMutation(gql`
        mutation register($input: CreateUserInput!) {
            register(input: $input) {
                email
                accessToken
            }
        }
    `);

    // compare passwords 
    const password = useRef({});
    password.current = watch("password", "");
    // compare passwords end
    const onSubmit = async data => {
        setSending(true)
        setEmailError('')
        console.log('JSON.stringify(data)', data)

        mutateRegister({
            variables: {
                input: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    password: data.password,
                    repeatPassword: data.password_repeat,
                    phone: data.phone,
                }
            },
            onError: error => {
                console.log('onError register', errors.message)
                setSending(false)
                if (error.message.includes("Email")) {
                    setEmailError(error.message)
                }
            }
        }).then(({data}) => {
            setSending(false)
            if (data?.register?.accessToken) {
                reset({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    password: "",
                    password_repeat: "",
                    acceptTerms: ""
                })
                // if form submit success  redirect to success page
                return navigate("/sign-up-success");
            }
        })
        // if (data) {
        //     console.log('user is log in');
        //     setSending(false)
        //     reset({
        //         firstName: "",
        //         lastName: "",
        //         email: "",
        //         phone: "",
        //         password: "",
        //         password_repeat: "",
        //         acceptTerms: ""
        //     })
        //     // if form submit success  redirect to success page
        //     return navigate("/sign-up-success");
        // }
    };

    // uncomment to open error popup and call function

    // function openPopup() {
    //     setOpen(true);
    //     document.body.classList.add("overflow--hidden");
    // }
    function closePopup() {
        setOpen(false);
    }
    return (
        <div className='text-black'>
            <SubmitErrorPopup title="Error!" text="Something went wrong. Please try again." closePopup={() => closePopup()} showPopup={open} />
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div>
                    <div className="flex md:flex-row flex-col mb-4">
                        <div className="w-full md:w-1/2 md:mr-[0.625rem] pb-4 relative">
                            <label className="input-label" htmlFor="first_name">First Name*</label>
                            <input className={`input-field ${errors.firstName ? "input-field-error" : ''}`} type="text" placeholder="First Name" {...register("firstName", {
                                required: true
                            })} id="first_name" />
                            {errors.firstName && <span className="itemErrorMessage">This field is required</span>}
                        </div>
                        <div className="w-full md:w-1/2 md:ml-[0.625rem] pb-4 relative">
                            <label className="input-label" htmlFor="last_name">Last Name*</label>
                            <input className={`input-field ${errors.lastName ? "input-field-error" : ''}`} id="last_name" type="text"  {...register("lastName", {
                                required: true
                            })} placeholder="Last Name" />

                            {errors.lastName && <span className="itemErrorMessage">This field is required</span>}
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col mb-4">
                        <div className="w-full md:w-1/2 md:mr-[0.625rem] pb-4 relative">
                            <label className="input-label" htmlFor="email">Email *</label>
                            <input className={`input-field ${(errors.email || emailError) ? "input-field-error" : ''}`} type="text" {...register("email", {
                                required: "This field is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Email format is incorrect"
                                }
                            })} placeholder="example@company.io" id="email" />
                            {
                                // if form email is wrong or dodnlt match password
                                !errors.email && emailError &&
                                <span className="itemErrorMessage">
                                    {emailError}
                                </span>
                            }
                            {
                                errors?.email?.message &&
                                <span className="itemErrorMessage">
                                    {errors.email.message}
                                </span>
                            }

                        </div>
                        <div className="w-full md:w-1/2 md:ml-[0.625rem] pb-4 relative">
                            <label className="input-label" htmlFor="phone">Phone *</label>
                            <input className={`input-field ${errors.phone ? "input-field-error" : ''}`} id="phone" type="text" {...register("phone", {
                                required: "This field is required",
                                pattern: {
                                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                    message: "Phone format is incorrect"
                                }
                            })} placeholder="+45 09383920" />
                            {
                                errors?.phone?.message ?
                                    <span className="itemErrorMessage">
                                        {errors.phone.message}
                                    </span> : ''
                            }
                        </div>
                    </div>

                    <div className="flex md:flex-row flex-col mb-4">
                        <div className="w-full md:w-1/2 md:mr-[0.625rem] pb-4 relative">
                            <label className="input-label" htmlFor="password">Password *</label>
                            <input className={`input-field ${errors.password ? "input-field-error" : ''}`} type={showPassword ? "text" : "password"} {...register("password", {
                                required: "You must specify a password",
                                minLength: {
                                    value: 4,
                                    message: "Password must have at least 6 characters"
                                }
                            })} placeholder="Min. 4 characters" id="password" />
                            <span className={`passwordIcon ${showPassword ? "show" : ""}`} onClick={() => setShowPassword(!showPassword)}> </span>
                            {errors.password && <span className="itemErrorMessage">{errors.password.message}</span>}
                        </div>
                        <div className="w-full md:w-1/2 md:ml-[0.625rem] pb-4 relative">
                            <label className="input-label" htmlFor="repeatPassword">Repeat Password *</label>
                            <input className={`input-field ${errors.password_repeat ? "input-field-error" : ''}`} id="repeatPassword" type={showPasswordRepeat ? "text" : "password"} {...register("password_repeat", {
                                validate: value =>
                                    value === password.current || "The passwords do not match"
                            })} placeholder="Type password again" />
                            <span className={`passwordIcon ${showPasswordRepeat ? "show" : ""}`} onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}> </span>
                            {errors.password_repeat && <span className="itemErrorMessage">{errors.password_repeat.message}</span>}
                        </div>

                    </div>
                    <div className="flex pt-14 pb-5 relative">
                        <label className="containerCheckbox pb-[0.938rem]">
                            <input type="checkbox" className="checkbox" {...register('acceptTerms', {
                                required: true,
                            })} />
                            <span className="text-formSubSize text-white ">I Accept <NavLink to="/" className="text-mainColor">Terms & Condition</NavLink></span>
                            <span className="checkMark"></span>
                        </label>
                        {errors.acceptTerms && <span className="itemErrorMessage top-[5rem]  ">This field is required</span>}
                    </div>
                </div>
                {
                    // if we are sending data show loader
                    sending ?
                        <div className="main-button w-fit">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div> :
                        // after or before send
                        <input type="submit" value={"Create Account"} className="main-button" />
                }
                <div className="text-formSubSize text-white pt-5"> Already a Member? Sign in <NavLink to="/login" className="text-mainColor">here.</NavLink></div>
            </form >
        </div >
    )
}
