import React, {useMemo} from 'react'
import { personaHomeList } from '../../../data/data'
import HomePersonaSectionItem from './HomePersonaSectionItem'
import {gql, useQuery} from "@apollo/client";
export default function HomePersonaSection() {

  const {loading: personasLoading, data: personasData, error: personasError} = useQuery(gql`
        query {
            personas {
                personaId
                name
                age
                region
                status
                kids
                overview
                image
            }
        }
    `)

  const personas = useMemo(() =>
      personasData && personasData.personas &&
          personasData.personas.slice(0, 4).map((persona) => ({
            ...persona,
            image: persona.image?.includes('/images/') ? `${process.env.REACT_APP_BASE_URL}${persona.image}` : persona.image,
          }))
          || [],
      [personasData]
  );

  return (
    <div className='flex justify-around lg:flex-row flex-col '>
      {
        personas.map((item, i) => (
          // showRightLine if element is last show last right line 
          <HomePersonaSectionItem key={item.personaId} data={item} showRightLine={personasData.personas.length === i + 1} />
        ))
      }
    </div>
  )
}
