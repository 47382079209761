import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {region} from "../../../data/data";

export default function PersonaDashboardTable({ data }) {
    let [category, setCategory] = useState(0)
    let [questions, setQuestions] = useState(0)

    useEffect(() => {
        setCategory(0)
        setQuestions(0)
        const categoriesIds = [];
        data.answers?.map((item) => (
            categoriesIds.push(item?.question?.categoryId)
        ))
        setCategory([...new Set(categoriesIds)].length)
        data.answers?.map((item) => (
            setQuestions(prevState => prevState + 1)
        ))
    }, [])
    return (
        <NavLink to={`/persona/${data.personaId}`} className={'group'}>
            <div className='flex w-full font-firstFont text-xs tracking-[0.04em] items-center pt-4 pb-3 border-b border-b-transparentWhite25 group-hover:bg-mainColor group-hover:text-black font-medium'>
                <div className='w-3/12'>
                    <div className='flex items-center pl-1'>
                        < img src={data?.image} alt={data?.name} className="w-[1.56rem] h-[1.56rem] object-cover rounded-[100%] mr-7" />
                        <span >{data?.name}</span>
                    </div>
                </div>
                <div className='w-1/12'>
                    {data?.age}
                </div>
                <div className='w-2/12'>
                    {data?.region &&
                    region.find((el) => el.abbreviation === data.region)?.name
                    || data?.region}

                </div>
                <div className='w-2/12'>
                    {data?.status && `${data?.status}, `}
                    {data?.kids && `Kids - ${data?.kids}`}
                </div>
                <div className='w-3/12'>
                    {data?.overview}
                </div>
                <div className='w-1/12'>
                    <div>
                        {questions} <span className='text-transparentWhite5 group-hover:text-[rgba(0,0,0,0.5)]'>answers</span>
                    </div>
                    <div>
                        {category} <span className='text-transparentWhite5 group-hover:text-[rgba(0,0,0,0.5)]'>categories</span>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}
