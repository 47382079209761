import React from 'react'
import PersonaCategoryQuestion from './PersonaCategoryQuestion'
import PersonaFiles from './PersonaFiles'
import PersonaQuestion from './PersonaQuestion'

export default function PersonaInfo({ data, persona }) {
    return (
        <div>
            <PersonaQuestion data={data} />
            <PersonaFiles data={data} answers={persona?.answers} />
            <PersonaCategoryQuestion data={data?.answers} answers={persona?.answers} />
        </div>
    )
}
