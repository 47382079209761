import React, {useMemo} from 'react'
import PersonaDashboardContent from '../gobal/PersonaDashboard/PersonaDashboardContent'
import PersonaMostSearched from '../gobal/PersonaDashboard/PersonaMostSearched'
import GlobalLayout from '../layout/GlobalLayout'
import TopMenu from '../layout/TopMenu'
import {UserStatusGoPage} from '../lib/userStatus'
import {gql, useQuery} from "@apollo/client";
import FullScreenLoader from "../../ui/FullScreenLoader";

export default function Personas() {
    const {loading: personasLoading, data: personasData, error: personasError} = useQuery(gql`
        query {
            personas {
                personaId
                name
                age
                region
                status
                kids
                overview
                image
                answers {
                    answerId
                    answer
                    parsedAnswer
                    question {
                        questionId
                        question
                        categoryId
                    }
                }
            }
        }
    `)

    const personas = useMemo(() =>
            personasData && personasData.personas &&
            personasData.personas.map((persona) => ({
                ...persona,
                image: persona.image?.includes('/images/') ? `${process.env.REACT_APP_BASE_URL}${persona.image}` : persona.image,
            }))
            || [],
        [personasData]
    );


    UserStatusGoPage("/personas")

    if (personasLoading) {
        return (
            <FullScreenLoader/>
        )
    }
    return (
        <div>
            <GlobalLayout showTopMenu={false}>
                <TopMenu/>
                <div className="pl-10 pr-5 mt-2">
                    <div className="flex font-firstFont text-sm tracking-wider">
                        <div className="pr-5 border-r-2"><a className="flex items-center opacity-60" href="/"><img
                            src="/images/icons/back.svg" alt="back"/><span
                            className="ml-[0.625rem]">Dashboard</span></a></div>
                        <div className="flex ml-7">
                            <div className="ml-2 ">Personas /</div>
                        </div>
                    </div>
                </div>
                <PersonaMostSearched personas={personas} />
                <PersonaDashboardContent personas={personas} />
            </GlobalLayout>
        </div>
    )
}
