import React from 'react'
import FirstLoginCategoryForm from '../gobal/FirstLoginCategoryForm'
import FormTitle from '../gobal/FormTitle'
import GlobalLayout from '../layout/GlobalLayout'
import UserLayout from '../layout/UserLayout'
import { UserStatusGoPage } from '../lib/userStatus'

export default function FirstLoginCategory() {
    // should check url which user click to come to this page
    UserStatusGoPage("/")
    return (
        <GlobalLayout showTopMenu={false} >
            <UserLayout>
                <div className='max-w-[45.188rem]  px-4 font-formFont w-full pt-[5rem] md:pt-[15.625rem] pb-[5rem] md:min-h-[64rem]'>
                    <div className='pb-[4.688rem]'>
                        <FormTitle info="Hello, welcome to" title="Impath" />
                    </div>
                    <div className='uppercase opacity-50 text-formSmallText  max-w-[25.938rem] pb-8'>to get you started please select a few fields you’d like to have focus on.</div>
                    <FirstLoginCategoryForm />
                </div>
            </UserLayout>
        </GlobalLayout>
    )
}
