import React, { useEffect, useState } from 'react'
import { personaList } from '../../../data/data'
import PersonaCardItem from '../PersonaCardItem'
import ReactPaginate from 'react-paginate';

export default function PersonaList({ answers }) {

    const [offset, setOffset] = useState(0);
    const [startSlice, setStartSlice] = useState(0);
    const [data, setData] = useState([]);
    const [perPage] = useState(9);
    const [pageCount, setPageCount] = useState(0)

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage)
        setStartSlice(selectedPage * perPage)
    };

    const getData = async () => {
        const data = personaList;
        const slice = personaList.slice(startSlice,
            startSlice + perPage)
        const postData = slice.map((item) => (
            <div key={item.id} className="w-1/1 md:w-1/3 px-4 pb-6">
                <PersonaCardItem data={item} />
            </div>
        ))
        setData(postData)
        setPageCount(Math.ceil(data.length / perPage))
    }
    useEffect(() => {
        getData()
    }, [offset])

    if (!answers || !answers.length) {
        return (
            // if nothing found
            <div className='relative flex mb-8'>
                <div
                    className="border  border-white rounded-[0.313rem] mx-[0.625rem]    text-white text-xs py-[5px] px-[10px] min-h-[5rem] flex -tracking-tighter   items-center">
                    <div>
                        <div className='line-three max-h-12 my-auto font-semibold mb-1'>
                            Nothing found
                        </div>
                        <div className='text-[rgba(255,255,255,0.45)] line-two'>
                            Wasn't found any personas for given criteria. Please search again
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className=' min-h-[42.938rem]'>
                <div className='flex flex-wrap -mx-4'>
                    {answers?.map((answer, key) => (
                        <div key={answer.answerId} className="w-1/1 md:w-1/3 px-4 pb-6">
                            <PersonaCardItem answer={answer} />
                        </div>
                    ))}
                    {/*{data && data}*/}
                </div>
            </div>
            <div className='personaPagination relative text-[17px] font-firstFont  mx-auto pb-10 '>
                <div className='flex justify-center text-transparentWhite5 mt-5'>
                    {
                        // offset + 1 + ' of ' + pageCount
                        "1 of 1"
                    }
                </div>
                {/*<ReactPaginate*/}
                {/*    previousLabel={<div className='cursor-pointer flex items-center pageChange'  >*/}
                {/*        <img src={`/images/search/prev.svg`} alt="close" className='w-4 h-4 object-contain mr-3' />*/}
                {/*        <span>Previous</span>*/}
                {/*    </div>}*/}
                {/*    nextLabel={<div className='cursor-pointer flex items-center min-w-[84px] justify-end pageChange' >*/}
                {/*        <span>Next</span>*/}
                {/*        <img src={`/images/search/next.svg`} alt="close" className='w-4 h-4 object-contain ml-3' />*/}
                {/*    </div>}*/}
                {/*    breakLabel={"/"}*/}
                {/*    breakClassName={"break-me"}*/}
                {/*    pageCount={pageCount}*/}
                {/*    extraAriaContext={pageCount}*/}
                {/*    marginPagesDisplayed={1}*/}
                {/*    pageRangeDisplayed={1}*/}
                {/*    onPageChange={handlePageClick}*/}
                {/*    containerClassName={"pagination"}*/}
                {/*    subContainerClassName={"pages pagination"}*/}
                {/*    activeClassName={"active"} */}
                {/*/>*/}
            </div>

        </div>
    )
}
