import React, { useEffect, useState } from 'react'
import StepOne from './StepOne'
import { useSelector } from "react-redux";
import StepTwo from './StepTwo';
import SearchStepNumbers from './SearchStepNumbers';
import StepThree from './StepThree';
import { useDispatch } from 'react-redux';
import { searchStep } from '../../../features/searchStepChange';


export default function SearchForm() {
    // const searchFormData = useSelector((state) => state.searchFormData.value);
    // const dispatch = useDispatch()
    const searchStepChange = useSelector((state) => state.searchStepChange.value);
    // useEffect(() => {
    //     dispatch(searchStep({
    //         step1: true,
    //         step2: false,
    //         step3: false
    //     }))
    // }, [])
    return (

        <div className='flex flex-col items-center relative mt-8 min-h-[95vh]'>
            {/* left line */}
            <div className='absolute left-0 bg-mainColor w-11 h-full'>
            </div>
            <SearchStepNumbers />
            {/* search form steps  */}
            <div className={`${searchStepChange.step1 && !searchStepChange.step2 && !searchStepChange.step3 ? 'block' : 'hidden'}`}>
                <StepOne />
            </div>
            <div className={`${searchStepChange.step1 && searchStepChange.step2 && !searchStepChange.step3 ? 'block' : 'hidden'}`}>
                <StepTwo />
            </div>
            <div className={`${searchStepChange.step1 && searchStepChange.step2 && searchStepChange.step3 ? 'block' : 'hidden'}`}>
                <StepThree />
            </div>
        </div>
    )
}
