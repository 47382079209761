import React from 'react'
import { NavLink } from 'react-router-dom'
import {region} from "../../../data/data";

export default function PersonaDashboardCard({ data }) {
    return (
        <div className='border border-mainColor rounded-[0.313rem] min-h-full flex flex-col justify-between'>
            <div>
                <div className='flex py-[0.875rem] px-[1.188rem] border-b border-b-transparentWhite25'>
                    <div className='mr-4'>
                        <img src={data.image} alt={data.name} className="text-xs w-[2.813rem] h-[2.813rem] object-cover rounded-[100%]" />
                    </div>
                    <div className='font-firstFont text-xs tracking-[0.04em] font-medium'>
                        <div>{data.name}, {data.age}</div>
                        <div className='text-transparentWhite45'>
                            <div>{data?.region &&
                            region.find((el) => el.abbreviation === data.region)?.name
                            || data?.region}</div>
                            <div>{data.status}, Kids - {data.kids}</div>
                        </div>
                    </div>
                </div>
                <div className='py-[0.875rem] px-[1.188rem] font-firstFont text-xs tracking-[0.04em]'>
                    <div className='mb-1 text-transparentWhite5'>Personality</div>
                    <div className='font-medium  overflow-hidden'>
                        <div className='line-three min-h-[3rem]'>
                            {data.overview}
                        </div>
                    </div>
                </div>
            </div>
            <NavLink to={`/persona/${data.personaId}`} className="bg-mainColor flex justify-center items-center text-bgMain font-firstFont font-medium text-xs tracking-[0.04em] py-[0.438rem]">
                More
            </NavLink>
        </div>
    )
}
