import React from 'react'
import FormTitle from '../gobal/FormTitle'
import LoginForm from '../gobal/LoginForm'
import GlobalLayout from '../layout/GlobalLayout'
import UserLayout from '../layout/UserLayout'
import { UserStatusGoPage } from '../lib/userStatus'

export default function Login() {
    UserStatusGoPage("/")
    return (
        <GlobalLayout showTopMenu={false} >
            <UserLayout>
                <div className='max-w-[32.375rem]  px-4 font-formFont w-full pt-[5rem] md:pt-[15.625rem] pb-[5rem]'>
                    <div className='mb-7'>
                        <FormTitle info="Hello, welcome to" title="Impath" />
                    </div>
                    <LoginForm />
                </div>
            </UserLayout>
        </GlobalLayout>
    )
}
