import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changePopupData } from '../../../features/showPopup';
import PopupVideoSection from './PopupVideoSection';

export default function Popup() {
    const showPopup = useSelector((state) => state.showPopup.value);
    const dispatch = useDispatch()
    const closePopup = () => {
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: false
        }))
        document.body.classList.remove("overflow-hidden");
    }
    return (
        <div className={`top-0 left-0 z-50 fixed w-full  h-full bg-popupBg flex justify-center pt-40 overflow-auto mediaPopup ${showPopup.open ? 'open' : ''}`} >
            <div className='absolute w-full h-full left-0 top-0 z-10 cursor-pointer' onClick={() => closePopup()}></div>
            {
                showPopup.image &&
                <div className='relative z-20 h-fit'>
                    <div className='closePopup font-firstFont text-xs text-transparentWhite65 text-right pr-4 py-2 absolute left-0 top-0 w-full cursor-pointer' onClick={() => closePopup()}>Close</div>
                    <div className='max-w-[61.50rem] pb-10 w-full'>
                        <img src={showPopup.image} alt='item' className='w-full rounded-[0.188rem] overflow-hidden' />
                    </div>
                </div>
            }
            {
                showPopup.video &&
                <PopupVideoSection closePopup={() => closePopup()} />
            }
        </div>
    )
}
