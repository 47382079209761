import React, {useState} from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {gql, useMutation} from "@apollo/client";
import {useForm} from "react-hook-form";
import {userIsLoginFunctional} from "../../features/userIsLogIn";
import {useDispatch} from "react-redux";

export default function AccountRecoveryForm({ email = "" }) {
    let navigate = useNavigate();
    const {token} = useParams();
    const dispatch = useDispatch()

    const [sending, setSending] = useState(false)
    const [emailErrorCompare, setEmailErrorCompare] = useState('')

    const [mutateUpdatePassword] = useMutation(gql`
        mutation updatePassword($input: RecoverUserInput!) {
            updatePassword(input: $input) {
                email
                accessToken
            }
        }
    `);

    // submit form
    const onSubmit = async data => {

        setSending(true)
        setEmailErrorCompare('')

        mutateUpdatePassword({
            variables: {
                input: {
                    email,
                    token,
                    password: data.password,
                    repeatPassword: data.password_repeat,
                }
            },
            onError: errors => {
                console.log('onError', errors.message)
                setSending(false)
                if (errors.message.includes('user')) {
                    setEmailErrorCompare(errors.message)
                } else {
                    setEmailErrorCompare('Email not found!')
                }
            }
        }).then(({data}) => {
            setSending(false)
            if (data?.updatePassword) {
                // if success
                localStorage.setItem('token', data.updatePassword.accessToken)
                dispatch(userIsLoginFunctional({isLogIn: true}))

                navigate('/')
            }
        })
    };

    const { register, handleSubmit, formState: { errors }, watch, getValues, reset
    } = useForm();

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)


    return (
        <div className='text-black'>
            <form
                onSubmit={handleSubmit((data) => onSubmit(data))}
            >
                <div className="w-full md:mr-[0.625rem] pb-6 relative">
                    <label className="input-label" htmlFor="email">Email</label>
                    <input
                        value={email}
                        className={`input-field ${(emailErrorCompare || errors.email) ? "input-field-error" : ''}`}
                        type="email"
                        required={true}
                        placeholder="example@company.io"
                        id="email"
                        // onChange={event => setEmail(event.target.value)}
                        disabled={true}
                    />
                    {
                        // if form email is wrong or dodnlt match password
                        emailErrorCompare &&
                        <span className="itemErrorMessage">
                            {emailErrorCompare}
                        </span>
                    }
                </div>

                <div className="w-full md:mr-[0.625rem] pb-6 relative">
                    <label className="input-label" htmlFor="password">Password *</label>
                    <input className={`input-field ${errors.password ? "input-field-error" : ''}`} type={showPassword ? "text" : "password"} {...register("password", {
                        required: "You must specify a password",
                        minLength: {
                            value: 6,
                            message: "Password must have at least 6 characters"
                        }
                    })} placeholder="Password" id="password" />
                    <span className={`passwordIcon ${showPassword ? "show" : ""}`} onClick={() => setShowPassword(!showPassword)}> </span>
                    {errors.password && <span className="itemErrorMessage">{errors.password.message}</span>}
                </div>

                <div className="w-full md:mr-[0.625rem] pb-10 relative">
                    <label className="input-label" htmlFor="repeatPassword">Repeat Password *</label>
                    <input className={`input-field ${errors.password_repeat ? "input-field-error" : ''}`} id="repeatPassword" type={showPasswordRepeat ? "text" : "password"} {...register("password_repeat", {
                        validate: value =>
                            value === watch("password", "") || "The passwords do not match"
                    })} placeholder="Type password again" />
                    <span className={`passwordIcon ${showPasswordRepeat ? "show" : ""}`} onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}> </span>
                    {errors.password_repeat && <span className="itemErrorMessage">{errors.password_repeat.message}</span>}
                </div>

                {
                    // if we are sending data
                    sending ?
                        <div className="main-button w-fit">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div> :
                        // after or before send
                        <input type="submit" value={"Send"} className="main-button"/>
                }
                <div className="text-formSubSize text-white pt-[3.125rem]">
                    Remember password? Sign in
                    &nbsp;
                    <NavLink
                        to="/login"
                        className="text-mainColor"
                    >
                        Here.
                    </NavLink>
                </div>
            </form>
        </div>
    )
}
