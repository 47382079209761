import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {personaList} from '../../data/data';
import PersonaContent from '../gobal/PersonaSInglePage/PersonaContent';
import GlobalLayout from '../layout/GlobalLayout';
import TopMenu from '../layout/TopMenu';
import {addPersonaSearchResult} from '../../features/personaSearchResult';
import HiddenAudio from '../gobal/Popup/HiddenAudio';
import Popup from '../gobal/Popup/Popup';
import {changePopupData} from '../../features/showPopup';
import {searchStep} from '../../features/searchStepChange';
import {gql, useQuery} from "@apollo/client";
import {number} from "tailwindcss/lib/util/dataTypes";
import FullScreenLoader from "../../ui/FullScreenLoader";

export default function SinglePersona(props) {
    // const state = useLocation();
    const {id} = useParams()
    const [data, setData] = useState([])
    // const [prevNextData, setPrevNextData] = useState([])
    const [currentItemIndex, setCurrentItemIndex] = useState()
    const [prevItemIndex, setPrevItemIndex] = useState(null)
    const [nextItemIndex, setNextItemIndex] = useState(null)
    const searchFormData = useSelector((state) => state.searchFormData.value);
    const personaSearchResult = useSelector((state) => state.personaSearchResult.value);
    const dispatch = useDispatch()
    let navigate = useNavigate();

    const {data: personaData, loading: personaLoading} = useQuery(gql`
        query persona($id: Int!) {
            persona(id: $id) {
                personaId
                image
                name
                region
                age
                kids
                overview
                answers {
                    answerId
                    parsedAnswer
                    question {
                        questionId
                        question
                        parsedContent
                        categoryName
                        category {
                            categoryId
                            name
                        }
                    }
                }
            }
        }
    `, {
        variables: {
            id: Number(id),
        },
        skip: !id,
    })

    const persona = useMemo(() => personaData?.persona && {
        ...personaData?.persona,
        image: personaData?.persona.image.includes('/images/') ? `${process.env.REACT_APP_BASE_URL}${personaData?.persona.image}` : personaData?.persona.image,
    } || {}, [personaData]);


    useEffect(() => {
        if (!searchFormData.category && !searchFormData.question) {
            // if we don't come to single page after search
            setData(
                personaList.filter(function (item) {
                    return item.id === Number(id);
                })
            )
        } else {
            // if we come to single page after search
            setData(
                personaSearchResult.personaData.filter(function (item) {
                    return item.id === Number(id);
                })
            )
            // if we go to page after search 
            setCurrentItemIndex(personaSearchResult.personaData.findIndex(item => item.id === Number(id)))
        }
    }, [id])

    useEffect(() => {
        // get prev item id if we go single page from search result
        personaSearchResult.personaData[currentItemIndex - 1] ?
            setPrevItemIndex(personaSearchResult.personaData[currentItemIndex - 1].id) : setPrevItemIndex(null)
        // get next item id
        personaSearchResult.personaData[currentItemIndex + 1] ?
            setNextItemIndex(personaSearchResult.personaData[currentItemIndex + 1].id) : setNextItemIndex(null)
    }, [currentItemIndex])

    useEffect(() => {
        dispatch(addPersonaSearchResult({
            personaData: personaSearchResult.personaData,
            prevItemId: prevItemIndex,
            nextItemId: nextItemIndex
        }))
    }, [prevItemIndex, nextItemIndex])


    useEffect(() => {
        // if we play come to single page empty data so we can open popups and play audio 
        dispatch(changePopupData({
            video: '',
            image: '',
            open: false,
            audio: '',
            playAudio: false,
            activeAudio: ''
        }))
    }, [])

    // click on number to go search step
    function changeStep(step1, step2, step3) {
        dispatch(searchStep({
            step1: step1,
            step2: step2,
            step3: step3
        }))
        navigate("/search");
    }

    if (personaLoading) {
        return (
            <FullScreenLoader/>
        )
    }

    return (
        <GlobalLayout showTopMenu={false}>
            {/* popup for audio and video  */}
            <Popup/>
            {/* hidden audio player */}
            <HiddenAudio/>
            <TopMenu/>
            <div className='pl-10 pr-5 mt-2'>
                <div className='flex font-firstFont text-sm tracking-wider'>
                    <div className='pr-5 border-r-2'>
                        <NavLink to={'/'} className='flex items-center opacity-60'>
                            <img src="/images/icons/back.svg" alt="back"/>
                            <span className='ml-[0.625rem]'>Dashboard</span>
                        </NavLink>
                    </div>
                    {
                        searchFormData.question ?
                            <div className='flex ml-7'>

                                <div className='opacity-60 cursor-pointer'
                                     onClick={() => changeStep(true, false, false)}>
                                    Step 1 Month /
                                </div>
                                <div className='ml-2 opacity-60 cursor-pointer'
                                     onClick={() => changeStep(true, true, false)}>
                                    Step 2 Type /
                                </div>
                                <div className='ml-2 opacity-60 cursor-pointer'
                                     onClick={() => changeStep(true, true, true)}>
                                    Step 3 Question
                                </div>
                                <div className='ml-2 opacity-60 cursor-pointer'><NavLink to={'/persona-result'}>
                                    / Personas List
                                </NavLink>
                                </div>
                                <div className='ml-2'>
                                    / {data[0]?.name}
                                </div>
                            </div> :
                            <div className='flex ml-7'>
                                <div className='ml-2   cursor-pointer'>
                                    <NavLink to={'/personas'}>
                                        Personas List /
                                    </NavLink>
                                </div>
                                <div className='ml-1 '>
                                    {personaData?.persona?.name}
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div>
                <PersonaContent persona={persona} data={data} id={id}/>
            </div>
        </GlobalLayout>
    )
}
