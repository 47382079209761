import React from 'react'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux';

export default function HiddenAudio() {
    const showPopup = useSelector((state) => state.showPopup.value);
    return (
        showPopup.audio &&
        <div className='hidden'>
            <ReactPlayer
                url={showPopup.audio}
                controls={true}
                playing={true}
                loop={true}
            />
        </div>
    )
}
