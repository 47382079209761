import React, {useEffect, useMemo, useState} from 'react'
import PagePreview from "../PagePreview/PagePreview";
import isValidHttpUrl from "../../../helpers/isValidHttpUrl";
import uniq from "lodash/uniq"


export default function DemoTextInfo({question}) {
    // const searchFormData = useSelector((state) => state.searchFormData.value);
    // const [showSuccess, setShowSuccess] = useState(false)
    //
    // // when open page if we save question show save success popup
    // useEffect(() => {
    //     searchFormData.saveSearch &&
    //     setShowSuccess(true)
    // }, [])
    // useEffect(() => {
    //     // if open save success message hide it in 5s
    //     setTimeout(() => {
    //         showSuccess &&
    //         setShowSuccess(false)
    //     }, 5000);
    // }, [showSuccess])

    const topAnswersMap = useMemo(() => {
        const answers = question?.topAnswers?.map((topAnswer) => (
            topAnswer?.answer?.parsedAnswer?.map((answer) => answer)
        ))
        return answers?.length > 0 ? uniq(answers.flat()) : []
    }, [question])


    return (
        <div>
            {/*<SuccessPopup title="Success!" text="Your search was saved successfully!" showPopup={showSuccess}*/}
            {/*              closePopup={() => setShowSuccess(false)}/>*/}
            <div className='font-firstFont mt-16 flex'>
                <div className='w-5/12 pr-11'>
                    <div className='mb-8'>
                        <div className='text-transparentWhite6 text-sm tracking-wider mb-2'>Question</div>
                        <div className='text-base font-medium'>
                            {question?.question || ""}
                        </div>

                    </div>
                    <div>
                        <div className='text-transparentWhite6 text-sm tracking-wider mb-2'>Finding</div>
                        <div className='text-base font-medium'>
                            {question?.finding || ""}
                        </div>
                    </div>
                </div>
                <div className='w-7/12 pl-11'>
                    <div className='border border-mainColor p-6 rounded-[0.438rem] text-mainColor pr-11'>
                        <div className='text-sm tracking-wider mb-4'>Insight</div>
                        <div className='text-base font-semibold  '>
                            {question?.insight || ""}
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-7'>
                <div className='text-transparentWhite6 text-sm tracking-wider mb-2'>
                    Content
                </div>
                <div className='flex flex-wrap gap-6'>
                    {question?.parsedContent?.map((content, key) => (
                        <div key={key}>
                            {isValidHttpUrl(content) ? (
                                <PagePreview link={content}/>
                            ) : content}
                        </div>
                    ))}
                    {/*{*/}
                    {/*    homeSliderMonth &&*/}
                    {/*    homeSliderMonth.map((item, i) => (*/}
                    {/*        <DemoContentItem data={item} key={item.id} itemIndex={`demoContentItem${i}`}/>*/}
                    {/*    ))*/}
                    {/*}*/}
                </div>
            </div>
            <div className='mt-7'>
                <div className='text-transparentWhite6 text-sm tracking-wider mb-4'>
                    Top answers across all users
                </div>
                <div className="flex mb-5">
                    <ol className="tracking-wider text-personaTextFontSize leading-5 text-transparentWhite8">
                        {/*{*/}
                        {/*    question?.topAnswers?.map((topAnswer, key) => (*/}
                        {/*        topAnswer?.answer?.parsedAnswer?.map((answer, key2) => (*/}
                        {/*            <li key={key2}>{key + 1}. {answer}</li>*/}
                        {/*        ))*/}
                        {/*    ))*/}
                        {/*}*/}
                        {
                            topAnswersMap?.map((answer, key2) => (
                                <li key={key2}>{key2 + 1}. {answer}</li>
                            ))
                        }
                    </ol>
                </div>
                {/*<div className='flex flex-wrap gap-6'>*/}

                {/*    {*/}
                {/*        topRatedDemo &&*/}
                {/*        topRatedDemo.map((item, i) => (*/}
                {/*            <DemoTopAnswerItem data={item} key={item.id} itemIndex={`demoTopAnswerItem${i}`}/>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
